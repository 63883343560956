import React from "react";
import { ReactComponent as Checked } from "../../../Assets/images/checked.svg";
import { ReactComponent as Unchecked } from "../../../Assets/images/unchecked.svg";
import { ReactComponent as Rejected } from "../../../Assets/images/rejectedCheck.svg";

export interface ChooseServiceButtonProps {
  className?: string;
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  chequeado?: "none" | "checked" | "rejected";
}

export const ChooseServiceButton = (props: ChooseServiceButtonProps) => {
  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className={`w-64 h-11 text-center box-border py-1 text-primary-20 bg-primary-10 rounded-md font-bold hover:text-primary ${
        props.className ? props.className : ""
      }`}
    >
      {props.text}
    </button>
  );
};

export const ChooseSecurityItem = (props: ChooseServiceButtonProps) => {
  let iconChecked = (checkStatus: "none" | "checked" | "rejected") => {
    switch (checkStatus) {
      case "none":
        return <Unchecked />;
      case "checked":
        return <Checked />;
      case "rejected":
        return <Rejected />;
      default:
        return <div></div>;
    }
  };

  return (
    <div className="flex flex-row space-x-5">
      <button
        disabled={props.disabled}
        onClick={props.onClick}
        className={`h-11 w-80 text-center box-border py-1 text-primary-20 bg-primary-10 rounded-md font-bold ${
          props.disabled ? "" : "hover:text-primary"
        } ${props.className ? props.className : ""}`}
      >
        {props.text}
      </button>
      {iconChecked(props.chequeado || "none")}
    </div>
  );
};
