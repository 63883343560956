import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../login/loginSlice";

export interface RNDCProps {
  loading: LoadingStatus;
  items: Array<RNDCItem>;
}

export interface RNDCItem {
  fechaExpedicion: string;
  nroRadicado: string;
  cedulaConductor: string;
  nroManifiesto: string;
  origen: string;
  destino: string;
  placaRemolque: string;
  fechaRadicacion: string;
  nombreEmpresa: string;
  placa: string;
  tipoDocumentoNoValido: string;
}

export const RNDCResult = (props: RNDCProps) => {
  const showMessage = () => {
    if (
      props &&
      props.items &&
      props.items[0] &&
      props.items[0].tipoDocumentoNoValido
    ) {
      return "Tipo de documento no válido";
    } else {
      return "No se encontraron registros, intente de nuevo más tarde";
    }
  };
  return (
    <LoadingOverlay
      styles={{
        content: {},
        overlay: {},
        spinner: {},
        wrapper: { height: "fit-content" },
      }}
      active={props.loading === "pending"}
      className="overflow-auto mb-10"
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {props.items &&
      props.items.length > 0 &&
      !props.items[0].tipoDocumentoNoValido ? (
        <div className="flex flex-col justify-evenly w-full px-2">
          <div className="text-primary text-3xl font-semibold text-center">
            Registro nacional de carga
          </div>
          {props.items.length > 0 ? (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    No. Manifiesto
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Cedula Conductor
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha Expedicion
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    No. Radicado
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Origen
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Destino
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Placa Remolque
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha Radicación
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Nombre Empresa
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Placa
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.items.map((rndc, i) => {
                  return (
                    <tr
                      key={i}
                      className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                    >
                      <td></td>
                      <td>{rndc.nroManifiesto}</td>
                      <td>{rndc.cedulaConductor}</td>
                      <td>{rndc.fechaExpedicion}</td>
                      <td>{rndc.nroRadicado}</td>
                      <td>{rndc.origen}</td>
                      <td>{rndc.destino}</td>
                      <td>{rndc.placaRemolque}</td>
                      <td>{rndc.fechaRadicacion}</td>
                      <td>{rndc.nombreEmpresa}</td>
                      <td>{rndc.placa}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            <>
              <div className="text-primary text-3xl font-semibold text-center">
                Registro nacional de carga
              </div>
              <div className="text-primary text-xl font-semibold text-center">
                {props.items[0].tipoDocumentoNoValido
                  ? "Tipo de documento no válido"
                  : "No se encontraron registros"}
              </div>
            </>
          )}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <>
              <div className="text-primary text-3xl font-semibold text-center">
                Registro nacional de carga
              </div>
              <div className="text-primary text-xl font-semibold text-center">
                {showMessage()}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </LoadingOverlay>
  );
};
