import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../login/loginSlice";

export interface SofiaPlusProps {
  loading: LoadingStatus;
  item: SofiaPlus | null;
}

export interface SofiaPlus {
  lista: string;
  tipo: string;
  presentaAdvertencia: boolean;
  Resultado: Array<SofiaPlusResult>;
  error: string;
}

export interface SofiaPlusResult {
  idDatoConsultado: number;
  fechaCertificado: string;
  descarga: string;
  programa: string;
  id: number;
  tipoCertificado: string;
  estadoCertificacion: string;
  estadoAprendiz: string;
}

export const SofiaPlusComponent = (props: SofiaPlusProps) => {
  return (
    <LoadingOverlay
      styles={{
        content: {},
        overlay: {},
        spinner: {},
        wrapper: { height: "50%" },
      }}
      active={props.loading === "pending"}
      className="overflow-auto mb-10"
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {props.item ? (
        <div className="flex flex-col justify-evenly w-full px-2">
          <div className="text-primary text-3xl font-semibold text-center">
            Sena Sofia Plus
          </div>
          <div className="text-primary text-lg font-semibold text-center">
            {props.item.presentaAdvertencia
              ? "Presenta advertencia"
              : "No presenta advertencias"}
          </div>

          <div className="text-primary text-xl font-semibold text-center">
            Certificados
          </div>
          {props.item.Resultado ? (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha Certificado
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Enlace de descarga
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Programa
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Tipo de certificado
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Estado de certificación
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Estado de aprendiz
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.Resultado.map((certificado, i) => {
                  return (
                    <tr
                      key={i}
                      className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                    >
                      <td></td>
                      <td>{certificado.fechaCertificado}</td>
                      <td>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={certificado.descarga}
                        >
                          {certificado.descarga}
                        </a>
                      </td>
                      <td>{certificado.programa}</td>
                      <td>{certificado.tipoCertificado}</td>
                      <td>{certificado.estadoCertificacion}</td>
                      <td>{certificado.estadoAprendiz}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : props.loading === "rejected" || props.loading === "resolved" ? (
            <div className="text-primary text-xl font-semibold text-center">
              {props.item.error}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <div className="text-primary text-xl font-semibold text-center">
              No se encontraron registros, intente de nuevo más tarde
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </LoadingOverlay>
  );
};
