import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../login/loginSlice";

export interface LicenciaSuspendidaProps {
  loading: LoadingStatus;
  item: LicenciaSupendida | null;
}
export interface LicenciaSupendida {
  mensajeSin: string;
  tieneAntecedentes: boolean;
  sanciones: Array<SancionLicencia>;
  presentaRiesgo: boolean;
}

export interface SancionLicencia {
  estado: string;
  licencia: string;
  categoria: string;
}

export const LicenciaSuspendida = (props: LicenciaSuspendidaProps) => {
  return (
    <LoadingOverlay
      styles={{
        content: {},
        overlay: {},
        spinner: {},
        wrapper: { height: "fit-content" },
      }}
      active={props.loading === "pending"}
      className="overflow-auto mb-10"
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {props.item ? (
        <div className="flex flex-col justify-evenly w-full px-2">
          <div
            className={`${
              props.item.presentaRiesgo ? "text-red-400" : "text-green-400"
            } text-3xl font-semibold text-center`}
          >
            Licencias Suspendidas
          </div>
          <div className="text-primary text-lg font-semibold text-center">
            Tiene antecedentes: {props.item.tieneAntecedentes ? "Sí" : "No"}
          </div>
          {props.item.sanciones ? (
            <div className="text-primary text-lg font-semibold text-center">
              Sanciones
            </div>
          ) : (
            <div className="text-primary text-lg font-semibold text-center">
              {props.item.mensajeSin}
            </div>
          )}
          {props.item.sanciones && props.item.sanciones.length > 0 ? (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Estado
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Licencia
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Categoria
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.sanciones.map((sancion, i) => {
                  return (
                    <tr
                      key={i}
                      className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                    >
                      <td></td>
                      <td>{sancion.estado}</td>
                      <td>{sancion.licencia}</td>
                      <td>{sancion.categoria}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <div className="text-primary text-xl font-semibold text-center">
              No se encontraron registros, intente de nuevo más tarde
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </LoadingOverlay>
  );
};
