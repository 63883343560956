import Select from "react-select";
import { DropDownProps } from "./Dropdown";

export const DropdownSecondary = (props: DropDownProps) => {
  return (
    <Select
      styles={{
        control: (base) => ({
          ...base,
          color: "white",
          background: "rgba(255,255,255,0.1)",
          border: "0.5px solid rgba(255,255,255,0.2)",
          "&:hover": {
            border: "0.5px solid rgba(255,255,255,0.4)",
          },
        }),
        dropdownIndicator: (base) => ({
          ...base,
          color: "rgba(255,255,255,0.5)",
          "&:hover": {
            color: "rgba(255,255,255,0.5)",
          },
        }),
        menuList: (base) => ({
          ...base,
          color: "white",
          background: "rgba(0,0,0,0.8)",
          border: "0.5px solid rgba(255,255,255,0.2)",
          "&:hover": {
            border: "0.5px solid rgba(255,255,255,0.2)",
            background: "rgba(0,0,0,0.8)",
          },
        }),
        menu: (base) => ({
          ...base,
          color: "white",
          background: "rgba(255,255,255,0.1)",
          border: "0.5px solid rgba(255,255,255,0.2)",
          "&:hover": {
            border: "0.5px solid rgba(255,255,255,0.2)",
          },
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isFocused
            ? "rgba(255,255,255,1)"
            : "rgba(255,255,255,0.35)",
          background: "rgba(255,255,255,0)",
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "600",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "white",
          textAlign: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
          fontSize: "14px",
          fontWeight: "600",
        }),
        input: (base) => ({ ...base, color: "transparent" }),
      }}
      options={props.options}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      isClearable={true}
    />
  );
};
