import axiosApiInstance from "../../../helpers/interceptor";

let getFilteredConsultAlertMethod = "request/alertBackOffice/getFiltered";
let updateConsultAlertMethod = "request/alertBackOffice/updateConsultedNoAlert"; 

let getFilteredConsultAlertAPI = async (
  filteredRequest: FilteredConsultAlert
): Promise<{
  error: string;
  requests: FilteredConsultAlertResponse | null;
}> => {
  let requestsResponse = await axiosApiInstance.post<FilteredConsultAlertResponse>(
    `${getFilteredConsultAlertMethod}`,
    filteredRequest
  );

  if (requestsResponse.status === 200) {
    return { error: "", requests: requestsResponse.data };
  } else {
    return { error: `${requestsResponse.status}`, requests: null };
  }
};

let updateConsultAlertAPI = async (
  request: RequestUpdateConsultedNoAlert
): Promise<{
  error: string;
  requests: ResponseUpdateConsultedNoAlert | null;
}> => {
  let updateResponse = await axiosApiInstance.post<ResponseUpdateConsultedNoAlert>(
    `${updateConsultAlertMethod}`,
    request
  );

  if (updateResponse.status === 200) {
    return { error: "", requests: updateResponse.data };
  } else {
    return { error: `${updateResponse.status}`, requests: null };
  }
};


//Models Update Consulted No Alert
export interface RequestUpdateConsultedNoAlert {
  identifier: string;
}

export interface ResponseUpdateConsultedNoAlert {
  __v: number;
  _id: string;
  configurationId: string;
  consultedId: string;
  consultedUniqueIdentifier: string;
  hasAlert: boolean;
  isCurrentVersion: boolean;
  lastConsulted: string;
  resultId: string;
  updateMethod: string;
  versionNumber: number;
}

//Models GetFiltered Consulted Alert
export interface FilteredConsultAlert {
  page: number;
  tipoEvaluado: string | null;
}

export interface FilteredConsultAlertResponse {
  solicitudes: Array<AlertBackOfficeModel>;
  numPages: number;
}

export interface AlertBackOfficeModel {
  idEvaluado: string;
  dataEvaluado: EvaluatedModel;
  fuentesConAlertas: AlertBackOfficeSourceModel[];
  estado: string;
  tipoEvaluado: string;
}

export interface AlertBackOfficeSourceModel {
  idFuente: string;
  dataFuente: SourceModel;
  tipoAlerta: string;
  evidenciaConsultaFuente: string;
  resultComparendo: ResponseDriverInfoComparendoModel;
  resultContraloria: ResponseDriverInfoContraloriaModel;
  resultJudicial: ResponseDriverInfoJudicialModel;
  resultListas: ResponseDriverInfoListasModel;
  resultPep: ResponseDriverInfoPepModel;
  resultProcuraduria: ResponseDriverProcuraduriaModel;
  resultPolicia: ResponseDriverPoliciaModel;
  resultInpec: ResponseDriverInpecModel;
  resultRunt: ResponseDriverRuntModel;
  resultVehicleRunt: ResponseDriverRuntVehicleModel;
  estado: string;
  fechaActualizacion: string;
}

export interface ResponseDriverInfoComparendoModel {
  alertaComparendo: boolean;
  comparendos: Comparendo[];
}

export interface ResponseDriverInfoContraloriaModel {
  alertaContraloria: boolean;
  contraloria: Contraloria;
}

export interface ResponseDriverInfoJudicialModel {
  alertaJudicialAltasCortes: boolean;
  alertaJudicialCivil: boolean;
  alertaJudicialLaboral: boolean;
  alertaJudicialPenal: boolean;
  procesosJudiciales: ProcesosJudiciales;
}
export interface ResponseDriverInfoListasModel {
  alertaListas: boolean;
  listasRestrictivas: ListasRestrictivas[];
}
export interface ResponseDriverInfoPepModel {
  alertaPep: boolean;
  detallePep: DetallePep[];
}
export interface ResponseDriverProcuraduriaModel {
  alertaProcuraduria: boolean;
  registrosProduraduria: RegistrosProduraduria;
}
export interface ResponseDriverPoliciaModel {
  alertaPolicia: boolean;
  registrosPolicia: RegistrosPolicia[];
}
export interface ResponseDriverInpecModel {
  alertaInpec: boolean;
  inpec: RegistrosInpec[];
}
export interface ResponseDriverRuntModel {
  alertaRunt: boolean;
  runt: Runt;
}
export interface ResponseDriverRuntVehicleModel {
  alertaRunt: boolean;
  runt: RuntVehicle;
}
export interface RuntVehicle {
  alertas: Alerta[];
  limitacionesPropiedad: LimitacionesPropiedad[];
  matricula: Matricula;
  rtm: Rtm;
  soat: Soat;
  tieneLimitaciones: boolean;
}
export interface Runt {
  alertas: Alerta[];
  licenciasConduccion: LicenciasConduccion[];
  vehiculos: Vehiculos[];
}

export interface Soat {
  entidadSoat: string;
  esVigente: boolean;
  estado: string;
  fechaExpedicion: string;
  fechaVigencia: string;
  nitEntidadSoat: string;
}
export interface Rtm {
  cdartm: string;
  esVigente: boolean;
  fechaExpedicion: string;
  fechaVigente: string;
  numeroRTM: string;
  vigente: string;
}
export interface Matricula {
  fechaMatricula: string;
  tieneDeficiencias: string;
}
export interface LimitacionesPropiedad {
  entidadJuridica: string;
  fechaExpedicion: string;
  fechaRadicacion: string;
  noDocumento: string;
  tipoLimitacion: string;
}
export interface Alerta {
  descripcion: string;
  fecha: string;
  tipo: string;
  titulo: string;
}
export interface LicenciasConduccion {
  categoria: string;
  estadoLicencia: string;
  fechaExpedicion: string;
  fechaVencimiento: string;
  lugarExpedicion: string;
  numeroLicencia: string;
}
export interface Vehiculos {
  tipo: string;
  fecha: string;
  placa: string;
}
export interface RegistrosProduraduria {
  delitos: RegistrosProduraduriaDelitos[];
  inhabilidad: RegistrosProduraduriaInhabilidad[];
  instancias: RegistrosProduraduriaInstancias[];
  sanciones: RegistrosProduraduriaSanciones[];
}
export interface RegistrosPolicia {
  id: string;
  idDatoConsultado: string;
  mensaje: string;
  mensajeRespuesta: string;
}
export interface RegistrosInpec {
  id: string;
  idDatoConsultado: string;
  numeroUnico: string;
  establecimientoACargo: string;
  situacionJuridica: string;
  estadoIngreso: string;
  genero: string;
  identificacion: string;
  nombre: string;
}
export interface RegistrosProduraduriaDelitos {
  descripcion: string;
}
export interface RegistrosProduraduriaInhabilidad {
  siri: string;
  fechaFin: string;
  fechaInicio: string;
  inhabilidad: string;
  modulo: string;
}
export interface RegistrosProduraduriaInstancias {
  autoridad: string;
  fecha: string;
  nombre: string;
}
export interface RegistrosProduraduriaSanciones {
  clase: string;
  sancion: string;
  suspendida: string;
  termino: string;
}

export interface DetallePep {
  descripcion: string;
  nombre: string;
}
export interface ListasRestrictivas {
  descripcion: String;
  nombre: String;
  tieneRiesgo: boolean;
}
export interface ProcesosJudiciales {
  altasCortes: ProcesosJudicialesModel[];
  civiles: ProcesosJudicialesModel[];
  laborales: ProcesosJudicialesModel[];
  penales: ProcesosJudicialesModel[];
}
export interface ProcesosJudicialesModel {
  proceso: String;
  entidad: String;
  fecha: String;
  clase: String;
  ponente: String;
  demandantes: String;
  demandados: String;
}
export interface Comparendo {
  estado: String;
  fecha: String;
  fechaNotificacion: String;
  infraccion: String;
  interesMora: String;
  nombreInfractor: String;
  numeroComparendo: String;
  secretaria: String;
  valorAdicional: String;
  valorMulta: String;
  valorPagar: String;
}
export interface Contraloria {
  descripcion: String;
}
export interface EvaluatedModel {
  id?: string;
  identificacion: string;
  tipoIdentificacion: string;
  nombres?: string;
  apellidos?: string;
  razonSocial?: string;
  email?: string;
  numeroCelular?: string;
  tipoVehiculo: string;
  tipoEvaluado: string;
  chequeado: "none" | "checked" | "rejected";
  gpsLink?: string;
  gpsUser?: string;
  gpsPassword?: string;
  tieneGPS?: string;
  rejectionReason?: Motive;
  status: string;
}

export interface Motive {
  nombre: string;
  descripcion: string;
}

export interface SourceModel {
  id: string;
  nombre: string;
  descripcion: string;
  esManual: boolean;
  instrucciones: Array<string>;
}

export {
  getFilteredConsultAlertAPI,
  updateConsultAlertAPI
};
