import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../login/loginSlice";

export interface RuntPersonaProps {
  loading: LoadingStatus;
  item: RuntPersonaResult | null;
}

export interface RuntPersonaResult {
  lista: string;
  infracciones: RuntInfraccion;
  solicitudesSicov: Array<SolicitudSicov>;
  solicitudesAptitud: Array<SolicitudAptitud>;
  datosBasicos: DatosBasicosRunt;
  presentaRiesto: boolean;
  licencias: Array<LicenciaRunt>;
}

export interface RuntInfraccion {
  serialVersionUID: string;
  tieneMultas: string;
  nroPazYSalvo: string;
}

export interface SolicitudSicov {
  estado: string;
  fechaSolicitud: string;
  entidad: string;
  descripcionTipoValidacion: string;
  tramitesRealizados: string;
}

export interface SolicitudAptitud {
  numeroSolicitud: string;
  serialVersionUID: string;
  estado: string;
  fechaSolicitud: string;
  entidad: string;
  tramitesRealizados: string;
  identificador: string;
}

export interface DatosBasicosRunt {
  apellidos: string;
  tipoDocumento: string;
  noInscrito: boolean;
  numeroDocumento: string;
  estadoConductor: string;
  tieneLicencias: string;
  nroInscripcion: string;
  fechaInscripcion: string;
  nombres: string;
  estadoPersona: string;
}

export interface LicenciaRunt {
  otExpide: string;
  fechaExpedicion: string;
  fechaVencimientoExamen: string;
  fechaVencimiento: string;
  categoria: string;
  estadoLicencia: string;
  numeroLicencia: string;
}

export const RuntPersona = (props: RuntPersonaProps) => {
  return (
    <LoadingOverlay
      styles={{
        content: {},
        overlay: {},
        spinner: {},
        wrapper: { height: "fit-content" },
      }}
      active={props.loading === "pending"}
      className="overflow-auto mb-10"
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {props.item ? (
        <div className="flex flex-col justify-evenly w-full px-2">
          <div className="text-primary text-3xl font-semibold text-center">
            Runt
          </div>
          <div className="text-primary text-lg font-semibold text-center">
            {props.item.presentaRiesto
              ? "Presenta riesgo"
              : "No presenta riesgo"}
          </div>
          <div className="text-primary text-2xl font-semibold text-center">
            Datos Básicos
          </div>
          {props.item.datosBasicos ? (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Nombres
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Apellidos
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Tipo Identificación
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Identificación
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Estado de conductor
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Tiene licencias
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha inscripción
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Estado de persona
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                <tr className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md">
                  <td></td>
                  <td>{props.item.datosBasicos.nombres}</td>
                  <td>{props.item.datosBasicos.apellidos}</td>
                  <td>{props.item.datosBasicos.tipoDocumento}</td>
                  <td>{props.item.datosBasicos.numeroDocumento}</td>
                  <td>{props.item.datosBasicos.estadoConductor}</td>
                  <td>
                    {props.item.datosBasicos.tieneLicencias ? "Sí" : "No"}
                  </td>
                  <td>{props.item.datosBasicos.fechaInscripcion}</td>
                  <td>{props.item.datosBasicos.estadoPersona}</td>
                  <td></td>
                </tr>

                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
          <div className="text-primary text-xl font-semibold text-center">
            Licencias
          </div>
          {props.item.licencias && props.item.licencias.length > 0 ? (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    No. Licencia
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Estado
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Categoria
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha de vencimiento
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha de Expedición
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    OT que expide
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.licencias.map((licencia, i) => {
                  return (
                    <tr
                      key={i}
                      className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                    >
                      <td></td>
                      <td>{licencia.numeroLicencia}</td>
                      <td>{licencia.estadoLicencia}</td>
                      <td>{licencia.categoria}</td>
                      <td>{licencia.fechaVencimiento}</td>
                      <td>{licencia.fechaExpedicion}</td>
                      <td>{licencia.otExpide}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
          {props.item.solicitudesSicov &&
          props.item.solicitudesSicov.length > 0 ? (
            <div className="text-primary text-lg font-semibold text-center">
              Solicitudes Sicov
            </div>
          ) : (
            ""
          )}
          {props.item.solicitudesSicov &&
          props.item.solicitudesSicov.length > 0 ? (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Estado
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Entidad
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha de solicitud
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Descripción{" "}
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Tramites realizados
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.solicitudesSicov.map((sicov, i) => {
                  return (
                    <tr
                      key={i}
                      className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                    >
                      <td></td>
                      <td>{sicov.estado}</td>
                      <td>{sicov.entidad}</td>
                      <td>{sicov.fechaSolicitud}</td>
                      <td>{sicov.descripcionTipoValidacion}</td>
                      <td>{sicov.tramitesRealizados}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
          <div className="text-primary text-lg font-semibold text-center">
            Solicitudes Aptitud
          </div>
          {props.item.solicitudesAptitud &&
          props.item.solicitudesAptitud.length > 0 ? (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    No. Solicitud
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Estado
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha Solicitud
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Entidad
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Trámites realizados
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Identificador
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.solicitudesAptitud.map((aptitud, i) => {
                  return (
                    <tr
                      key={i}
                      className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                    >
                      <td></td>
                      <td>{aptitud.numeroSolicitud}</td>
                      <td>{aptitud.estado}</td>
                      <td>{aptitud.fechaSolicitud}</td>
                      <td>{aptitud.entidad}</td>
                      <td>{aptitud.tramitesRealizados}</td>
                      <td>{aptitud.identificador}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <div className="text-primary text-xl font-semibold text-center">
              No se encontraron registros, intente de nuevo más tarde
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </LoadingOverlay>
  );
};
