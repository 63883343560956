import { useEffect, useState } from "react";
import Modal, { Styles } from "react-modal";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ReactComponent as LeftArrow } from "../../../../Assets/images/leftArrow.svg";
import { DropdownSecondary } from "../../../common/DropdownSecondary";
import {
  createTransportesRequest,
  resetErrorMessage,
  resetLoadingStatus,
  selectRequestCreationErrorMessage,
  selectRequestCreationLoading,
  selectRequestCreationRedirectionId,
} from "../requestCreationSlice";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { CreateTransportesRequest } from "../requestCreationAPI";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "20%",
    width: "20%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "2%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};

Modal.setAppElement("#root");

export const RequestCreationProVe = () => {
  let loadingStatus = useAppSelector(selectRequestCreationLoading);
  let redirectionId = useAppSelector(selectRequestCreationRedirectionId);
  let errorMessage = useAppSelector(selectRequestCreationErrorMessage);

  const [request, setRequest] = useState<CreateTransportesRequest>({
    driverIdentification: "",
    observations: "",
    ownerIdentification: "",
    ownerIdType: "",
    placaTrailer: "",
    placaVehicle: "",
    driverIdType: "cc",
    vehicleType: "",
    trailerOwnerIdentification: "",
  });

  let tipoDocumentoOptionsPropietario = [
    { label: "Cedula de Ciudadanía", value: "cc" },
    { label: "Cedula de extranjería ", value: "ce" },
    { label: "NIT (sin digito verificación) ", value: "nit" },
  ];

  let tipoDocumentoOptionsConductor = [
    { label: "Cedula de Ciudadanía", value: "cc" },
    { label: "Cedula de extranjería ", value: "ce" },
  ];

  let tipoVehiculo = [
    { label: "Rigido", value: "rigido" },
    { label: "Articulado ", value: "articulado" },
  ];
  let getTipoVehiculoLabel = (value: string) => {
    switch (value) {
      case "rigido":
        return "Rigido";
      case "articulado":
        return "articulado";
      default:
        return "SELECCIONE UNA OPCIÓN";
    }
  };

  let getTipoDocumentoLabel = (value: string) => {
    switch (value) {
      case "CC":
        return "Cedula de Ciudadanía";
      case "CE":
        return "Cedula de extranjería";
      case "NIT":
        return "Nit";
      case "Pasaporte":
        return "Pasaporte";
      default:
        return "SELECCIONE UNA OPCIÓN";
    }
  };

  const placaError = (placa: string | undefined | null) => {
    const regPlaca =
      /(\w{3,3})+(?:[\W|_]{0,1})+((\d{3,3})|(\d{2,2})+(\w{1,1}))/gi;
    if (placa) {
      if (regPlaca.test(placa)) {
        return "";
      } else {
        return (
          <p className="text-xs text-red-400 mx-2">
            El dato ingresado no concuerda con una placa
          </p>
        );
      }
    } else {
      return "";
    }
  };

  const placaIsValid = (placa: string | undefined | null) => {
    if (placa) {
      const regPlaca =
        /(\w{3,3})+(?:[\W|_]{0,1})+((\d{3,3})|(\d{2,2})+(\w{1,1}))/gi;
      return regPlaca.test(placa);
    } else {
      return false;
    }
  };

  const requestTransportesCreated = (
    requestParam: CreateTransportesRequest
  ): boolean => {
    let correct = false;
    if (
      requestParam.driverIdentification !== "" &&
      requestParam.driverIdentification?.length! > 3
    ) {
      correct = true;
    }
    if (requestParam.ownerIdType && requestParam.ownerIdType !== "") {
      if (requestParam.ownerIdentification?.length! > 3) {
        correct = true;
      }
    }

    if (
      requestParam.ownerIdentification?.length! > 0 &&
      (!requestParam.ownerIdType || requestParam.ownerIdType === "")
    ) {
      correct = false;
    }

    if (
      requestParam.placaVehicle &&
      requestParam.placaVehicle?.length > 0 &&
      (!requestParam.vehicleType || requestParam.vehicleType === "")
    ) {
      correct = false;
    }
    if (requestParam.vehicleType && requestParam.vehicleType !== "") {
      if (requestParam.vehicleType === "rigido") {
        if (
          requestParam.placaVehicle?.length! > 0 &&
          placaIsValid(requestParam.placaVehicle) &&
          requestParam.ownerIdentification &&
          requestParam.ownerIdentification.length > 4
        ) {
          correct = true;
        } else {
          correct = false;
        }
      } else if (requestParam.vehicleType === "articulado") {
        if (
          requestParam.placaVehicle?.length! > 5 &&
          requestParam.placaTrailer?.length! > 4 &&
          placaIsValid(requestParam.placaVehicle) &&
          requestParam.ownerIdentification &&
          requestParam.ownerIdentification.length > 4
        ) {
          correct = true;
        } else {
          correct = false;
        }
      }
    }

    if (
      (!request.ownerIdType || request.ownerIdType === "") &&
      request.ownerIdentification?.length! > 0
    ) {
      correct = false;
    }

    return correct;
  };

  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  const closeModal = () => {
    dispatch(resetLoadingStatus());
  };
  useEffect(() => {
    window.addEventListener("popstate", () => {});
    if (loadingStatus === "resolved" && redirectionId !== "") {
      navigate(`/services/securityEvaluation/choose/${redirectionId}`, {
        replace: true,
      });
    }
    return () => {};
  }, [dispatch, loadingStatus, navigate, redirectionId]);
  return (
    <>
      {loadingStatus === "pending" && (
        <LoadingOverlay
          active={loadingStatus === "pending"}
          spinner={<ClipLoader color="white" size="150px" />}
        ></LoadingOverlay>
      )}

      <Modal
        isOpen={loadingStatus === "rejected"}
        onRequestClose={(e) => {
          e.preventDefault();
          dispatch(resetLoadingStatus());
          dispatch(resetErrorMessage());
          if (errorMessage === "401") {
            navigate(0);
          }
        }}
        style={customStylesModal}
        contentLabel=""
      >
        <div className="flex flex-col items-center justify-center">
          <div className="text-center text-sm text-black mb-3">
            Error creando la consulta
          </div>
          {errorMessage === "401" ? <p>La sesión se ha vencido</p> : ""}
          <button
            onClick={(e) => {
              e.preventDefault();
              closeModal();
              dispatch(resetErrorMessage());
              if (errorMessage === "401") {
                navigate(0);
              }
            }}
            className="bg-backgroundButtonModal rounded-lg text-center w-44 h-8 text-sm text-white font-bold"
          >
            {errorMessage ? "Iniciar sesión" : "Intentar de nuevo"}
          </button>
        </div>
      </Modal>
      <div className="flex flex-col items-center w-full h-5/6 ">
        <div className="flex flex-col sm:flex-row h-1/5 w-full items-center justify-evenly">
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/services/requestCreation/selectTypes");
            }}
            className="bg-primary-5 flex flex-row items-center justify-start h-11 w-64 rounded-md box-border px-5 cursor-pointer"
          >
            <LeftArrow className="mr-8" />
            <div className="text-primary text-lg font-bold">
              Regresar al MENU
            </div>
          </div>
          <div className="text-5xl text-primary font-extrabold text-center">
            Consulta de Transportes
          </div>
        </div>
        <div className="flex flex-row justify-start w-full">
          <div className="flex flex-row justify-evenly w-full">
            <div className="flex flex-col w-1/3">
              <div className="text-2xl text-primary font-bold text-left m-2">
                Conductor
              </div>
              <div className="text-lg font-bold text-primary-35 m-2">
                Tipo de Documento
              </div>
              <div className="lg:w-3/6 sm:w-3/5 xl:w-3/5 m-2">
                <DropdownSecondary
                  defaultValue={
                    !request.driverIdType
                      ? { label: "SELECCIONE UNA OPCIÓN", value: "" }
                      : {
                          label: getTipoDocumentoLabel(request.driverIdType!),
                          value: request.driverIdType!,
                        }
                  }
                  title="TipoDocumento"
                  options={tipoDocumentoOptionsConductor}
                  onChange={(e, m) => {
                    if (m.action === "clear") {
                      setRequest({
                        ...request,
                        driverIdType: "",
                        driverIdentification: "",
                      });
                    } else {
                      if (e?.value === "") {
                        setRequest({
                          ...request,
                          driverIdType: e?.value!,
                          driverIdentification: "",
                        });
                      } else {
                        setRequest({ ...request, driverIdType: e?.value! });
                      }
                    }
                  }}
                />
                {(!request.ownerIdType || request.ownerIdType === "") &&
                  request.ownerIdentification?.length! > 0 && (
                    <p className="text-xs text-red-400 mx-2">
                      Debe seleccionar el tipo de documento
                    </p>
                  )}
              </div>

              <div className="text-lg font-bold text-primary-35 m-2">
                Número de Documento
              </div>
              <input
                value={request?.driverIdentification!}
                onChange={(e) => {
                  let reg = /^\d+$/;

                  if (
                    (!e.target.value.includes("-") &&
                      reg.test(e.target.value)) ||
                    e.target.value.length === 0
                  ) {
                    setRequest({
                      ...request,
                      driverIdentification: e.target.value,
                    });
                  }
                }}
                className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold h-10 px-6 m-2"
              />
              {request.driverIdentification &&
                request.driverIdentification.length > 0 &&
                request.driverIdentification.length <= 3 && (
                  <p className="text-xs text-red-400 mx-2">
                    Debe ser mayor a 3 dígitos
                  </p>
                )}

              <div className="text-2xl text-primary font-bold text-left m-2">
                Propietario
              </div>
              <div className="text-lg font-bold text-primary-35 m-2">
                Tipo de Documento
              </div>
              <div className="lg:w-3/6 sm:w-3/5 xl:w-3/5 m-2">
                <DropdownSecondary
                  defaultValue={
                    !request.ownerIdType
                      ? { label: "SELECCIONE UNA OPCIÓN", value: "" }
                      : {
                          label: getTipoDocumentoLabel(request.ownerIdType!),
                          value: request.ownerIdType!,
                        }
                  }
                  title="TipoDocumento"
                  options={tipoDocumentoOptionsPropietario}
                  onChange={(e, m) => {
                    if (m.action === "clear") {
                      setRequest({
                        ...request,
                        ownerIdType: "",
                        ownerIdentification: "",
                      });
                    } else {
                      if (e?.value === "") {
                        setRequest({
                          ...request,
                          ownerIdType: e?.value!,
                          ownerIdentification: "",
                        });
                      } else {
                        setRequest({ ...request, ownerIdType: e?.value! });
                      }
                    }
                  }}
                />
                {(!request.ownerIdType || request.ownerIdType === "") &&
                  request.ownerIdentification?.length! > 0 && (
                    <p className="text-xs text-red-400 mx-2">
                      Debe seleccionar el tipo de documento
                    </p>
                  )}
              </div>

              <div className="text-lg font-bold text-primary-35 m-2">
                Número de documento
              </div>
              <input
                value={request.ownerIdentification || ""}
                onChange={(e) => {
                  let reg = /^\d+$/;
                  if (
                    (!e.target.value.includes("-") &&
                      reg.test(e.target.value)) ||
                    e.target.value.length === 0
                  ) {
                    setRequest({
                      ...request,
                      ownerIdentification: e.target.value,
                    });
                  }
                }}
                className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold h-10 px-6 m-2"
              />
              {request.ownerIdType &&
                request.ownerIdType !== "" &&
                request.ownerIdentification?.length! <= 4 && (
                  <p className="text-xs text-red-400 mx-2">
                    Debe ser mayor a 4 dígitos
                  </p>
                )}

              {request.ownerIdType === "cc" &&
                request.ownerIdentification?.length! > 3 &&
                (request.ownerIdentification?.length === 9 ||
                  request.ownerIdentification?.length === 9) &&
                (request.ownerIdentification.startsWith("9") ||
                  request.ownerIdentification.startsWith("8")) && (
                  <p className="text-xs text-red-400 mx-2">
                    El documento puede no ser válido, revisa si es un NIT
                  </p>
                )}
              <div className="text-lg font-bold text-primary-35 m-2">
                Observaciones
              </div>
              <input
                value={request.observations!}
                onChange={(e) => {
                  setRequest({
                    ...request,
                    observations: e.target.value,
                  });
                }}
                className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold h-10 px-6 w-full m-2"
              />
            </div>

            <div className="flex flex-col w-1/3">
              <div className="text-2xl text-primary font-bold text-left my-2">
                Tipología Vehículo
              </div>
              <DropdownSecondary
                defaultValue={
                  !request.vehicleType
                    ? { label: "SELECCIONE UNA OPCIÓN", value: "" }
                    : {
                        label: getTipoVehiculoLabel(request.vehicleType),
                        value: request.vehicleType,
                      }
                }
                title="TipoVehiculo"
                options={tipoVehiculo}
                onChange={(e, m) => {
                  if (m.action === "clear") {
                    setRequest({
                      ...request,
                      vehicleType: "",
                      placaTrailer: null,
                      placaVehicle: null,
                    });
                  } else {
                    if (e?.value === "rigido") {
                      setRequest({
                        ...request,
                        vehicleType: e?.value!,
                        placaTrailer: null,
                      });
                    } else {
                      setRequest({ ...request, vehicleType: e?.value! });
                    }
                  }
                }}
              />
              {(!request.vehicleType || request.vehicleType === "") &&
                request.placaVehicle?.length! > 0 && (
                  <p className="text-xs text-red-400 mx-2">
                    Debe seleccionar el tipo de vehículo
                  </p>
                )}
              <div className="text-lg font-bold text-primary-35 m-2">
                Placa Cabezote
              </div>
              <input
                value={request.placaVehicle || ""}
                onChange={(e) => {
                  setRequest({
                    ...request,
                    placaVehicle: e.target.value.toUpperCase(),
                  });
                }}
                className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold h-10 px-6 m-2"
              />
              {request.vehicleType &&
                request.vehicleType !== "" &&
                request.placaVehicle?.length! < 1 && (
                  <p className="text-xs text-red-400 mx-2">
                    Debe contener una placa
                  </p>
                )}
              {(!request.ownerIdentification ||
                request.ownerIdentification?.length! <= 4) &&
                request.placaVehicle &&
                request.placaVehicle?.length > 1 && (
                  <p className="text-xs text-red-400 mx-2">
                    Para obtener información completa, debes ingresar el
                    documento de identidad del propietario de este vehículo.
                  </p>
                )}
              {placaError(request.placaVehicle)}
              {request.vehicleType === "articulado" ? (
                <div>
                  <div className="text-lg font-bold text-primary-35 m-2">
                    Placa Remolque
                  </div>
                  <input
                    value={request.placaTrailer || ""}
                    onChange={(e) => {
                      setRequest({
                        ...request,
                        placaTrailer: e.target.value.toUpperCase(),
                      });
                    }}
                    className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold h-10 px-6 m-2"
                  />
                  {(!request.vehicleType ||
                    request.placaTrailer?.length! < 5) && (
                    <p className="text-xs text-red-400 mx-2">
                      Ingrese una placa de remolque válida
                    </p>
                  )}
                  <div className="text-lg font-bold text-primary-35 m-2">
                    Número de documento propietario remolque
                  </div>
                  <input
                    value={request.trailerOwnerIdentification || ""}
                    onChange={(e) => {
                      setRequest({
                        ...request,
                        trailerOwnerIdentification:
                          e.target.value.toUpperCase(),
                      });
                    }}
                    className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold h-10 px-6 m-2"
                  />
                  {(!request.vehicleType ||
                    request.trailerOwnerIdentification?.length! < 5) && (
                    <p className="text-xs text-red-400 mx-2">
                      Ingrese un número de documento válido
                    </p>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-row h-1/5 items-center justify-center">
          {requestTransportesCreated(request) && (
            <div
              onClick={(e) => {
                e.preventDefault();
                dispatch(createTransportesRequest(request));
              }}
              className="flex flex-col items-center pt-2 justify-center h-10 w-60 text-primary bg-primary-5 rounded-md text-center font-bold cursor-pointer"
            >
              Crear consulta
            </div>
          )}
        </div>
      </div>
    </>
  );
};
