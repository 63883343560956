import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import ClipLoader from "react-spinners/ClipLoader";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  getFosyga,
  getLicenciasSuspendidas,
  getMultas,
  getOFAC,
  getONU,
  getPolicia,
  getProcesos,
  getProcuraduria,
  getRama,
  getRuntPersona,
  getUE,
  selectdriverResult,
  selectCurrentRegistration,
  selectCurrentRequest,
  getRNDCPersona,
  getSofiaPlus,
  selectUpdatedLoading,
  saveResult,
  selectOwnerResult,
  selectVehicleResult,
  selectOfficialResult,
  selectProviderResult,
  getRuntPlaca,
  getRndcPlaca,
  resetDetailData,
  selectTrailerResult,
  getRndcPlacaTrailer,
  getRuntPlacaTrailer,
} from "../../securityEvaluationSlice";
import { ReactComponent as LeftArrow } from "../../../../../Assets/images/leftArrow.svg";
import { useEffect, useState } from "react";
import { FosygaResult } from "../../EvalComponents/FosygaResult";
import { LicenciaSuspendida } from "../../EvalComponents/LicenciaSuspendida";
import { ListaRestrictiva } from "../../EvalComponents/ListaRestrictiva";
import { PoliciaResult } from "../../EvalComponents/PoliciaResult";
import { ProcuraduriaResult } from "../../EvalComponents/Procuraduria";
import { ProcesosUnificadosResult } from "../../EvalComponents/ProcesoUnificado";
import { RuntPersona } from "../../EvalComponents/RuntPersona";
import { MultaResult } from "../../EvalComponents/MultaResult";
import { RNDCResult } from "../../EvalComponents/RNDCResult";
import { SofiaPlusComponent } from "../../EvalComponents/SofiaPlus";
import { RamaJudicialResult } from "../../EvalComponents/RamaJudicial";
import { CommentRequest } from "../../securityEvaluationAPI";
import { selectCurrentStudy } from "../../../../common/commonSlice";
import { RuntPlaca } from "../../EvalComponents/RuntPlaca";
import Modal, { Styles } from "react-modal";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "20%",
    width: "20%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "2%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};

Modal.setAppElement("#root");

export const AutomaticAlerts = () => {
  let params = useParams<{ id: string }>();
  let currentStudy = useAppSelector(selectCurrentStudy);
  const [showModal, setShowModal] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [comentario, setComentario] = useState("");
  const [elementSelected, setElementSelected] = useState<
    | "Conductor"
    | "Propietario"
    | "Vehiculo"
    | "Funcionario"
    | "Proveedor"
    | "Remolque"
  >(
    currentStudy === "funcionarios"
      ? "Funcionario"
      : currentStudy === "clients"
      ? "Proveedor"
      : "Conductor"
  );

  let request = useAppSelector(selectCurrentRequest);
  let registration = useAppSelector(selectCurrentRegistration);
  let conductorResult = useAppSelector(selectdriverResult);
  let ownerResult = useAppSelector(selectOwnerResult);
  let vehicleResult = useAppSelector(selectVehicleResult);
  let trailerResult = useAppSelector(selectTrailerResult);
  let officialResult = useAppSelector(selectOfficialResult);
  let providerResult = useAppSelector(selectProviderResult);
  let saveLoading = useAppSelector(selectUpdatedLoading);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  const isStillLoading = () => {
    if (
      conductorResult.loadingOfac === "pending" ||
      conductorResult.loadingOnu === "pending" ||
      conductorResult.loadingUE === "pending" ||
      conductorResult.loadingPolicia === "pending" ||
      conductorResult.loadingRama === "pending" ||
      conductorResult.loadingProcesos === "pending" ||
      conductorResult.multasLoading === "pending" ||
      conductorResult.procuraduriaLoading === "pending" ||
      conductorResult.loadingRuntPersona === "pending" ||
      conductorResult.loadingRndc === "pending" ||
      conductorResult.loadingSofiaPlus === "pending" ||
      ownerResult.loadingOfac === "pending" ||
      ownerResult.loadingOnu === "pending" ||
      ownerResult.loadingUE === "pending" ||
      ownerResult.loadingPolicia === "pending" ||
      ownerResult.loadingRama === "pending" ||
      ownerResult.loadingProcesos === "pending" ||
      ownerResult.procuraduriaLoading === "pending" ||
      vehicleResult.runtLoading === "pending" ||
      vehicleResult.rndcLoading === "pending" ||
      trailerResult.runtLoading === "pending" ||
      trailerResult.rndcLoading === "pending" ||
      officialResult.loadingOfac === "pending" ||
      officialResult.loadingOnu === "pending" ||
      officialResult.loadingUE === "pending" ||
      officialResult.loadingPolicia === "pending" ||
      officialResult.loadingRama === "pending" ||
      officialResult.loadingProcesos === "pending" ||
      officialResult.procuraduriaLoading === "pending" ||
      providerResult.loadingOfac === "pending" ||
      providerResult.loadingOnu === "pending" ||
      providerResult.loadingUE === "pending" ||
      providerResult.loadingProcesos === "pending" ||
      providerResult.procuraduriaLoading === "pending"
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    window.addEventListener("popstate", () => {
      dispatch(resetDetailData());
      navigate(`/services/securityEvaluation/choose/${params.id}`);
    });
    if (!registration) {
      if (saveLoading === "resolved") {
        navigate(`/services/intro`);
      } else {
        navigate(`/services/securityEvaluation/choose/${params.id}`);
      }
    }

    //-----------------------------------------------------------//
    if (registration && registration.datoConsultadoPersona) {
      if (!conductorResult.fosyga && conductorResult.fosygaLoading === "idle") {
        dispatch(
          getFosyga({
            type: "driver",
            id: registration?.datoConsultadoPersona.toString()!,
          })
        );
      }

      if (
        !conductorResult.licenciaSuspendida &&
        conductorResult.licenciaLoading === "idle"
      ) {
        dispatch(
          getLicenciasSuspendidas({
            type: "driver",
            id: registration?.datoConsultadoPersona.toString()!,
          })
        );
      }
      if (!conductorResult.ofac && conductorResult.loadingOfac === "idle") {
        dispatch(
          getOFAC({
            type: "driver",
            id: registration?.datoConsultadoPersona.toString()!,
          })
        );
      }
      if (!conductorResult.onu && conductorResult.loadingOnu === "idle") {
        dispatch(
          getONU({
            type: "driver",
            id: registration?.datoConsultadoPersona.toString()!,
          })
        );
      }
      if (!conductorResult.ue && conductorResult.loadingUE === "idle") {
        dispatch(
          getUE({
            type: "driver",
            id: registration?.datoConsultadoPersona.toString()!,
          })
        );
      }

      if (
        !conductorResult.policia &&
        conductorResult.loadingPolicia === "idle"
      ) {
        dispatch(
          getPolicia({
            type: "driver",
            id: registration?.datoConsultadoPersona.toString()!,
          })
        );
      }

      if (
        !conductorResult.ramaJudicial &&
        conductorResult.loadingRama === "idle"
      ) {
        dispatch(
          getRama({
            type: "driver",
            id: registration?.datoConsultadoPersona.toString()!,
          })
        );
      }

      if (
        !conductorResult.procesos &&
        conductorResult.loadingProcesos === "idle"
      ) {
        dispatch(
          getProcesos({
            type: "driver",
            id: registration?.datoConsultadoPersona.toString()!,
          })
        );
      }

      if (!conductorResult.multas && conductorResult.multasLoading === "idle") {
        dispatch(
          getMultas({
            type: "driver",
            id: registration?.datoConsultadoPersona.toString()!,
          })
        );
      }

      if (
        !conductorResult.procuraduria &&
        conductorResult.procuraduriaLoading === "idle"
      ) {
        dispatch(
          getProcuraduria({
            type: "driver",
            id: registration?.datoConsultadoPersona.toString()!,
          })
        );
      }

      if (
        !conductorResult.runtPersona &&
        conductorResult.loadingRuntPersona === "idle"
      ) {
        dispatch(
          getRuntPersona({
            type: "driver",
            id: registration?.datoConsultadoPersona.toString()!,
          })
        );
      }

      if (
        conductorResult.rndc &&
        conductorResult.rndc.length === 0 &&
        conductorResult.loadingRndc === "idle"
      ) {
        dispatch(
          getRNDCPersona({
            type: "driver",
            reqRndcPersona: {
              cedula: request?.driverIdentification!,
              datoConsultadoPersona:
                registration.datoConsultadoPersona.toString()!,
            },
          })
        );
      }

      if (
        !conductorResult.sofiaPlus &&
        conductorResult.loadingSofiaPlus === "idle"
      ) {
        dispatch(
          getSofiaPlus({
            type: "driver",
            id: registration?.datoConsultadoPersona.toString()!,
          })
        );
      }
    }

    //-----------------------------------------------------------//

    if (registration && registration.datoConsultadoPlaca) {
      if (!vehicleResult.runt && vehicleResult.runtLoading === "idle") {
        dispatch(
          getRuntPlaca({
            type: "vehicle",
            id: registration?.datoConsultadoPlaca.toString()!,
          })
        );
      }

      if (
        vehicleResult.rndc &&
        vehicleResult.rndc.length === 0 &&
        vehicleResult.rndcLoading === "idle"
      ) {
        dispatch(
          getRndcPlaca({
            type: "vehicle",
            reqRndcPlaca: {
              datoConsultadoPlaca: registration.datoConsultadoPlaca.toString(),
              placa: request?.placaVehicle!,
            },
          })
        );
      }
    }

    //-----------------------------------------------------------//

    // ----------------------------------------------------------//
    if (registration && registration.datoConsultadoTrailer) {
      if (!trailerResult.runt && trailerResult.runtLoading === "idle") {
        dispatch(
          getRuntPlacaTrailer({
            type: "trailer",
            id: registration?.datoConsultadoTrailer.toString()!,
          })
        );
      }

      if (
        trailerResult.rndc &&
        trailerResult.rndc.length === 0 &&
        trailerResult.rndcLoading === "idle"
      ) {
        dispatch(
          getRndcPlacaTrailer({
            type: "trailer",
            reqRndcPlaca: {
              datoConsultadoPlaca:
                registration.datoConsultadoTrailer.toString(),
              placa: request?.placaVehicle!,
            },
          })
        );
      }
    }
    //-----------------------------------------------------------//

    if (registration && registration.datoConsultadoPropietario) {
      if (!ownerResult.ofac && ownerResult.loadingOfac === "idle") {
        dispatch(
          getOFAC({
            type: "owner",
            id: registration?.datoConsultadoPropietario.toString()!,
          })
        );
      }
      if (!ownerResult.onu && ownerResult.loadingOnu === "idle") {
        dispatch(
          getONU({
            type: "owner",
            id: registration?.datoConsultadoPropietario.toString()!,
          })
        );
      }
      if (!ownerResult.ue && ownerResult.loadingUE === "idle") {
        dispatch(
          getUE({
            type: "owner",
            id: registration?.datoConsultadoPropietario.toString()!,
          })
        );
      }

      if (!ownerResult.policia && ownerResult.loadingPolicia === "idle") {
        dispatch(
          getPolicia({
            type: "owner",
            id: registration?.datoConsultadoPropietario.toString()!,
          })
        );
      }

      if (!ownerResult.ramaJudicial && ownerResult.loadingRama === "idle") {
        dispatch(
          getRama({
            type: "owner",
            id: registration?.datoConsultadoPropietario.toString()!,
          })
        );
      }

      if (!ownerResult.procesos && ownerResult.loadingProcesos === "idle") {
        dispatch(
          getProcesos({
            type: "owner",
            id: registration?.datoConsultadoPropietario.toString()!,
          })
        );
      }
      if (
        !ownerResult.procuraduria &&
        ownerResult.procuraduriaLoading === "idle"
      ) {
        dispatch(
          getProcuraduria({
            type: "owner",
            id: registration?.datoConsultadoPropietario.toString()!,
          })
        );
      }
    }

    //-----------------------------------------------------------//

    if (registration && registration.datoConsultadoProvider) {
      if (!providerResult.ofac && providerResult.loadingOfac === "idle") {
        dispatch(
          getOFAC({
            type: "provider",
            id: registration?.datoConsultadoProvider.toString()!,
          })
        );
      }
      if (!providerResult.onu && providerResult.loadingOnu === "idle") {
        dispatch(
          getONU({
            type: "provider",
            id: registration?.datoConsultadoProvider.toString()!,
          })
        );
      }
      if (!providerResult.ue && providerResult.loadingUE === "idle") {
        dispatch(
          getUE({
            type: "provider",
            id: registration?.datoConsultadoProvider.toString()!,
          })
        );
      }

      if (
        !providerResult.procesos &&
        providerResult.loadingProcesos === "idle"
      ) {
        dispatch(
          getProcesos({
            type: "provider",
            id: registration?.datoConsultadoProvider.toString()!,
          })
        );
      }
      if (
        !providerResult.procuraduria &&
        providerResult.procuraduriaLoading === "idle"
      ) {
        dispatch(
          getProcuraduria({
            type: "provider",
            id: registration?.datoConsultadoProvider.toString()!,
          })
        );
      }
    }

    //-----------------------------------------------------------//
    if (registration && registration.datoConsultadoOfficial) {
      if (!officialResult.ofac && officialResult.loadingOfac === "idle") {
        dispatch(
          getOFAC({
            type: "official",
            id: registration?.datoConsultadoOfficial.toString()!,
          })
        );
      }
      if (!officialResult.onu && officialResult.loadingOnu === "idle") {
        dispatch(
          getONU({
            type: "official",
            id: registration?.datoConsultadoOfficial.toString()!,
          })
        );
      }
      if (!officialResult.ue && officialResult.loadingUE === "idle") {
        dispatch(
          getUE({
            type: "official",
            id: registration?.datoConsultadoOfficial.toString()!,
          })
        );
      }

      if (!officialResult.policia && officialResult.loadingPolicia === "idle") {
        dispatch(
          getPolicia({
            type: "official",
            id: registration?.datoConsultadoOfficial.toString()!,
          })
        );
      }

      if (
        !officialResult.ramaJudicial &&
        officialResult.loadingRama === "idle"
      ) {
        dispatch(
          getRama({
            type: "official",
            id: registration?.datoConsultadoOfficial.toString()!,
          })
        );
      }

      if (
        !officialResult.procesos &&
        officialResult.loadingProcesos === "idle"
      ) {
        dispatch(
          getProcesos({
            type: "official",
            id: registration?.datoConsultadoOfficial.toString()!,
          })
        );
      }
      if (
        !officialResult.procuraduria &&
        officialResult.procuraduriaLoading === "idle"
      ) {
        dispatch(
          getProcuraduria({
            type: "official",
            id: registration?.datoConsultadoOfficial.toString()!,
          })
        );
      }
    }
    //-----------------------------------------------------------//
    return () => {};
  }, [
    conductorResult,
    dispatch,
    registration,
    navigate,
    params.id,
    officialResult,
    ownerResult,
    providerResult,
    vehicleResult,
    saveLoading,
    request,
    trailerResult,
  ]);

  return (
    <LoadingOverlay
      styles={{
        content: {},
        overlay: {},
        spinner: {},
        wrapper: { height: "95%" },
      }}
      active={saveLoading === "pending"}
      className="overflow-auto"
      spinner={<ClipLoader color="white" size="150px" />}
    >
      <Modal
        isOpen={showModal}
        onRequestClose={(e) => {
          e.preventDefault();
          setShowModal(false);
        }}
        style={customStylesModal}
        contentLabel=""
      >
        <div className="flex flex-col items-center justify-center">
          <div className="text-center text-sm text-black mb-3">
            Debes esperar a que se terminen todas las consultas para poder
            continuar
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              setShowModal(false);
            }}
            className="bg-backgroundButtonModal rounded-lg text-center w-44 h-8 text-sm text-white font-bold"
          >
            Esperar
          </button>
        </div>
      </Modal>
      <div className="flex flex-col w-full h-full items-center justify-evenly">
        <div className="flex flex-col items-center justify-center  w-full">
          <div className="flex flex-row">
            <div
              onClick={(e) => {
                e.preventDefault();
                dispatch(resetDetailData());
                navigate(`/services/securityEvaluation/choose/${params.id}`);
              }}
              className="bg-primary-5 flex flex-row items-center justify-start h-11 w-64 rounded-md box-border px-5 cursor-pointer"
            >
              <LeftArrow className="mr-16" />
              <div className="text-primary text-lg font-bold">Regresar</div>
            </div>
            <div className="text-4xl text-primary text-center font-extrabold mb-4 ml-10">
              Estudio de Seguridad
            </div>
          </div>

          <div className="text-xl text-primary text-center font-extrabold mb-4">
            Seleccione para revisar los diferentes estudios
          </div>
          <div className="flex flex-row justify-evenly items-center w-full">
            {request?.driverIdentification ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setElementSelected("Conductor");
                }}
                className={`p-2 text-center box-border py-1 ${
                  elementSelected === "Conductor"
                    ? "text-primary"
                    : "text-primary-20"
                } bg-primary-10 rounded-md font-bold hover:text-primary`}
              >
                Conductor
              </button>
            ) : (
              ""
            )}
            {request?.ownerIdentification ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setElementSelected("Propietario");
                }}
                className={`p-2 text-center box-border py-1 ${
                  elementSelected === "Propietario"
                    ? "text-primary"
                    : "text-primary-20"
                } bg-primary-10 rounded-md font-bold hover:text-primary`}
              >
                Propietario
              </button>
            ) : (
              ""
            )}
            {request?.placaVehicle ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setElementSelected("Vehiculo");
                }}
                className={`p-2 text-center box-border py-1 ${
                  elementSelected === "Vehiculo"
                    ? "text-primary"
                    : "text-primary-20"
                } bg-primary-10 rounded-md font-bold hover:text-primary`}
              >
                Vehiculo
              </button>
            ) : (
              ""
            )}
            {request?.placaTrailer ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setElementSelected("Remolque");
                }}
                className={`p-2 text-center box-border py-1 ${
                  elementSelected === "Remolque"
                    ? "text-primary"
                    : "text-primary-20"
                } bg-primary-10 rounded-md font-bold hover:text-primary`}
              >
                Remolque
              </button>
            ) : (
              ""
            )}
            {request?.officialIdentification ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setElementSelected("Funcionario");
                }}
                className={`p-2 text-center box-border py-1 ${
                  elementSelected === "Funcionario"
                    ? "text-primary"
                    : "text-primary-20"
                } bg-primary-10 rounded-md font-bold hover:text-primary`}
              >
                Funcionario
              </button>
            ) : (
              ""
            )}

            {request?.providerNit ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setElementSelected("Proveedor");
                }}
                className={`p-2 text-center box-border py-1 ${
                  elementSelected === "Proveedor"
                    ? "text-primary"
                    : "text-primary-20"
                } bg-primary-10 rounded-md font-bold hover:text-primary`}
              >
                Proveedor
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full items-center">
          {elementSelected === "Conductor" ? (
            <div className="h-full flex flex-col w-full items-center">
              <div className="text-3xl text-primary text-center font-extrabold my-2">
                Conductor
              </div>
              <div className="flex flex-row items-center justify-center w-full">
                <div className="flex flex-row justify-evenly w-full">
                  <div className="flex flex-row justify-center w-full mx-5">
                    <div className="text-lg text-primary-35 font-bold mr-10">
                      No. de documento
                    </div>
                    <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 text-center text-primary text-sm font-semibold">
                      {request?.driverIdentification}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row w-full justify-evenly my-5">
                  <div className="flex flex-row justify-center w-full mx-5">
                    <div className="text-lg text-primary-35 font-bold mr-10">
                      Tipo de documento
                    </div>
                    <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-4 py-2 text-center text-primary text-sm font-semibold">
                      {request?.driverIdType === "cc"
                        ? "Cedula de ciudadanía"
                        : "Cedula de extranjería"}
                    </div>
                  </div>
                </div>
              </div>
              {request?.driverIdentification ? (
                <div className="overflow-auto w-full">
                  <FosygaResult
                    item={conductorResult.fosyga}
                    loading={conductorResult.fosygaLoading}
                  />
                  <LicenciaSuspendida
                    item={conductorResult.licenciaSuspendida}
                    loading={conductorResult.licenciaLoading}
                  />
                  <PoliciaResult
                    item={conductorResult.policia}
                    loading={conductorResult.loadingPolicia}
                  />
                  <ProcuraduriaResult
                    item={conductorResult.procuraduria}
                    loading={conductorResult.procuraduriaLoading}
                  />
                  <RamaJudicialResult
                    item={conductorResult.ramaJudicial}
                    loading={conductorResult.loadingRama}
                  />
                  <ProcesosUnificadosResult
                    item={conductorResult.procesos}
                    loading={conductorResult.procuraduriaLoading}
                  />
                  <RuntPersona
                    item={conductorResult.runtPersona}
                    loading={conductorResult.loadingRuntPersona}
                  />
                  <MultaResult
                    item={conductorResult.multas}
                    loading={conductorResult.multasLoading}
                  />
                  <RNDCResult
                    items={conductorResult.rndc}
                    loading={conductorResult.loadingRndc}
                  />

                  <ListaRestrictiva
                    name="OFAC"
                    item={conductorResult.ofac}
                    loading={conductorResult.loadingOfac}
                  />
                  <ListaRestrictiva
                    name="ONU"
                    item={conductorResult.onu}
                    loading={conductorResult.loadingOnu}
                  />
                  <ListaRestrictiva
                    name="UE"
                    item={conductorResult.ue}
                    loading={conductorResult.loadingUE}
                  />
                  <SofiaPlusComponent
                    item={conductorResult.sofiaPlus}
                    loading={conductorResult.loadingSofiaPlus}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {elementSelected === "Propietario" ? (
            <div className="h-full flex flex-col w-full items-center">
              <div className="text-3xl text-primary text-center font-extrabold my-2">
                Propietario
              </div>
              <div className="flex flex-row items-center justify-center w-full">
                <div className="flex flex-row justify-evenly w-full">
                  <div className="flex flex-row justify-center w-full mx-5">
                    <div className="text-lg text-primary-35 font-bold mr-10">
                      No. de documento
                    </div>
                    <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 text-center text-primary text-sm font-semibold">
                      {request?.ownerIdentification}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row w-full justify-evenly my-5">
                  <div className="flex flex-row justify-center w-full mx-5">
                    <div className="text-lg text-primary-35 font-bold mr-10">
                      Tipo de documento
                    </div>
                    <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-4 py-2 text-center text-primary text-sm font-semibold">
                      {request?.ownerIdType === "cc"
                        ? "Cedula de ciudadanía"
                        : "Nit"}
                    </div>
                  </div>
                </div>
              </div>
              {request?.ownerIdentification ? (
                <div className="overflow-auto w-full">
                  <PoliciaResult
                    item={ownerResult.policia}
                    loading={ownerResult.loadingPolicia}
                  />
                  <ProcuraduriaResult
                    item={ownerResult.procuraduria}
                    loading={ownerResult.procuraduriaLoading}
                  />
                  <RamaJudicialResult
                    item={ownerResult.ramaJudicial}
                    loading={ownerResult.loadingRama}
                  />
                  <ProcesosUnificadosResult
                    item={ownerResult.procesos}
                    loading={ownerResult.procuraduriaLoading}
                  />
                  <ListaRestrictiva
                    name="OFAC"
                    item={ownerResult.ofac}
                    loading={ownerResult.loadingOfac}
                  />
                  <ListaRestrictiva
                    name="ONU"
                    item={ownerResult.onu}
                    loading={ownerResult.loadingOnu}
                  />
                  <ListaRestrictiva
                    name="UE"
                    item={ownerResult.ue}
                    loading={ownerResult.loadingUE}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {elementSelected === "Proveedor" ? (
            <div className="h-full flex flex-col w-full items-center">
              <div className="text-3xl text-primary text-center font-extrabold my-2">
                Cliente o proveedor
              </div>
              <div className="flex flex-row items-center justify-center w-full">
                <div className="flex flex-row justify-evenly w-full">
                  <div className="flex flex-row justify-center w-full mx-5">
                    <div className="text-lg text-primary-35 font-bold mr-10">
                      No. de documento
                    </div>
                    <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 text-center text-primary text-sm font-semibold">
                      {request?.providerNit}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row w-full justify-evenly my-5">
                  <div className="flex flex-row justify-center w-full mx-5">
                    <div className="text-lg text-primary-35 font-bold mr-10">
                      Tipo de documento
                    </div>
                    <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-4 py-2 text-center text-primary text-sm font-semibold">
                      NIT
                    </div>
                  </div>
                </div>
              </div>
              {request?.providerNit ? (
                <div className="overflow-auto w-full">
                  <ProcuraduriaResult
                    item={providerResult.procuraduria}
                    loading={providerResult.procuraduriaLoading}
                  />
                  <ProcesosUnificadosResult
                    item={providerResult.procesos}
                    loading={providerResult.procuraduriaLoading}
                  />

                  <ListaRestrictiva
                    name="OFAC"
                    item={providerResult.ofac}
                    loading={providerResult.loadingOfac}
                  />
                  <ListaRestrictiva
                    name="ONU"
                    item={providerResult.onu}
                    loading={providerResult.loadingOnu}
                  />
                  <ListaRestrictiva
                    name="UE"
                    item={providerResult.ue}
                    loading={providerResult.loadingUE}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {elementSelected === "Funcionario" ? (
            <div className="h-full flex flex-col w-full items-center">
              <div className="text-3xl text-primary text-center font-extrabold my-2">
                Funcionario
              </div>
              <div className="flex flex-row items-center justify-center w-full">
                <div className="flex flex-row justify-evenly w-full">
                  <div className="flex flex-row justify-center w-full mx-5">
                    <div className="text-lg text-primary-35 font-bold mr-10">
                      No. de documento
                    </div>
                    <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 text-center text-primary text-sm font-semibold">
                      {request?.officialIdentification}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row w-full justify-evenly my-5">
                  <div className="flex flex-row justify-center w-full mx-5">
                    <div className="text-lg text-primary-35 font-bold mr-10">
                      Tipo de documento
                    </div>
                    <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-4 py-2 text-center text-primary text-sm font-semibold">
                      {request?.officialIdType === "cc"
                        ? "Cedula de ciudadanía"
                        : "Nit"}
                    </div>
                  </div>
                </div>
              </div>
              {request?.officialIdentification ? (
                <div className="overflow-auto w-full">
                  <PoliciaResult
                    item={officialResult.policia}
                    loading={officialResult.loadingPolicia}
                  />
                  <ProcuraduriaResult
                    item={officialResult.procuraduria}
                    loading={officialResult.procuraduriaLoading}
                  />
                  <RamaJudicialResult
                    item={officialResult.ramaJudicial}
                    loading={officialResult.loadingRama}
                  />
                  <ProcesosUnificadosResult
                    item={officialResult.procesos}
                    loading={officialResult.procuraduriaLoading}
                  />

                  <ListaRestrictiva
                    name="OFAC"
                    item={officialResult.ofac}
                    loading={officialResult.loadingOfac}
                  />
                  <ListaRestrictiva
                    name="ONU"
                    item={officialResult.onu}
                    loading={officialResult.loadingOnu}
                  />
                  <ListaRestrictiva
                    name="UE"
                    item={officialResult.ue}
                    loading={officialResult.loadingUE}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {elementSelected === "Vehiculo" ? (
            <div className="h-full flex flex-col w-full items-center">
              <div className="text-3xl text-primary text-center font-extrabold my-4">
                Vehículo
              </div>
              <div className="flex flex-row items-center justify-center w-full">
                <div className="flex flex-row justify-evenly w-full">
                  <div className="flex flex-row justify-center w-full mx-5">
                    <div className="text-lg text-primary-35 font-bold mr-10">
                      Placa
                    </div>
                    <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 text-center text-primary text-sm font-semibold">
                      {request?.placaVehicle}
                    </div>
                  </div>
                </div>
              </div>
              {request?.placaVehicle ? (
                <div className="overflow-auto w-full">
                  <RuntPlaca
                    item={vehicleResult.runt}
                    loading={vehicleResult.runtLoading}
                  />
                  <RNDCResult
                    items={vehicleResult.rndc}
                    loading={vehicleResult.rndcLoading}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {elementSelected === "Remolque" ? (
            <div className="h-full flex flex-col w-full items-center">
              <div className="text-3xl text-primary text-center font-extrabold my-4">
                Remolque
              </div>
              <div className="flex flex-row items-center justify-center w-full">
                <div className="flex flex-row justify-evenly w-full">
                  <div className="flex flex-row justify-center w-full mx-5">
                    <div className="text-lg text-primary-35 font-bold mr-10">
                      Placa
                    </div>
                    <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 text-center text-primary text-sm font-semibold">
                      {request?.placaTrailer}
                    </div>
                  </div>
                  {request?.trailerOwnerIdentification ? (
                    <div className="flex flex-row justify-center w-full mx-5">
                      <div className="text-lg text-primary-35 font-bold mr-10">
                        Identificador propietario
                      </div>
                      <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 text-center text-primary text-sm font-semibold">
                        {request?.trailerOwnerIdentification}
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-row justify-center w-full mx-5">
                      <div className="text-lg text-primary-35 font-bold mr-10">
                        Identificador propietario
                      </div>
                      <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 text-center text-primary text-sm font-semibold">
                        {request?.ownerIdentification}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {request?.placaTrailer ? (
                <div className="overflow-auto w-full">
                  <RuntPlaca
                    item={trailerResult.runt}
                    loading={trailerResult.runtLoading}
                  />
                  <RNDCResult
                    items={trailerResult.rndc}
                    loading={trailerResult.rndcLoading}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="flex flex-row justify-evenly items-center w-full h-2/6 mb-5">
          <div className="flex flex-col items-start justify-center">
            <label className="text-sm text-primary-35 font-bold">
              <input
                type="radio"
                value="Aceptado"
                checked={radioValue === "Aceptado"}
                onChange={(e) => {
                  setRadioValue(e.target.value);
                }}
              />
              <span className="ml-2">APROBADA</span>
            </label>
            <label className="text-sm text-primary-35 font-bold">
              <input
                type="radio"
                value="Rechazado"
                checked={radioValue === "Rechazado"}
                onChange={(e) => {
                  setRadioValue(e.target.value);
                }}
              />
              <span className="ml-2">RECHAZADA</span>
            </label>
            <label className="text-sm text-primary-35 font-bold">
              <input
                type="radio"
                value="Favorable"
                checked={radioValue === "Favorable"}
                onChange={(e) => {
                  setRadioValue(e.target.value);
                }}
              />
              <span className="ml-2">FAVORABLE CON OBSERVACIONES</span>
            </label>
          </div>
          <div className="w-1/2">
            <label className="text-sm text-primary-35 font-bold">
              <span className="mr-2">Comentario</span>
              <input
                className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold h-10 px-6 w-full"
                type="text"
                value={comentario}
                onChange={(e) => {
                  setComentario(e.target.value);
                }}
              />
            </label>
          </div>
          {radioValue !== "" && (
            <div
              onClick={(e) => {
                e.preventDefault();
                if (isStillLoading()) {
                  setShowModal(true);
                  return;
                }
                let commentsNew: Array<CommentRequest> = [];
                if (request && request.comments) {
                  commentsNew = request.comments;

                  commentsNew = [
                    ...request.comments,
                    {
                      content: comentario,
                      date: new Date().toISOString(),
                    },
                  ];
                }
                dispatch(
                  saveResult({
                    comments: commentsNew,
                    status: radioValue,
                    observations: "",
                  })
                );
              }}
              className="flex flex-col items-center justify-center h-10 w-60 text-primary bg-primary-5 rounded-md text-center font-bold cursor-pointer mx-5 mt-6"
            >
              {saveLoading === "pending" ? (
                <ClipLoader color="white" size="20px" />
              ) : (
                "Continuar"
              )}
            </div>
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
};
