import addMinutes from "date-fns/addMinutes";
import format from "date-fns/format";

let formatDateStandar = (dateString: string) => {
  let date = new Date(dateString);
  date = addMinutes(date, date.getTimezoneOffset() * -1);
  if (date) {
    let formatedDate = format(date, "dd/MM/yyyy");
    let formatedTime = format(date, "hh:mm a");
    return `${formatedDate} | ${formatedTime}`;
  } else {
    return "Sin Fecha";
  }
};

let isEmpty = (string: string) => {
  return !string || string.trim() === "";
};
export { formatDateStandar, isEmpty };
