import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";

import {
  selectLoadingStatus,
  selectCurrentSolicitud,
  selectFilteredConsultAlertResponse,
  setCurrentSolicitud,
} from "../updateDataSlice";

import { ReactComponent as LeftArrow } from "../../../../Assets/images/leftArrow.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const DetailAlertUpdateData = () => {
  let loadingStatus = useAppSelector(selectLoadingStatus);
  let currentSelect = useAppSelector(selectCurrentSolicitud);
  let solicitudes = useAppSelector(selectFilteredConsultAlertResponse);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  useEffect(() => {}, [dispatch, currentSelect, solicitudes]);

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      <div className="flex flex-col items-center  w-full h-full">
        {/* Header */}
        <div className="flex flex-row h-1/5 w-full items-center justify-evenly">
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/services/updateData");
            }}
            className="bg-primary-5 flex flex-row items-center justify-start h-11 w-64 rounded-md box-border px-5 cursor-pointer"
          >
            <LeftArrow className="mr-16" />
            <div className="text-primary text-lg font-bold">Regresar</div>
          </div>
          <div className="text-3xl text-primary font-extrabold text-center">
            Actualización de datos {currentSelect?.dataEvaluado.tipoEvaluado}
          </div>
          <div></div>
        </div>

        <div className="flex flex-col items-center w-full h-full">
          <div className="flex flex-col h-3/6 w-5/6 justify-center items-start space-y-6">
            <div className="flex flex-row  items-center w-full mr-20 justify-center">
              <div className="lg:w-4/6 sm:w-4/5 xl:w-2/5 text-1xl font-bold text-primary-35  ">
                Nombres y Apellidos
              </div>
              <input
                value={
                  currentSelect?.dataEvaluado.tipoIdentificacion === "CC"
                    ? currentSelect?.dataEvaluado.nombres
                    : currentSelect?.dataEvaluado.tipoIdentificacion === "NIT"
                    ? currentSelect?.dataEvaluado.razonSocial
                    : currentSelect?.dataEvaluado.tipoIdentificacion === "Placa"
                    ? currentSelect?.dataEvaluado.identificacion
                    : ""
                }
                onChange={(e) => {}}
                className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold h-10 px-6 w-3/5"
              />
              <div className="lg:w-4/6 sm:w-4/5 xl:w-2/5 text-1xl font-bold text-primary-35 ml-40">
                No. de documento
              </div>
              <input
                value={currentSelect?.dataEvaluado.identificacion}
                onChange={(e) => {}}
                className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold h-10 px-6 w-3/5"
              />
            </div>

            <div className="flex flex-row  items-center w-full mr-20 justify-center">
              <div className="lg:w-4/6 sm:w-4/5 xl:w-2/5 text-1xl font-bold text-primary-35  ">
                Tipo de documento
              </div>
              <input
                value={currentSelect?.dataEvaluado.tipoIdentificacion}
                onChange={(e) => {}}
                className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold h-10 px-6 w-3/5"
              />
              <div className="lg:w-4/6 sm:w-4/5 xl:w-2/5 text-1xl font-bold text-primary-35 ml-40">
                No. de alertas
              </div>
              <input
                value={currentSelect?.fuentesConAlertas.length}
                onChange={(e) => {}}
                className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold h-10 px-6 w-3/5"
              />
            </div>
          </div>

          <div className="flex flex-col w-5/6 justify-center items-start space-y-2">
            <div className="flex flex-row  items-center w-full mr-20 justify-center mb-5">
              <div className="lg:w-4/6 sm:w-4/5 xl:w-2/5 text-1xl font-bold text-primary-35 text-center">
                Tipo de alerta
              </div>
              <div className="lg:w-4/6 sm:w-4/5 xl:w-2/5 text-1xl font-bold text-primary-35 ml-40 text-center">
                Fuente de Consulta
              </div>
            </div>

            {currentSelect?.fuentesConAlertas.map((solicitud, i) => {
              return (
                <div className="flex flex-row  items-center w-full mr-20 justify-center">
                  <div className="lg:w-4/6 sm:w-4/5 xl:w-2/5 text-1xl font-semibold text-primary text-center">
                    {solicitud.tipoAlerta}
                  </div>
                  <div className="lg:w-4/6 sm:w-4/5 xl:w-2/5 text-1xl font-semibold text-primary ml-40 text-center">
                    {solicitud.dataFuente.nombre}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-row mt-4">
          <div className="flex flex-col items-center justify-center h-1/5 mr-4">
            <div
              onClick={(e) => {
                e.preventDefault();
              }}
              className="flex flex-col items-center justify-center h-10 w-60 text-primary bg-primary-5 rounded-md text-center font-bold cursor-pointer"
            >
              Rechazar
            </div>
          </div>
          <div className="flex flex-col items-center justify-center h-1/5">
            <div
              onClick={(e) => {
                e.preventDefault();
                dispatch(setCurrentSolicitud(currentSelect));
                navigate("/services/updateData/detail/sources");
              }}
              className="flex flex-col items-center justify-center h-10 w-60 text-primary bg-primary-5 rounded-md text-center font-bold cursor-pointer"
            >
              Continuar
            </div>
          </div>
        </div>

        <div className="h-1/5 sm:w-4/5 xl:w-3/5"></div>
      </div>
    </LoadingOverlay>
  );
};

export default DetailAlertUpdateData;
