import LoadingOverlay from "react-loading-overlay-ts";
import Modal from "react-modal";
import ClipLoader from "react-spinners/ClipLoader";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  changePassword,
  changeUsername,
  emptyErrorMessage,
  selectErrorMessage,
  selectLoading,
  selectPassword,
  selectTokenString,
  selectUserInfoLogin,
  selectUsername,
  tryLogin,
} from "./loginSlice";
import { InputPrimary } from "./components/InputPrimary";
import { useEffect, useState } from "react";
import addHours from "date-fns/addHours";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { LoginButton } from "./components/LoginButton";
import { setTokenInfo, setUserInfo } from "../common/commonSlice";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "20%",
    width: "20%",
    borderRadius: "20px",
  },
};

Modal.setAppElement("#root");
export const Login = () => {
  let dispatch = useAppDispatch();
  let username = useAppSelector(selectUsername);
  let password = useAppSelector(selectPassword);
  let loading = useAppSelector(selectLoading);
  let tokenString = useAppSelector(selectTokenString);
  let errorMessage = useAppSelector(selectErrorMessage);
  let userInfo = useAppSelector(selectUserInfoLogin);

  const [, setCookie] = useCookies(["token"]);
  const [modalIsOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
    dispatch(emptyErrorMessage());
  };

  let navigate = useNavigate();

  let modifyUsername = (username: string) => {
    dispatch(changeUsername(username));
  };

  let modifyPassword = (password: string) => {
    dispatch(changePassword(password));
  };

  let login = () => {
    dispatch(tryLogin());
  };

  useEffect(() => {
    if (tokenString && tokenString !== "") {
      let now = new Date();
      let inAnHour = addHours(now, 4);
      setCookie(
        "token",
        { token: tokenString, expiry: inAnHour.getTime() },
        { path: "/", expires: inAnHour, sameSite: "lax", secure: true }
      );
      dispatch(setUserInfo(userInfo!));
      dispatch(
        setTokenInfo({ expiry: inAnHour.getTime(), token: tokenString })
      );
      navigate("/services/intro");
    }

    if (errorMessage !== "") {
      setIsOpen(true);
    }

    return () => {};
  }, [tokenString, navigate, setCookie, errorMessage, dispatch, userInfo]);

  return (
    <LoadingOverlay
      active={loading}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel=""
      >
        <div className="flex flex-col items-center justify-center">
          <div className="text-center text-sm text-black mb-3">
            El usuario o la contraseña no son correctas
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
            className="bg-backgroundButtonModal rounded-lg text-center w-44 h-8 text-sm text-white font-bold"
          >
            Intente nuevamente
          </button>
        </div>
      </Modal>
      <div className="flex flex-col justify-center items-center w-screen h-screen">
        <div className="text-5xl font-extrabold text-primary mb-24">
          Bienvenido a su backoffice
        </div>
        <InputPrimary
          className="mb-8"
          inputType="email"
          placeHolder="Usuario"
          value={username}
          onChange={(e) => {
            e.preventDefault();
            modifyUsername(e.target.value);
          }}
        />
        <InputPrimary
          inputType="password"
          placeHolder="Contraseña"
          value={password}
          onChange={(e) => {
            e.preventDefault();
            modifyPassword(e.target.value);
          }}
        />
        <LoginButton
          text="Ingresar"
          disabled={loading}
          onClick={(e) => {
            e.preventDefault();
            login();
          }}
        />
      </div>
    </LoadingOverlay>
  );
};
