import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../login/loginSlice";
import {
  AlertBackOfficeModel,
  FilteredConsultAlert,
  FilteredConsultAlertResponse,
  getFilteredConsultAlertAPI,
  RequestUpdateConsultedNoAlert,
  ResponseUpdateConsultedNoAlert,
  updateConsultAlertAPI
} from "./updateDataAPI";

export interface UpdateDataState {
  loadingStatus: LoadingStatus;
  loadingStatusUpdateData: LoadingStatus;
  errorMessage: string;
  solicitudes: FilteredConsultAlertResponse | null;
  responseUpdateData: ResponseUpdateConsultedNoAlert | null;
  requestUpdateData: RequestUpdateConsultedNoAlert;
  currentFilteredRequest: FilteredConsultAlert;
  currentSolicitud: AlertBackOfficeModel | null;

}

const initialState: UpdateDataState = {
  loadingStatus: "idle",
  loadingStatusUpdateData: "idle",
  errorMessage: "",
  solicitudes: null,
  responseUpdateData: null,
  requestUpdateData: {identifier: ''},
  currentFilteredRequest: { page: 0, tipoEvaluado: null },
  currentSolicitud: null
};

export const getFilteredRequest = createAsyncThunk(
  "updateData/getFilteredRequest",
  async (filteredRequest: FilteredConsultAlert, { rejectWithValue }) => {
    try {
      const responseRequest = await getFilteredConsultAlertAPI(filteredRequest);
      if (responseRequest.error === "") {
        return responseRequest.requests;
      } else {
        return rejectWithValue(responseRequest.error);
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateConsultedNoAlert = createAsyncThunk(
  "updateData/updateConsultedNoAlert",
  async (filteredRequest: RequestUpdateConsultedNoAlert, { rejectWithValue }) => {
    try {
      const responseRequest = await updateConsultAlertAPI(filteredRequest);
      if (responseRequest.error === "") {
        return responseRequest.requests;
      } else {
        return rejectWithValue(responseRequest.error);
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);


export const updateDataSlice = createSlice({
  name: "updateDataSlice",
  initialState,
  reducers: {
    changePage: (state, action: PayloadAction<number>) => {
      state.currentFilteredRequest = {
        ...state.currentFilteredRequest,
        page: action.payload,
      };
    },
    changeCurrentFilteredRequest: (
      state,
      action: PayloadAction<FilteredConsultAlert>
    ) => {
      state.loadingStatus = "pending";
      state.currentFilteredRequest = { ...action.payload, page: 0 };
    },
    setCurrentSolicitud: (state, action: PayloadAction<AlertBackOfficeModel | null>) => {
      state.currentSolicitud = action.payload;
    },
    deleteRequest: (state) => {
      state.currentSolicitud = null;
      state.loadingStatus = "idle";
    },
    resetLoadingStatusUpdateData: (state) => {
      state.loadingStatusUpdateData = "idle";
    },
    changeRequestUpdateData: (
      state,
      action: PayloadAction<RequestUpdateConsultedNoAlert>
    ) => {
      state.loadingStatus = "pending";
      state.requestUpdateData = {...action.payload};
    },
    resetUpdateData: (state) => {
      state.loadingStatusUpdateData = "idle";
     // state.requestUpdateData = {identifier: ''};
     // state.responseUpdateData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilteredRequest.fulfilled, (state, action) => {
        let solicitud = action.payload;
        state.solicitudes = solicitud;
        state.loadingStatus = "resolved";
      })
      .addCase(getFilteredRequest.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getFilteredRequest.rejected, (state, action) => {
        state.errorMessage = action.payload as string;
        state.loadingStatus = "rejected";
      })

      .addCase(updateConsultedNoAlert.fulfilled, (state, action) => {
        let data = action.payload;
        state.responseUpdateData = data;
        state.loadingStatusUpdateData = "resolved";
      })
      .addCase(updateConsultedNoAlert.pending, (state) => {
        state.loadingStatusUpdateData = "pending";
      })
      .addCase(updateConsultedNoAlert.rejected, (state, action) => {
        state.errorMessage = action.payload as string;
        state.loadingStatusUpdateData = "rejected";
      })
  },
});

export const {
  changePage,
  changeCurrentFilteredRequest,
  setCurrentSolicitud,
  deleteRequest,
  resetLoadingStatusUpdateData,
  changeRequestUpdateData,
  resetUpdateData
} = updateDataSlice.actions;

export const selectLoadingStatus = (state: RootState) =>
  state.updateData.loadingStatus;

export const selectLoadingStatusUpdateData = (state: RootState) =>
  state.updateData.loadingStatusUpdateData;

export const selectFilteredConsultAlertResponse = (state: RootState) =>
  state.updateData.solicitudes;

export const selectUpdateDataResponse = (state: RootState) =>
  state.updateData.responseUpdateData;

export const selectCurrentFilteredConsultAlert = (state: RootState) =>
  state.updateData.currentFilteredRequest;

export const selectRequestUpdateData = (state: RootState) =>
  state.updateData.requestUpdateData;

export const selectCurrentSolicitud = (state: RootState) =>
  state.updateData.currentSolicitud;

export default updateDataSlice.reducer;
