import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ReactComponent as LeftArrow } from "../../../../Assets/images/leftArrow.svg";
import download from "../../../../Assets/images/download.png";
import {
  deleteCurrentDownloadedFile,
  downloadFile,
  getRequestById,
  resetDetailData,
  resetDetailRequest,
  selectCurrentDownloadedFile,
  selectCurrentRequest,
  selectLoadingOneRequest,
} from "../securityEvaluationSlice";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import fileDownload from "js-file-download";
import {
  resetRedirectionId,
  selectRequestCreationLoading,
  selectRequestCreationRedirectionId,
} from "../../createRequest/requestCreationSlice";

export const ChooseStudySubject = () => {
  let currentRequest = useAppSelector(selectCurrentRequest);
  let currentRequestLoading = useAppSelector(selectLoadingOneRequest);
  let currentFile = useAppSelector(selectCurrentDownloadedFile);
  let loadingStatusCreation = useAppSelector(selectRequestCreationLoading);
  let redirectionId = useAppSelector(selectRequestCreationRedirectionId);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let params = useParams<{ id: string }>();

  useEffect(() => {
    if (redirectionId !== "" || loadingStatusCreation === "resolved") {
      dispatch(resetRedirectionId());
    }

    window.addEventListener("popstate", () => {
      dispatch(resetDetailRequest());
    });
    if (currentFile && currentFile.dataBase64 !== "") {
      let url = `data:application/pdf;base64,${currentFile.dataBase64}`;
      let fileName = currentFile.fileName.split("/").pop();
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          fileDownload(blob, fileName!);
        });
      dispatch(deleteCurrentDownloadedFile());
    }
    if (!currentRequest || currentRequest.id !== params.id) {
      dispatch(getRequestById(params.id!));
    }
  }, [currentRequest, params, dispatch, currentRequestLoading, currentFile]);

  return (
    <>
      {currentRequestLoading === "pending" ? (
        <LoadingOverlay
          active={currentRequestLoading === "pending"}
          spinner={<ClipLoader color="white" size="150px" />}
        ></LoadingOverlay>
      ) : (
        <div className="flex flex-col items-center  w-full h-5/6">
          <div className="flex flex-row h-1/5 w-full items-center justify-evenly">
            <div
              onClick={(e) => {
                e.preventDefault();
                dispatch(resetDetailData());
                navigate("/services/requestCreation/selectTypes");
              }}
              className="bg-primary-5 flex flex-row items-center justify-start h-11 w-64 rounded-md box-border px-5 cursor-pointer"
            >
              <LeftArrow className="mr-16" />
              <div className="text-primary text-lg font-bold">Regresar</div>
            </div>
            <div className="text-5xl text-primary font-extrabold text-center">
              Estudio de Seguridad
            </div>
          </div>
          <div className="flex flex-col h-3/5 sm:w-4/5 xl:w-3/5 items-center justify-evenly space-y-8">
            <div className="flex flex-col">
              <div className="text-primary text-lg font-bold">
                Esta consulta fue creada por
              </div>
              <div className="text-primary text-xl font-bold text-center">
                {currentRequest?.user.name}
              </div>
            </div>
            <div className="text-primary text-lg font-bold text-center">
              El estudio de seguridad consiste de evaluar los siguientes sujetos
            </div>
            <div className="flex flex-row justify-evenly w-full">
              {currentRequest?.driverIdentification ? (
                <div className="flex flex-col">
                  <div className="text-primary text-lg font-bold">
                    Conductor
                  </div>
                  <div className="text-primary text-lg font-bold">
                    Cedula: {currentRequest?.driverIdentification}
                  </div>
                </div>
              ) : (
                ""
              )}
              {currentRequest?.ownerIdentification ? (
                <div className="flex flex-col">
                  <div className="text-primary text-lg font-bold">
                    Propietario
                  </div>
                  <div className="text-primary text-lg font-bold">
                    Cedula: {currentRequest?.ownerIdentification}
                  </div>
                </div>
              ) : (
                ""
              )}
              {currentRequest?.placaVehicle ? (
                <div className="flex flex-col">
                  <div className="text-primary text-lg font-bold">Vehículo</div>
                  <div className="text-primary text-lg font-bold">
                    Placa: {currentRequest?.placaVehicle}
                  </div>
                </div>
              ) : (
                ""
              )}
              {currentRequest?.placaTrailer ? (
                <div className="flex flex-col">
                  <div className="text-primary text-lg font-bold">Remolque</div>
                  <div className="text-primary text-lg font-bold">
                    Placa: {currentRequest?.placaTrailer}
                  </div>
                </div>
              ) : (
                ""
              )}
              {currentRequest?.officialIdentification ? (
                <div className="flex flex-col">
                  <div className="text-primary text-lg font-bold">
                    Funcionario
                  </div>
                  <div className="text-primary text-lg font-bold">
                    Cedula: {currentRequest?.officialIdentification}
                  </div>
                  <div className="text-primary text-lg font-bold">
                    Cargo: {currentRequest?.officialPosition}
                  </div>
                </div>
              ) : (
                ""
              )}
              {currentRequest?.providerNit ? (
                <div className="flex flex-col">
                  <div className="text-primary text-lg font-bold">
                    Proveedor o cliente
                  </div>
                  <div className="text-primary text-lg font-bold">
                    Cedula: {currentRequest?.providerNit}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {currentRequest?.observations ? (
              <div className="text-primary text-base font-bold text-center">
                El solicitante dejó las siguientes observaciones:
              </div>
            ) : (
              ""
            )}
            {currentRequest?.observations ? (
              <div className="text-primary text-base font-bold text-center backdrop-brightness-50 px-2 py-2 rounded-lg">
                {currentRequest.observations}
              </div>
            ) : (
              ""
            )}
            {currentRequest?.fileNames &&
              currentRequest.fileNames.length > 0 && (
                <div className="flex flex-col">
                  <div className="text-white font-bold">Archivos</div>
                  {currentRequest.fileNames.map((file, i) => {
                    return (
                      <div className="flex flex-rpw w-full">
                        <div className="text-white text-lg">
                          {file.fileName}
                        </div>
                        <div
                          onClick={() => {
                            dispatch(downloadFile(file.fileId));
                          }}
                          className="mx-4 flex w-8 h-8 items-center cursor-pointer"
                        >
                          <img
                            src={download}
                            alt="Download"
                            className="h-6 w-6 "
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            <div className="text-primary text-base font-bold text-center">
              Para empezar el estudio puede ejecutarlo con el siguiente botón:
            </div>
          </div>

          <div className="flex flex-col h-1/6 items-center justify-center">
            <div
              onClick={(e) => {
                e.preventDefault();

                navigate(`/services/securityEvaluation/automatic/${params.id}`);
              }}
              className="flex flex-col items-center justify-center h-10 w-60 text-primary bg-primary-5 rounded-md text-center font-bold cursor-pointer"
            >
              Ejecutar estudio
            </div>
          </div>
        </div>
      )}
    </>
  );
};
