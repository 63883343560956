import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  changeCurrentFilteredRequest,
  changePage,
  getFilteredRequest,
  resetDetailData,
  resetState,
  selectCurrentFilteredRequest,
  selectCurrentFilteredResponse,
  selectLoadingRequests,
} from "./securityEvaluationSlice";
import { ReactComponent as LeftArrow } from "../../../Assets/images/leftArrow.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { formatDateStandar } from "../../../helpers/utils";
import { FilterButton } from "./components/FilterButton";
import { selectCurrentStudy, selectUserInfo } from "../../common/commonSlice";

const SecurityEvaluation = () => {
  let loadingStatus = useAppSelector(selectLoadingRequests);
  let requests = useAppSelector(selectCurrentFilteredResponse);
  let filteredRequest = useAppSelector(selectCurrentFilteredRequest);
  let userInfo = useAppSelector(selectUserInfo);
  let currentStudo = useAppSelector(selectCurrentStudy);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(getFilteredRequest({ ...filteredRequest, type: currentStudo }));
    dispatch(resetDetailData());
  }, [dispatch, filteredRequest, currentStudo]);

  let getTable = (subject: string) => {
    if (requests && requests.requests.length > 0) {
      //Tipo de tabla

      switch (subject) {
        case "transportes":
          return (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha de creación de consulta
                  </th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Tipo de consulta
                  </th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Identificación Conductor
                  </th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Placa Vehiculo
                  </th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Placa Remolque
                  </th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Identificador Propietario
                  </th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Identificador Propietario Remolque
                  </th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Estado
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {requests.requests.map((solicitud, i) => {
                  return (
                    <tr
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          userInfo?.roles.includes("admin") ||
                          userInfo?.roles.includes("security") ||
                          userInfo?.roles.includes("agent")
                        ) {
                          if (solicitud.status !== "Por Revisar") {
                            navigate(
                              `/services/securityEvaluation/consult/${solicitud.id}`
                            );
                          } else {
                            navigate(
                              `/services/securityEvaluation/choose/${solicitud.id}`
                            );
                          }
                        } else {
                          if (solicitud.status !== "Por Revisar") {
                            navigate(
                              `/services/securityEvaluation/consult/${solicitud.id}`
                            );
                          }
                        }
                      }}
                      key={i}
                      className="text-center text-primary text-sm font-semibold cursor-pointer h-7 hover:bg-primary-35"
                    >
                      <td></td>
                      <td>{formatDateStandar(solicitud.creationDate)}</td>
                      <td>Transportes</td>
                      <td>{solicitud.driverIdentification || "No Aplica"}</td>
                      <td>{solicitud.placaVehicle || "No Aplica"}</td>
                      <td>{solicitud.placaTrailer || "No Aplica"}</td>
                      <td>{solicitud.ownerIdentification || "No Aplica"}</td>
                      <td>
                        {solicitud.trailerOwnerIdentification || "No Aplica"}
                      </td>
                      <td>{solicitud.status}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          );
        case "funcionarios":
          return (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha de creación de consulta
                  </th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Tipo de consulta
                  </th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Identificación Funcionario
                  </th>

                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Estado
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {requests.requests.map((solicitud, i) => {
                  return (
                    <tr
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          userInfo?.roles.includes("admin") ||
                          userInfo?.roles.includes("security") ||
                          userInfo?.roles.includes("agent")
                        ) {
                          if (solicitud.status !== "Por Revisar") {
                            navigate(
                              `/services/securityEvaluation/consult/${solicitud.id}`
                            );
                          } else {
                            navigate(
                              `/services/securityEvaluation/choose/${solicitud.id}`
                            );
                          }
                        } else {
                          if (solicitud.status !== "Por Revisar") {
                            navigate(
                              `/services/securityEvaluation/consult/${solicitud.id}`
                            );
                          }
                        }
                      }}
                      key={i}
                      className="text-center text-primary text-sm font-semibold cursor-pointer h-7 hover:bg-primary-35"
                    >
                      <td></td>
                      <td>{formatDateStandar(solicitud.creationDate!)}</td>
                      <td>Funcionarios</td>
                      <td>{solicitud.officialIdentification}</td>
                      <td>{solicitud.status}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          );
        case "clients":
          return (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha de creación de consulta
                  </th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Tipo de consulta
                  </th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    NIT
                  </th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Estado
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {requests.requests.map((solicitud, i) => {
                  return (
                    <tr
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          userInfo?.roles.includes("admin") ||
                          userInfo?.roles.includes("security") ||
                          userInfo?.roles.includes("agent")
                        ) {
                          if (solicitud.status !== "Por Revisar") {
                            navigate(
                              `/services/securityEvaluation/consult/${solicitud.id}`
                            );
                          } else {
                            navigate(
                              `/services/securityEvaluation/choose/${solicitud.id}`
                            );
                          }
                        } else {
                          if (solicitud.status !== "Por Revisar") {
                            navigate(
                              `/services/securityEvaluation/consult/${solicitud.id}`
                            );
                          }
                        }
                      }}
                      key={i}
                      className="text-center text-primary text-sm font-semibold cursor-pointer h-7 hover:bg-primary-35"
                    >
                      <td></td>
                      <td>{formatDateStandar(solicitud.creationDate!)}</td>
                      <td>Clientes y proveedores</td>
                      <td>{solicitud.providerNit}</td>
                      <td>{solicitud.status}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          );
        default:
          return (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Realizada por
                  </th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Estado
                  </th>
                  <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {requests.requests.map((solicitud, i) => {
                  return (
                    <tr
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          userInfo?.roles.includes("admin") ||
                          userInfo?.roles.includes("security") ||
                          userInfo?.roles.includes("agent")
                        ) {
                          if (solicitud.status !== "Por Revisar") {
                            navigate(
                              `/services/securityEvaluation/consult/${solicitud.id}`
                            );
                          } else {
                            navigate(
                              `/services/securityEvaluation/choose/${solicitud.id}`
                            );
                          }
                        } else {
                          if (solicitud.status !== "Por Revisar") {
                            navigate(
                              `/services/securityEvaluation/consult/${solicitud.id}`
                            );
                          }
                        }
                      }}
                      key={i}
                      className="text-center text-primary text-sm font-semibold cursor-pointer h-7 hover:bg-primary-35"
                    >
                      <td></td>
                      <td>{solicitud.status}</td>
                      <td>{formatDateStandar(solicitud.creationDate)}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          );
      }
    }
  };
  return (
    <>
      {loadingStatus === "pending" ? (
        <LoadingOverlay
          active={loadingStatus === "pending"}
          spinner={<ClipLoader color="white" size="150px" />}
        ></LoadingOverlay>
      ) : (
        <div className="flex flex-col items-center w-full h-5/6">
          <div className="flex flex-row h-1/5 w-full items-center justify-evenly">
            <div
              onClick={(e) => {
                e.preventDefault();
                dispatch(resetState());
                navigate("/services/intro");
              }}
              className="bg-primary-5 flex flex-row items-center justify-start h-11 w-64 rounded-md box-border px-5 cursor-pointer"
            >
              <LeftArrow className="mr-16" />
              <div className="text-primary text-lg font-bold">Regresar</div>
            </div>
            <div className="text-5xl text-primary font-extrabold text-center">
              Estudio de Seguridad
            </div>
            <div></div>
          </div>
          <div className="flex flex-col h-3/5 sm:w-10/12 xl:w-8/12">
            <div className="flex-grow overflow-auto h-4/5 w-full">
              {requests && requests.requests && requests.requests.length > 0 ? (
                getTable(currentStudo)
              ) : (
                <div className="flex flex-col h-3/5 w-full items-center justify-center">
                  <div className="text-3xl text-primary font-semibold text-center">
                    {loadingStatus === "idle"
                      ? "Lista de consultas"
                      : "No se encontraron consultas"}
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col h-1/5 items-center justify-between">
              <div className="flex flex-row items-center justify-center">
                <div
                  className="flex flex-row font-bold text-primary"
                  style={{ fontSize: "13px" }}
                >
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      if (filteredRequest.page > 0) {
                        dispatch(changePage(filteredRequest.page - 1));
                      }
                    }}
                    className="w-8 px-4 h-8 text-center cursor-pointer text-base"
                  >
                    {"<"}
                  </div>
                  Página {filteredRequest.page + 1} de {requests?.numPages || 0}
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      if (filteredRequest.page < requests?.numPages! - 1) {
                        dispatch(changePage(filteredRequest.page + 1));
                      }
                    }}
                    className="w-8 px-4 h-8 text-center cursor-pointer text-base"
                  >
                    {">"}
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full items-center justify-evenly">
                <FilterButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      changeCurrentFilteredRequest({
                        ...filteredRequest,
                        status: "Por Revisar",
                      })
                    );
                  }}
                  text="Por Revisar"
                  selected={filteredRequest.status === "Por Revisar"}
                />
                <FilterButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      changeCurrentFilteredRequest({
                        ...filteredRequest,
                        status: "Aceptado",
                      })
                    );
                  }}
                  text="Aceptadas"
                  selected={filteredRequest.status === "Aceptado"}
                />
                <FilterButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      changeCurrentFilteredRequest({
                        ...filteredRequest,
                        status: "Rechazado",
                      })
                    );
                  }}
                  text="Rechazadas"
                  selected={filteredRequest.status === "Rechazado"}
                />
                <FilterButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      changeCurrentFilteredRequest({
                        ...filteredRequest,
                        status: null,
                      })
                    );
                  }}
                  text="Todas"
                  selected={
                    !filteredRequest.status || filteredRequest.status === ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="h-1/5 sm:w-4/5 xl:w-3/5"></div>
        </div>
      )}
    </>
  );
};

export default SecurityEvaluation;
