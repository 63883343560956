import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { AlertBackOfficeSourceModel } from "../updateDataAPI";

import {
  resetLoadingStatusUpdateData,
  resetUpdateData,
  selectCurrentSolicitud,
  selectLoadingStatusUpdateData,
  selectUpdateDataResponse,
  updateConsultedNoAlert,
} from "../updateDataSlice";

import Modal, { Styles } from "react-modal";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "20%",
    width: "20%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "2%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};

Modal.setAppElement("#root");

export const DetailAlertUpdateDataSource = () => {
  let loadingStatusUpdateData = useAppSelector(selectLoadingStatusUpdateData);
  let currentSelect = useAppSelector(selectCurrentSolicitud);
  let updateDataResponse = useAppSelector(selectUpdateDataResponse);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let tipoEvaluadoValue = currentSelect?.tipoEvaluado;

  useEffect(() => {}, [dispatch, currentSelect, tipoEvaluadoValue]);

  const closeModal = () => {
    dispatch(resetLoadingStatusUpdateData());
    navigate("/services/updateData");
  };

  let getTipoDocumentoLabel = (value: string) => {
    switch (value) {
      case "CC":
        return "Cedula de Ciudadanía";
      case "NIT":
        return "Nit";
      case "CE":
        return "Cedula de extranjería";
      case "Pasaporte":
        return "Pasaporte";
      default:
        return "";
    }
  };

  let getDetailAlertsByEvaluatedType = (
    type: any,
    data: AlertBackOfficeSourceModel
  ) => {
    switch (type) {
      case "Conductor":
        return (
          <>
            {data && data.resultComparendo != null ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="grid grid-cols-2">
                  <div className="text-primary text-xl font-semibold text-center">
                    Comparendos e infracciones
                  </div>
                  <div className="text-primary text-xl font-semibold text-center">
                    SIMIT y Moviidad
                  </div>
                </div>

                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        No. Comparendo
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Secretaría
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha de notificación
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Nombre infractor
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Estado
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Infracción
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Valor Multa
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Valor adicional
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Interés mora
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Valor a pagar
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultComparendo.comparendos.map((comparendo, i) => {
                      return (
                        <tr
                          key={i}
                          className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                        >
                          <td></td>
                          <td>{comparendo.numeroComparendo}</td>
                          <td>{comparendo.secretaria}</td>
                          <td>{comparendo.fecha}</td>
                          <td>{comparendo.fechaNotificacion}</td>
                          <td>{comparendo.nombreInfractor}</td>
                          <td>{comparendo.estado}</td>
                          <td>{comparendo.infraccion}</td>
                          <td>{comparendo.valorMulta}</td>
                          <td>{comparendo.valorAdicional}</td>
                          <td>{comparendo.interesMora}</td>
                          <td>{comparendo.valorPagar}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            {data && data.resultJudicial != null ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="grid grid-cols-2">
                  <div className="text-primary text-xl font-semibold text-center">
                    Proceso Civil y Familiar
                  </div>
                  <div className="text-primary text-xl font-semibold text-center">
                    Rama Judicial
                  </div>
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Proceso
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Entidad
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Clase
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Ponentes
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Demandantes
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Demandados
                      </th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultJudicial.procesosJudiciales.civiles.map(
                      (proceso, i) => {
                        return (
                          <tr
                            key={i}
                            className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                          >
                            <td></td>
                            <td>{proceso.proceso}</td>
                            <td>{proceso.entidad}</td>
                            <td>{proceso.fecha}</td>
                            <td>{proceso.clase}</td>
                            <td>{proceso.ponente}</td>
                            <td>{proceso.demandantes}</td>
                            <td>{proceso.demandados}</td>
                            <td></td>
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            {data && data.resultRunt != null ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="text-primary text-xl font-semibold text-center">
                  RUNT
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Titulo
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Descripción
                      </th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultRunt.runt.alertas.map((alerta, i) => {
                      return (
                        <tr
                          key={i}
                          className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                        >
                          <td></td>
                          <td>{alerta.titulo}</td>
                          <td>{alerta.fecha}</td>
                          <td>{alerta.descripcion}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            {data && data.resultProcuraduria != null ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="text-primary text-xl font-semibold text-center">
                  Procuraduría
                </div>
                <div className="text-primary text-xl font-semibold text-left ml-6">
                  Inhabilidades
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Id
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Inhabilidad
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Modulo
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha de inicio
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha fin
                      </th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultProcuraduria.registrosProduraduria.inhabilidad.map(
                      (inhabilidad, i) => {
                        return (
                          <tr
                            key={i}
                            className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                          >
                            <td></td>
                            <td>{inhabilidad.siri}</td>
                            <td>{inhabilidad.inhabilidad}</td>
                            <td>{inhabilidad.modulo}</td>
                            <td>{inhabilidad.fechaInicio}</td>
                            <td>{inhabilidad.fechaFin}</td>
                            <td></td>
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-primary text-xl font-semibold text-left ml-6">
                  Sanciones
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Clase
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Sanción
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Término
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Suspendida
                      </th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultProcuraduria.registrosProduraduria.sanciones.map(
                      (sancion, i) => {
                        return (
                          <tr
                            key={i}
                            className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                          >
                            <td></td>
                            <td>{sancion.clase}</td>
                            <td>{sancion.sancion}</td>
                            <td>{sancion.termino}</td>
                            <td>{sancion.suspendida}</td>
                            <td></td>
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-primary text-xl font-semibold text-left ml-6">
                  Delitos
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Descripción delito
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultProcuraduria.registrosProduraduria.delitos.map(
                      (delito, i) => {
                        return (
                          <tr
                            key={i}
                            className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                          >
                            <td></td>
                            <td>{delito.descripcion}</td>
                            <td></td>
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-primary text-xl font-semibold text-left ml-6">
                  Instancias
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Nombre
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Autoridad
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha
                      </th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultProcuraduria.registrosProduraduria.instancias.map(
                      (instancia, i) => {
                        return (
                          <tr
                            key={i}
                            className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                          >
                            <td></td>
                            <td>{instancia.nombre}</td>
                            <td>{instancia.autoridad}</td>
                            <td>{instancia.fecha}</td>
                            <td></td>
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            {data && data.resultPolicia != null ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="text-primary text-xl font-semibold text-center">
                  Policia
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Descripción
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultPolicia.registrosPolicia.map((alerta, i) => {
                      return (
                        <tr
                          key={i}
                          className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                        >
                          <td></td>
                          <td>{alerta.mensaje}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            {data && data.resultInpec ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="text-primary text-xl font-semibold text-center">
                  Inpec
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Nombre
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Identificacion
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Estado
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Situación
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Establecimiento
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultInpec.inpec.map((alerta, i) => {
                      return (
                        <tr
                          key={i}
                          className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                        >
                          <td></td>
                          <td>{alerta.nombre}</td>
                          <td>{alerta.identificacion}</td>
                          <td>{alerta.estadoIngreso}</td>
                          <td>{alerta.situacionJuridica}</td>
                          <td>{alerta.establecimientoACargo}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </>
        );
      case "Propietario":
        return (
          <>
            {data && data.resultComparendo != null ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="grid grid-cols-2">
                  <div className="text-primary text-xl font-semibold text-center">
                    Comparendos e infracciones
                  </div>
                  <div className="text-primary text-xl font-semibold text-center">
                    SIMIT y Moviidad
                  </div>
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        No. Comparendo
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Secretaría
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha de notificación
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Nombre infractor
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Estado
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Infracción
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Valor Multa
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Valor adicional
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Interés mora
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Valor a pagar
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultComparendo.comparendos.map((comparendo, i) => {
                      return (
                        <tr
                          key={i}
                          className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                        >
                          <td></td>
                          <td>{comparendo.numeroComparendo}</td>
                          <td>{comparendo.secretaria}</td>
                          <td>{comparendo.fecha}</td>
                          <td>{comparendo.fechaNotificacion}</td>
                          <td>{comparendo.nombreInfractor}</td>
                          <td>{comparendo.estado}</td>
                          <td>{comparendo.infraccion}</td>
                          <td>{comparendo.valorMulta}</td>
                          <td>{comparendo.valorAdicional}</td>
                          <td>{comparendo.interesMora}</td>
                          <td>{comparendo.valorPagar}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            {data && data.resultJudicial != null ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="grid grid-cols-2">
                  <div className="text-primary text-xl font-semibold text-center">
                    Procesos Civil y Familiar
                  </div>
                  <div className="text-primary text-xl font-semibold text-center">
                    Rama Judicial
                  </div>
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Proceso
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Entidad
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Clase
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Ponentes
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Demandantes
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Demandados
                      </th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultJudicial.procesosJudiciales.civiles.map(
                      (proceso, i) => {
                        return (
                          <tr
                            key={i}
                            className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                          >
                            <td></td>
                            <td>{proceso.proceso}</td>
                            <td>{proceso.entidad}</td>
                            <td>{proceso.fecha}</td>
                            <td>{proceso.clase}</td>
                            <td>{proceso.ponente}</td>
                            <td>{proceso.demandantes}</td>
                            <td>{proceso.demandados}</td>
                            <td></td>
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            {data && data.resultRunt != null ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="text-primary text-xl font-semibold text-center">
                  RUNT
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Titulo
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Descripción
                      </th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultRunt.runt.alertas.map((alerta, i) => {
                      return (
                        <tr
                          key={i}
                          className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                        >
                          <td></td>
                          <td>{alerta.titulo}</td>
                          <td>{alerta.fecha}</td>
                          <td>{alerta.descripcion}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            {data && data.resultProcuraduria != null ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="text-primary text-xl font-semibold text-center">
                  Procuraduría
                </div>
                <div className="text-primary text-xl font-semibold text-left ml-6">
                  Inhabilidades
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Id
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Inhabilidad
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Modulo
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha de inicio
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha fin
                      </th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultProcuraduria.registrosProduraduria.inhabilidad.map(
                      (inhabilidad, i) => {
                        return (
                          <tr
                            key={i}
                            className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                          >
                            <td></td>
                            <td>{inhabilidad.siri}</td>
                            <td>{inhabilidad.inhabilidad}</td>
                            <td>{inhabilidad.modulo}</td>
                            <td>{inhabilidad.fechaInicio}</td>
                            <td>{inhabilidad.fechaFin}</td>
                            <td></td>
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-primary text-xl font-semibold text-left ml-6">
                  Sanciones
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Clase
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Sanción
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Término
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Suspendida
                      </th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultProcuraduria.registrosProduraduria.sanciones.map(
                      (sancion, i) => {
                        return (
                          <tr
                            key={i}
                            className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                          >
                            <td></td>
                            <td>{sancion.clase}</td>
                            <td>{sancion.sancion}</td>
                            <td>{sancion.termino}</td>
                            <td>{sancion.suspendida}</td>
                            <td></td>
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-primary text-xl font-semibold text-left ml-6">
                  Delitos
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Descripción delito
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultProcuraduria.registrosProduraduria.delitos.map(
                      (delito, i) => {
                        return (
                          <tr
                            key={i}
                            className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                          >
                            <td></td>
                            <td>{delito.descripcion}</td>
                            <td></td>
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-primary text-xl font-semibold text-left ml-6">
                  Instancias
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Nombre
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Autoridad
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha
                      </th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultProcuraduria.registrosProduraduria.instancias.map(
                      (instancia, i) => {
                        return (
                          <tr
                            key={i}
                            className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                          >
                            <td></td>
                            <td>{instancia.nombre}</td>
                            <td>{instancia.autoridad}</td>
                            <td>{instancia.fecha}</td>
                            <td></td>
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            {data && data.resultPolicia != null ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="text-primary text-xl font-semibold text-center">
                  Policia
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Descripción
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultPolicia.registrosPolicia.map((alerta, i) => {
                      return (
                        <tr
                          key={i}
                          className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                        >
                          <td></td>
                          <td>{alerta.mensaje}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            {data && data.resultInpec ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="text-primary text-xl font-semibold text-center">
                  Inpec
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Nombre
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Identificacion
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Estado
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Situación
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Establecimiento
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultInpec.inpec.map((alerta, i) => {
                      return (
                        <tr
                          key={i}
                          className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                        >
                          <td></td>
                          <td>{alerta.nombre}</td>
                          <td>{alerta.identificacion}</td>
                          <td>{alerta.estadoIngreso}</td>
                          <td>{alerta.situacionJuridica}</td>
                          <td>{alerta.establecimientoACargo}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </>
        );
      case "Vehiculo":
        return (
          <>
            {data &&
            data.resultVehicleRunt &&
            data.resultVehicleRunt.runt.alertas.length > 0 ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="text-primary text-xl font-semibold text-center">
                  Alertas
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Titulo
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Descripción
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Tipo
                      </th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultVehicleRunt.runt.alertas.map((alerta, i) => {
                      return (
                        <tr
                          key={i}
                          className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                        >
                          <td></td>
                          <td>{alerta.titulo}</td>
                          <td>{alerta.descripcion}</td>
                          <td>{alerta.fecha}</td>
                          <td>{alerta.tipo}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            {data &&
            data.resultVehicleRunt &&
            data.resultVehicleRunt.runt.limitacionesPropiedad.length > 0 ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="text-primary text-xl font-semibold text-center">
                  Limitaciones a la propiedad
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Tipo de limitación
                      </th>
                      {/* <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Descripción
                      </th> */}
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        No. Documento
                      </th>
                      {/* <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        No. Documento Demandante
                      </th> */}
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha de expedición
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha de radicación
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Entidad jurídica
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {data.resultVehicleRunt.runt.limitacionesPropiedad.map(
                      (limitacion, i) => {
                        return (
                          <tr
                            key={i}
                            className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                          >
                            <td></td>
                            <td>{limitacion.tipoLimitacion}</td>
                            {/* <td>{limitacion.descripcion}</td> */}
                            <td>{limitacion.noDocumento}</td>
                            {/* <td>{limitacion.noDocumentoDemandante}</td> */}
                            <td>{limitacion.fechaExpedicion}</td>
                            <td>{limitacion.fechaRadicacion}</td>
                            <td>{limitacion.entidadJuridica}</td>
                            <td></td>
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}

            {data &&
            data.resultVehicleRunt.runt &&
            data.resultVehicleRunt.runt.rtm ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="text-primary text-xl font-semibold text-center">
                  Revisión tecnomecánica
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        No. RTM
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Es Vigente
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha de expedición
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha de vigencia
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        CDA de RTM
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md">
                      <td></td>
                      <td>{data.resultVehicleRunt.runt.rtm.numeroRTM}</td>
                      <td>{data.resultVehicleRunt.runt.rtm.vigente}</td>
                      <td>{data.resultVehicleRunt.runt.rtm.fechaExpedicion}</td>
                      <td>{data.resultVehicleRunt.runt.rtm.fechaVigente}</td>
                      <td>{data.resultVehicleRunt.runt.rtm.cdartm}</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            {data &&
            data.resultVehicleRunt.runt &&
            data.resultVehicleRunt.runt.soat ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="grid grid-cols-2">
                  <div className="text-primary text-xl font-semibold text-center">
                    SOAT
                  </div>
                  <div className="text-primary text-xl font-semibold text-center">
                    RUNT
                  </div>
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Estado
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha de expedición
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha de vigencia
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        NIT de entidad queexpide el SOAT
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Entidad que expide SOAT
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md">
                      <td></td>
                      <td>{data.resultVehicleRunt.runt.soat.estado}</td>
                      <td>
                        {data.resultVehicleRunt.runt.soat.fechaExpedicion}
                      </td>
                      <td>{data.resultVehicleRunt.runt.soat.fechaVigencia}</td>
                      <td></td>
                      <td>{data.resultVehicleRunt.runt.soat.entidadSoat}</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            {data &&
            data.resultVehicleRunt.runt &&
            data.resultVehicleRunt.runt.matricula ? (
              <div className="flex flex-col space-y-5 w-full px-2">
                <div className="text-primary text-xl font-semibold text-center">
                  Información matrícula
                </div>
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Fecha de matrícula
                      </th>
                      <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                        Tiene Deficiencias en la matricula
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md">
                      <td></td>
                      <td>
                        {data.resultVehicleRunt.runt.matricula.fechaMatricula}
                      </td>
                      <td>
                        {
                          data.resultVehicleRunt.runt.matricula
                            .tieneDeficiencias
                        }
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </>
        );
      default:
        return <div></div>;
    }
  };

  return (
    <LoadingOverlay
      active={loadingStatusUpdateData === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      <Modal
        isOpen={loadingStatusUpdateData === "resolved"}
        onRequestClose={(e) => {
          e.preventDefault();
          dispatch(resetUpdateData());
          closeModal();
        }}
        style={customStylesModal}
        contentLabel=""
      >
        <div className="flex flex-col items-center justify-center">
          <div className="text-center text-sm text-black mb-3">
            Datos actualizados exitosamente
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={
          loadingStatusUpdateData === "rejected" ||
          updateDataResponse?.hasAlert === true
        }
        onRequestClose={(e) => {
          e.preventDefault();
          dispatch(resetUpdateData());
        }}
        style={customStylesModal}
        contentLabel=""
      >
        <div className="flex flex-col items-center justify-center">
          <div className="text-center text-sm text-black mb-3">
            Error actualizando la información
          </div>
        </div>
      </Modal>

      <div className="flex flex-col w-full h-full items-center justify-evenly">
        <div className="flex flex-col items-center justify-center h-1/6">
          <div className="text-4xl text-primary text-center font-extrabold ">
            Actualización de datos {currentSelect?.dataEvaluado.tipoEvaluado}
          </div>
        </div>

        {currentSelect &&
          (currentSelect?.dataEvaluado.tipoEvaluado === "Propietario" ||
            currentSelect?.dataEvaluado.tipoEvaluado === "Conductor") && (
            <div className="flex flex-col items-center justify-center w-full h-1/6 space-y-6">
              <div className="flex flex-row w-full justify-between">
                <div className="flex flex-row w-1/2 justify-center space-x-7">
                  <div className="text-lg text-primary-35 font-bold">
                    {currentSelect.dataEvaluado.tipoIdentificacion === "CC" ||
                    currentSelect.dataEvaluado.tipoIdentificacion === "CE"
                      ? "Nombres y apellidos"
                      : "Razon Social"}
                  </div>
                  <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 w-2/5 text-center text-primary text-sm font-semibold">
                    {currentSelect.dataEvaluado.tipoIdentificacion === "CC" ||
                    currentSelect.dataEvaluado.tipoIdentificacion === "CE"
                      ? `${currentSelect.dataEvaluado?.nombres} ${currentSelect.dataEvaluado?.apellidos}`
                      : currentSelect.dataEvaluado.razonSocial}
                  </div>
                </div>
                <div className="flex flex-row w-1/2 justify-center space-x-7">
                  <div className="text-lg text-primary-35 font-bold">
                    No. de documento
                  </div>
                  <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 w-2/5 text-center text-primary text-sm font-semibold">
                    {currentSelect.dataEvaluado?.identificacion}
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full justify-between">
                <div className="flex flex-row w-1/2 justify-center space-x-7">
                  <div className="text-lg text-primary-35 font-bold">
                    Tipo de documento
                  </div>
                  <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-4 py-2 w-2/5 text-center text-primary text-sm font-semibold">
                    {getTipoDocumentoLabel(
                      currentSelect.dataEvaluado?.tipoIdentificacion!
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

        <div className="flex flex-col h-3/6 overflow-auto w-full items-center mt-3">
          <div>
            {currentSelect?.fuentesConAlertas.map((solicitud, i) => {
              return getDetailAlertsByEvaluatedType(
                tipoEvaluadoValue,
                solicitud
              );
            })}
          </div>
        </div>
        <div className="flex flex-col h-1/6 items-center justify-center">
          <div
            onClick={async (e) => {
              e.preventDefault();
              //Actualizar el sourceEvaluated.
              if (
                currentSelect &&
                currentSelect?.fuentesConAlertas.length > 0
              ) {
                for (
                  let i = 0;
                  i < currentSelect?.fuentesConAlertas.length;
                  i++
                ) {}
              }

              //Consumir servicio
              dispatch(
                updateConsultedNoAlert({
                  identifier:
                    currentSelect?.idEvaluado == null
                      ? ""
                      : currentSelect?.idEvaluado,
                })
              );
              if (loadingStatusUpdateData === "resolved") {
                dispatch(resetUpdateData());
                navigate("/services/updateData");
              }
            }}
            className="flex flex-col items-center justify-center h-10 w-60 text-primary bg-primary-5 rounded-md text-center font-bold cursor-pointer"
          >
            Actualizar datos
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};
