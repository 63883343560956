import localforage from "localforage";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
  resetState,
  selectTokenExpiryTimestamp,
  setTokenInfo,
} from "./features/common/commonSlice";
import { Login } from "./features/login/Login";
import { Intro } from "./features/services/Intro";
import { AutomaticAlerts } from "./features/services/securityEvaluation/sections/automatic/AutomaticAlerts";
import { GettingAutomatic } from "./features/services/securityEvaluation/sections/automatic/GettingAutomatic";
import { ChooseStudySubject } from "./features/services/securityEvaluation/sections/ChooseStudySubject";
import SecurityEvaluation from "./features/services/securityEvaluation/SecurityEvaluation";
import DetailAlertUpdateData from "./features/services/updateData/sections/DetailtAlertUpdateData";
import { DetailAlertUpdateDataSource } from "./features/services/updateData/sections/DetailtAlertUpdateDataSource";
import UpdateData from "./features/services/updateData/UpdateData";
import axiosApiInstance from "./helpers/interceptor";
import { RequestCreationProVe } from "./features/services/createRequest/typesRequest/RequestProVe";
import { SelectType } from "./features/services/createRequest/typesRequest/SelectType";
import { RequestCreationFuncionario } from "./features/services/createRequest/typesRequest/RequestFuncionario";
import { RequestCreationProveedor } from "./features/services/createRequest/typesRequest/RequestProveedor";
import { ConsultIntro } from "./features/services/securityEvaluation/sections/consult/consultIntro";
import { ConsultResult } from "./features/services/securityEvaluation/sections/consult/ConsultResult";

function App() {
  let dispatch = useAppDispatch();
  let expiryTimeStamp = useAppSelector(selectTokenExpiryTimestamp);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, _, removeCookie] = useCookies(["token"]);
  let checkCookie = async () => {
    if (cookies.token && expiryTimeStamp !== -1) {
      if (expiryTimeStamp < new Date().getTime()) {
        dispatch(resetState());
        delete axiosApiInstance.defaults.headers.common["Authorization"];
        await localforage.clear();
        removeCookie("token", {
          path: "/",
          sameSite: "lax",
          secure: true,
        });
      }
    } else if (cookies.token && expiryTimeStamp === -1) {
      dispatch(setTokenInfo(cookies.token));
    } else if (
      (!cookies || !cookies.token || cookies.token === "") &&
      expiryTimeStamp !== -1
    ) {
      dispatch(resetState());
      delete axiosApiInstance.defaults.headers.common["Authorization"];
      await localforage.clear();
      if (cookies.token === "") {
        removeCookie("token", {
          path: "/",
          sameSite: "lax",
          secure: true,
        });
      }
    }
  };

  useEffect(() => {
    const intervalCookies = setInterval(() => {
      checkCookie();
    }, 3000);
    return () => clearInterval(intervalCookies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, expiryTimeStamp]);

  return (
    <div
      className="font-primary"
      style={{
        background:
          "url(https://cdn.anteia.co/back-office/transportes/background2.png)",
        height: "100vh",
        width: "100vw",
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        backgroundSize: "cover",
      }}
    >
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              cookies.token && cookies.token !== "" ? (
                <Navigate to="/services/intro" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/login"
            element={
              !cookies.token || cookies.token === "" ? (
                <Login />
              ) : (
                <Navigate to="/services/intro" />
              )
            }
          />
          <Route
            path="/services"
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to="/login" />
              ) : (
                <div className="h-screen w-screen">
                  <nav className="flex flex-col justify-end">
                    <div className="flex flex-row justify-end"></div>
                    <div className="flex flex-row justify-end">
                      <button
                        onClick={async (e) => {
                          e.preventDefault();
                          dispatch(resetState());
                          removeCookie("token", {
                            path: "/",
                            sameSite: "lax",
                            secure: true,
                          });
                          delete axiosApiInstance.defaults.headers.common[
                            "Authorization"
                          ];
                          await localforage.clear();
                        }}
                        className="mx-6 my-2 p-2 text-center box-border py-1 text-primary-20 bg-primary-10 rounded-md font-bold hover:text-primary"
                      >
                        Cerrar sesión
                      </button>
                    </div>
                  </nav>
                  <Outlet />
                </div>
              )
            }
          >
            <Route
              path="intro"
              element={
                !cookies.token || cookies.token === "" ? (
                  <Navigate to="/login" />
                ) : (
                  <Intro />
                )
              }
            />
            <Route
              path="updateData"
              element={
                !cookies.token || cookies.token === "" ? (
                  <Navigate to="/login" />
                ) : (
                  <UpdateData />
                )
              }
            />
            <Route
              path="updateData/detail"
              element={
                !cookies.token || cookies.token === "" ? (
                  <Navigate to="/login" />
                ) : (
                  <DetailAlertUpdateData />
                )
              }
            />
            <Route
              path="updateData/detail/sources"
              element={
                !cookies.token || cookies.token === "" ? (
                  <Navigate to="/login" />
                ) : (
                  <DetailAlertUpdateDataSource />
                )
              }
            />
            <Route
              path="securityEvaluation"
              element={
                !cookies.token || cookies.token === "" ? (
                  <Navigate to="/login" />
                ) : (
                  <SecurityEvaluation />
                )
              }
            />
            <Route
              path="securityEvaluation/choose/:id"
              element={
                !cookies.token || cookies.token === "" ? (
                  <Navigate to="/login" />
                ) : (
                  <ChooseStudySubject />
                )
              }
            />
            <Route
              path="securityEvaluation/automatic/:id"
              element={
                !cookies.token || cookies.token === "" ? (
                  <Navigate to="/login" />
                ) : (
                  <GettingAutomatic />
                )
              }
            />

            <Route
              path="securityEvaluation/automatic/alerts/:id"
              element={
                !cookies.token || cookies.token === "" ? (
                  <Navigate to="/login" />
                ) : (
                  <AutomaticAlerts />
                )
              }
            />

            <Route
              path="securityEvaluation/consult/:id"
              element={
                !cookies.token || cookies.token === "" ? (
                  <Navigate to="/login" />
                ) : (
                  <ConsultIntro />
                )
              }
            />

            <Route
              path="securityEvaluation/consult/result/:id"
              element={
                !cookies.token || cookies.token === "" ? (
                  <Navigate to="/login" />
                ) : (
                  <ConsultResult />
                )
              }
            />

            <Route
              path="requestCreation/selectTypes"
              element={
                !cookies.token || cookies.token === "" ? (
                  <Navigate to="/login" />
                ) : (
                  <SelectType />
                )
              }
            />
            <Route
              path="requestProVe"
              element={
                !cookies.token || cookies.token === "" ? (
                  <Navigate to="/login" />
                ) : (
                  <RequestCreationProVe />
                )
              }
            />

            <Route
              path="requestFuncionario"
              element={
                !cookies.token || cookies.token === "" ? (
                  <Navigate to="/login" />
                ) : (
                  <RequestCreationFuncionario />
                )
              }
            />

            <Route
              path="requestProveedor"
              element={
                !cookies.token || cookies.token === "" ? (
                  <Navigate to="/login" />
                ) : (
                  <RequestCreationProveedor />
                )
              }
            />
          </Route>

          <Route
            path="/*"
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to="/login" />
              ) : (
                <Navigate to="/services/intro" />
              )
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
