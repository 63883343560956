import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../login/loginSlice";

export interface RamaJudicialProps {
  loading: LoadingStatus;
  item: RamaJudicial | null;
}

export interface RamaJudicial {
  lista: string;
  tipo: string;
  bd: boolean;
  Resultado: Array<any>;
  coincidencia: string;
  tipoDocumentoConsulta: [
    {
      tipoDocumento: string;
      nombreDocumento: string;
    }
  ];
  duracion: string;
  presentaRiesgo: true;
  presentaAdvertencia: true;
  result: Array<RamaJudicialItem>;
  tipoDocumentoNoValido?: string;
}

export interface RamaJudicialItem {
  ciudad: string;
  condenas: Array<RamaJudicialCondena>;
}

export interface RamaJudicialCondena {
  numeroRadicacion: string;
  representante: string;
  presentaRiesgoCiudad: string;
  nombreSujeto: string;
  juzgado: string;
  identificacion: string;
  url: string;
  clase: string;
  delito: string;
}

export const RamaJudicialResult = (props: RamaJudicialProps) => {
  const getColor = (presentaRiesgo: boolean, presentaAdvertencia: boolean) => {
    if (presentaRiesgo) {
      return "text-red-400";
    } else if (presentaAdvertencia) {
      return "text-yellow-400";
    } else {
      return "text-green-400";
    }
  };

  return (
    <LoadingOverlay
      styles={{
        content: {},
        overlay: {},
        spinner: {},
        wrapper: { height: "fit-content", overflow: "hidden" },
      }}
      active={props.loading === "pending"}
      className="overflow-auto mb-10"
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {" "}
      {props.item?.tipoDocumentoNoValido ? (
        <>
          <div className="flex flex-col justify-evenly w-full px-2">
            <div className={`text-white text-3xl font-semibold text-center`}>
              Rama Judicial
            </div>
            <div className="text-primary text-lg font-semibold text-center">
              {props.item.tipoDocumentoNoValido}
            </div>
          </div>
        </>
      ) : (
        <>
          {props.item ? (
            <div className="flex flex-col justify-evenly w-full px-2">
              <div
                className={`${getColor(
                  props.item.presentaRiesgo,
                  props.item.presentaAdvertencia
                )} text-3xl font-semibold text-center`}
              >
                Rama Judicial
              </div>
              <div className="text-primary text-lg font-semibold text-center">
                {props.item.presentaRiesgo
                  ? "Presenta riesgo"
                  : "No presenta riesgo"}
              </div>

              <div className="text-primary text-xl font-semibold text-center">
                Condenas Por ciudad
              </div>
              {props.item && props.item.result ? (
                props.item.result.map((condenaCiudad, i) => {
                  if (
                    condenaCiudad.condenas &&
                    condenaCiudad.condenas.length > 0
                  ) {
                    return (
                      <div className="flex flex-col" key={i}>
                        <div className="text-primary text-lg font-semibold text-center">
                          Ciudad: {condenaCiudad.ciudad}
                        </div>
                        <table className="relative w-full">
                          <thead>
                            <tr>
                              <th></th>
                              <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                                No. Radicación
                              </th>
                              <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                                Representante
                              </th>
                              <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                                Presenta Riesgo
                              </th>
                              <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                                Nombre
                              </th>
                              <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                                Juzgado
                              </th>
                              <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                                Identificacion
                              </th>
                              <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                                Clase
                              </th>
                              <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                                Delito
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>&nbsp;</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            {condenaCiudad.condenas.map((condena, j) => {
                              return (
                                <tr
                                  key={j}
                                  className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                                >
                                  <td></td>
                                  <td>{condena.numeroRadicacion}</td>
                                  <td>{condena.representante}</td>
                                  <td>
                                    {condena.presentaRiesgoCiudad ? "Sí" : "No"}
                                  </td>
                                  <td>{condena.nombreSujeto}</td>
                                  <td>{condena.juzgado}</td>
                                  <td>{condena.identificacion}</td>
                                  <td>{condena.clase}</td>
                                  <td>{condena.delito}</td>
                                  <td></td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td>&nbsp;</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  } else {
                    return <div key={i}></div>;
                  }
                })
              ) : (
                <h6 className="text-white text-center">
                  "No se encontraron registros en condenas por ciudad"
                </h6>
              )}
            </div>
          ) : (
            <div>
              {props.loading === "rejected" || props.loading === "resolved" ? (
                <div className="text-primary text-xl font-semibold text-center">
                  No se encontraron registros, intente de nuevo más tarde
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </>
      )}
    </LoadingOverlay>
  );
};
