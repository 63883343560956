import React from "react";

export interface InputPrimaryProps {
  value: string;
  inputType: "email" | "password" | "text";
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  placeHolder: string;
}
export const InputPrimary = (props: InputPrimaryProps) => {
  return (
    <input
      className={`w-64 text-center py-2 px-4 items-center border-0.5 border-primary-20 bg-primary-10 box-border rounded-md text-primary text-sm placeholder:text-sm placeholder:text-primary-35 ${props.className}`}
      placeholder={props.placeHolder}
      type={props.inputType}
      value={props.value}
      onChange={props.onChange}
    />
  );
};
