import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as LeftArrow } from "../../../../../Assets/images/leftArrow.svg";
import download from "../../../../../Assets/images/download.png";

import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import {
  deleteCurrentDownloadedFile,
  downloadFile,
  generatePDFFromId,
  getRequestConsultation,
  resetDetailRequest,
  resetReactivate,
  selectConsultedRequest,
  selectConsultRequestLoading,
  selectCurrentDownloadedFile,
  selectLoadingDownloadFile,
  selectLoadingReactivate,
  setElementSelected,
} from "../../securityEvaluationSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { formatDateStandar } from "../../../../../helpers/utils";
import { selectUserInfo } from "../../../../common/commonSlice";
import fileDownload from "js-file-download";

export const ConsultIntro = () => {
  let currentConsultedRequest = useAppSelector(selectConsultedRequest);
  let consultedRequestLoading = useAppSelector(selectConsultRequestLoading);
  let userInfo = useAppSelector(selectUserInfo);
  let currentFile = useAppSelector(selectCurrentDownloadedFile);
  let loadingReactivate = useAppSelector(selectLoadingReactivate);
  let loadingPDFGeneration = useAppSelector(selectLoadingDownloadFile);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let params = useParams<{ id: string }>();

  const hasComments = () => {
    if (currentConsultedRequest) {
      // concatenate all comments and check its length
      let allcomments = currentConsultedRequest.request.comments
        .map((comment) => comment.content)
        .join("");

      if (
        currentConsultedRequest.request.comments &&
        currentConsultedRequest.request.comments.length > 0 &&
        allcomments.length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (loadingReactivate === "resolved") {
      dispatch(resetReactivate());
      navigate("/services/securityEvaluation");
    }

    if (currentFile && currentFile.dataBase64 !== "") {
      let url = `data:application/pdf;base64,${currentFile.dataBase64}`;
      let fileName = currentFile.fileName.split("/").pop();
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          fileDownload(blob, fileName!);
        });
      dispatch(deleteCurrentDownloadedFile());
    }
    if (
      !currentConsultedRequest ||
      (currentConsultedRequest &&
        currentConsultedRequest.request.id !== params.id)
    ) {
      dispatch(getRequestConsultation(params.id!));
    }
  }, [
    currentConsultedRequest,
    params,
    dispatch,
    consultedRequestLoading,
    loadingReactivate,
    navigate,
    currentFile,
  ]);

  return (
    <>
      {consultedRequestLoading === "pending" ? (
        <LoadingOverlay
          active={consultedRequestLoading === "pending"}
          spinner={<ClipLoader color="white" size="150px" />}
        ></LoadingOverlay>
      ) : (
        <div className="flex flex-col items-center  w-full h-5/6">
          {currentConsultedRequest ? (
            <div className="flex flex-col items-center  w-full h-full">
              <div className="flex flex-row h-1/5 w-full items-center justify-evenly">
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(resetDetailRequest());
                    navigate("/services/securityEvaluation");
                  }}
                  className="bg-primary-5 flex flex-row items-center justify-start h-11 w-64 rounded-md box-border px-5 cursor-pointer"
                >
                  <LeftArrow className="mr-16" />
                  <div className="text-primary text-lg font-bold">Regresar</div>
                </div>
                <div className="text-5xl text-primary font-extrabold text-center">
                  Estudio de Seguridad
                </div>
              </div>
              <div className="flex flex-col h-3/5 sm:w-4/5 xl:w-3/5 items-center justify-evenly space-y-8">
                <div className="flex flex-row justify-evenly w-full">
                  <div className="flex flex-col">
                    <div className="text-primary text-xl font-bold text-center">
                      Estado
                    </div>
                    <div className="text-primary text-lg font-bold">
                      {currentConsultedRequest.request.status}
                    </div>
                  </div>
                  {currentConsultedRequest.request.observations.length > 0 && (
                    <div className="flex flex-col">
                      <div className="text-primary text-xl font-bold text-center">
                        Observaciones de creación
                      </div>
                      <div className="text-primary text-lg font-bold">
                        {currentConsultedRequest.request.observations}
                      </div>
                    </div>
                  )}
                  {currentConsultedRequest.request.fileNames &&
                    currentConsultedRequest.request.fileNames.length > 0 && (
                      <div className="flex flex-col">
                        <div className="text-primary text-xl font-bold text-center">
                          Archivos
                        </div>
                        <div className="text-primary text-lg font-bold overflow-auto">
                          <table className="relative w-full">
                            <thead>
                              <tr>
                                <th></th>
                                <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                                  Nombre
                                </th>
                                <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                                  Descargar
                                </th>

                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>&nbsp;</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              {currentConsultedRequest.request.fileNames.map(
                                (file, i) => {
                                  return (
                                    <tr
                                      key={i}
                                      className="text-center text-primary text-sm font-semibold  h-7"
                                    >
                                      <td></td>
                                      <td className="px-2">{file.fileName}</td>
                                      <td
                                        className="px-4 cursor-pointer"
                                        onClick={() => {
                                          dispatch(downloadFile(file.fileId));
                                        }}
                                      >
                                        <img
                                          src={download}
                                          alt="Download"
                                          className="h-6 w-6 "
                                        />
                                      </td>
                                      <td></td>
                                    </tr>
                                  );
                                }
                              )}
                              <tr>
                                <td>&nbsp;</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  {hasComments() && (
                    <div className="flex flex-col">
                      <div className="text-primary text-xl font-bold text-center">
                        Comentarios
                      </div>
                      <div className="text-primary text-lg font-bold overflow-auto">
                        <table className="relative w-full">
                          <thead>
                            <tr>
                              <th></th>
                              <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                                Comentario
                              </th>
                              <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                                Fecha
                              </th>

                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>&nbsp;</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            {currentConsultedRequest.request.comments.map(
                              (comment, i) => {
                                if (comment.content.length > 0) {
                                  return (
                                    <tr
                                      key={i}
                                      className="text-center text-primary text-sm font-semibold  h-7"
                                    >
                                      <td></td>
                                      <td>{comment.content}</td>
                                      <td>{formatDateStandar(comment.date)}</td>
                                      <td></td>
                                    </tr>
                                  );
                                } else {
                                  return <tr></tr>;
                                }
                              }
                            )}
                            <tr>
                              <td>&nbsp;</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className="text-primary text-lg font-bold text-center">
                  El estudio de seguridad consiste de evaluar los siguientes
                  sujetos
                </div> */}
                {/* {userInfo?.roles.includes("agent") && (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(reactivateRequest(params.id!));
                    }}
                    className="flex flex-col items-center justify-center h-10 w-60 text-primary bg-primary-5 rounded-md text-center font-bold cursor-pointer"
                  >
                    {loadingReactivate === "pending" ? (
                      <ClipLoader color="white" size="20px" />
                    ) : (
                      " Reabrir solicitud"
                    )}
                  </div>
                )} */}
                {(userInfo?.roles.includes("security") ||
                  userInfo?.roles.includes("agent")) && (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(generatePDFFromId(params.id!));
                    }}
                    className="flex flex-col items-center justify-center h-10 w-60 text-primary bg-primary-5 rounded-md text-center font-bold cursor-pointer"
                  >
                    {loadingPDFGeneration === "pending" ? (
                      <ClipLoader color="white" size="20px" />
                    ) : (
                      "Descargar Reporte"
                    )}
                  </div>
                )}
                <div className="flex flex-row justify-evenly w-full">
                  {currentConsultedRequest?.request?.driverIdentification ? (
                    <div className="flex flex-col">
                      <div className="text-primary text-lg font-bold">
                        Conductor
                      </div>
                      <div className="text-primary text-lg font-bold">
                        Cedula:{" "}
                        {currentConsultedRequest.request?.driverIdentification}
                      </div>
                      {(userInfo?.roles.includes("security") ||
                        userInfo?.roles.includes("agent")) && (
                        <button
                          onClick={(e) => {
                            dispatch(setElementSelected("Conductor"));
                            let subject =
                              currentConsultedRequest?.subjects.find(
                                (x) =>
                                  x.identification ===
                                  currentConsultedRequest?.request
                                    .driverIdentification
                              );
                            navigate(
                              `/services/securityEvaluation/consult/result/${subject?.currentDataIdentifier}`
                            );
                          }}
                          className="self-center rounded-md w-40 mt-2 h-7 text-center text-primary text-base font-semibold bg-primary-20 "
                        >
                          Ver resultado
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {currentConsultedRequest?.request?.ownerIdentification ? (
                    <div className="flex flex-col ">
                      <div className="text-primary text-lg font-bold">
                        Propietario
                      </div>
                      <div className="text-primary text-lg font-bold">
                        Cedula:{" "}
                        {currentConsultedRequest.request?.ownerIdentification}
                      </div>
                      {(userInfo?.roles.includes("security") ||
                        userInfo?.roles.includes("agent")) && (
                        <button
                          onClick={(e) => {
                            dispatch(setElementSelected("Propietario"));
                            let subject =
                              currentConsultedRequest?.subjects.find(
                                (x) =>
                                  x.identification ===
                                  currentConsultedRequest?.request
                                    .ownerIdentification
                              );
                            navigate(
                              `/services/securityEvaluation/consult/result/${subject?.currentDataIdentifier}`
                            );
                          }}
                          className="self-center rounded-md w-40 mt-2 h-7 text-center text-primary text-base font-semibold bg-primary-20 "
                        >
                          Ver resultado
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {currentConsultedRequest?.request?.placaVehicle ? (
                    <div className="flex flex-col">
                      <div className="text-primary text-lg font-bold">
                        Vehículo
                      </div>
                      <div className="text-primary text-lg font-bold">
                        Placa: {currentConsultedRequest.request?.placaVehicle}
                      </div>
                      {(userInfo?.roles.includes("security") ||
                        userInfo?.roles.includes("agent")) && (
                        <button
                          onClick={(e) => {
                            dispatch(setElementSelected("Vehiculo"));
                            let subject =
                              currentConsultedRequest?.subjects.find(
                                (x) =>
                                  x.identification ===
                                  currentConsultedRequest?.request.placaVehicle
                              );
                            navigate(
                              `/services/securityEvaluation/consult/result/${subject?.currentDataIdentifier}`
                            );
                          }}
                          className="self-center rounded-md w-40 mt-2 h-7 text-center text-primary text-base font-semibold bg-primary-20 "
                        >
                          Ver resultado
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {currentConsultedRequest?.request?.placaTrailer ? (
                    <div className="flex flex-col">
                      <div className="text-primary text-lg font-bold">
                        Remolque
                      </div>
                      <div className="text-primary text-lg font-bold">
                        Placa: {currentConsultedRequest.request?.placaTrailer}
                      </div>
                      {(userInfo?.roles.includes("security") ||
                        userInfo?.roles.includes("agent")) && (
                        <button
                          onClick={(e) => {
                            dispatch(setElementSelected("Remolque"));
                            let subject =
                              currentConsultedRequest?.subjects.find(
                                (x) =>
                                  x.identification ===
                                  currentConsultedRequest?.request.placaTrailer
                              );
                            navigate(
                              `/services/securityEvaluation/consult/result/${subject?.currentDataIdentifier}`
                            );
                          }}
                          className="self-center rounded-md w-40 mt-2 h-7 text-center text-primary text-base font-semibold bg-primary-20 "
                        >
                          Ver resultado
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {currentConsultedRequest?.request?.officialIdentification ? (
                    <div className="flex flex-col">
                      <div className="text-primary text-lg font-bold">
                        Funcionario
                      </div>
                      <div className="text-primary text-lg font-bold">
                        Cedula:{" "}
                        {
                          currentConsultedRequest.request
                            ?.officialIdentification
                        }
                      </div>
                      <div className="text-primary text-lg font-bold">
                        Cargo:{" "}
                        {currentConsultedRequest.request?.officialPosition}
                      </div>
                      {(userInfo?.roles.includes("security") ||
                        userInfo?.roles.includes("agent")) && (
                        <button
                          onClick={(e) => {
                            dispatch(setElementSelected("Funcionario"));
                            let subject =
                              currentConsultedRequest?.subjects.find(
                                (x) =>
                                  x.identification ===
                                  currentConsultedRequest?.request
                                    .officialIdentification
                              );
                            navigate(
                              `/services/securityEvaluation/consult/result/${subject?.currentDataIdentifier}`
                            );
                          }}
                          className="self-center rounded-md w-40 mt-2 h-7 text-center text-primary text-base font-semibold bg-primary-20 "
                        >
                          Ver resultado
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {currentConsultedRequest?.request?.providerNit ? (
                    <div className="flex flex-col">
                      <div className="text-primary text-lg font-bold">
                        Proveedor o cliente
                      </div>
                      <div className="text-primary text-lg font-bold">
                        Nit: {currentConsultedRequest.request?.providerNit}
                      </div>
                      {(userInfo?.roles.includes("security") ||
                        userInfo?.roles.includes("agent")) && (
                        <button
                          onClick={(e) => {
                            dispatch(setElementSelected("Proveedor"));
                            let subject =
                              currentConsultedRequest?.subjects.find(
                                (x) =>
                                  x.identification ===
                                  currentConsultedRequest?.request.providerNit
                              );
                            navigate(
                              `/services/securityEvaluation/consult/result/${subject?.currentDataIdentifier}`
                            );
                          }}
                          className="self-center rounded-md w-40 mt-2 h-7 text-center text-primary text-base font-semibold bg-primary-20 "
                        >
                          Ver resultado
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {currentConsultedRequest?.request?.observations ? (
                  <div className="text-primary text-base font-bold text-center">
                    El solicitante dejó las siguientes observaciones:
                  </div>
                ) : (
                  ""
                )}
                {currentConsultedRequest?.request?.observations ? (
                  <div className="text-primary text-base font-bold text-center backdrop-brightness-50 px-2 py-2 rounded-lg">
                    {currentConsultedRequest.request.observations}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};
