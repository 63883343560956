import axiosApiInstance from "../../../helpers/interceptor";
import fileDownload from "js-file-download";
import { RNDCItem } from "./EvalComponents/RNDCResult";
import { FosygaData } from "./EvalComponents/FosygaResult";
import axios from "axios";
import { LicenciaSupendida } from "./EvalComponents/LicenciaSuspendida";
import { Lista } from "./EvalComponents/ListaRestrictiva";
import { AntecedentesPolicia } from "./EvalComponents/PoliciaResult";
import { RamaJudicial } from "./EvalComponents/RamaJudicial";
import { ProcesosUnificados } from "./EvalComponents/ProcesoUnificado";
import { MultasInfracciones } from "./EvalComponents/MultaResult";
import { Procuraduria } from "./EvalComponents/Procuraduria";
import { RuntPersonaResult } from "./EvalComponents/RuntPersona";
import { RuntPlacaResult } from "./EvalComponents/RuntPlaca";
import { SofiaPlus } from "./EvalComponents/SofiaPlus";

let getFilteredRequestsMethod = (
  type: "transportes" | "funcionarios" | "clients"
) => {
  switch (type) {
    case "transportes":
      return "request/getFilteredRequestsTransportes";
    case "clients":
      return "request/getFilteredRequestsProveedores";
    case "funcionarios":
      return "request/getFilteredRequestsFuncionarios";
    default:
      return "request/getFilteredRequestsTransportes";
  }
};
let generateEvaluatedReport = "evaluated/generateReportById";
let getRequestByIdPath = "request/getRequest";
let getUserByIdPath = "users/getUser";
let registerTransportesPath = "request/registerConsultation";
let reactivateRequestPath = "request/reActivateRequest";
let rndcPersona = "transportes/getRNDCPersona";
let rndcPlaca = "transportes/getRNDCVehiculo";
let subjectSave = "request/updateRequestStudy";
let requestConsultationPath = "request/consultRequest";

let dataUrl = process.env.REACT_APP_DATA_URL!;

let downloadReportAPI = async (id: string) => {
  let response = await axiosApiInstance.get<string>(
    `${generateEvaluatedReport}/${id}`,
    { responseType: "blob" }
  );
  if (!response.data) {
    return false;
  } else {
    fileDownload(response.data, `reporte${id}.pdf`);
    return true;
  }
};

let getFilteredRequestsAPI = async (
  filteredRequest: FilteredRequest
): Promise<{
  error: string;
  requests: FilteredResponse | null;
}> => {
  try {
    let requestsResponse = await axiosApiInstance.post<FilteredResponse>(
      `${getFilteredRequestsMethod(filteredRequest.type)}`,
      filteredRequest
    );
    return { error: "", requests: requestsResponse.data };
  } catch (err: any) {
    return { error: `${err.message}`, requests: null };
  }
};

export const getRequestByIdAPI = async (
  id: string
): Promise<Request | null> => {
  try {
    let requestsResponse = await axiosApiInstance.get<Request>(
      `${getRequestByIdPath}/${id}`
    );

    return requestsResponse.data;
  } catch {
    return null;
  }
};

export const getConsultationByRequestId = async (
  id: string
): Promise<ResultConsultation | null> => {
  try {
    let requestsResponse = await axiosApiInstance.get<ResultConsultation>(
      `${requestConsultationPath}/${id}`
    );

    return requestsResponse.data;
  } catch {
    return null;
  }
};

export const getResultDataAPI = async (
  id: string
): Promise<ResultData | null> => {
  try {
    let token = await authData();
    let resultData = await axios.get<ResultData>(
      `${dataUrl}/consult/getResults/${id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return resultData.data;
  } catch {
    return null;
  }
};

export const getPDFData = async (id: string): Promise<boolean> => {
  try {
    let data = await getConsultationByRequestId(id);
    if (data === null) {
      return false;
    }
    let token = await authData();
    let resultData = await axios.post<{ pdf: string }>(
      `${dataUrl}/generatePDF`,
      data.subjects,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (resultData.data.pdf && resultData.data.pdf.length > 0) {
      let url = `data:application/pdf;base64,${resultData.data.pdf}`;

      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          fileDownload(blob, `reporte${data?.request.id}.pdf`);
        });

      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

export const getUserByIdAPI = async (id: string): Promise<UserInfo | null> => {
  try {
    let requestsResponse = await axiosApiInstance.get<UserInfo>(
      `${getUserByIdPath}/${id}`
    );

    return requestsResponse.data;
  } catch {
    return null;
  }
};

export const registerConsultationAPI = async (
  req: RegistrationRequest
): Promise<RegisterConsultationResponse | null> => {
  try {
    let registerResponse =
      await axiosApiInstance.post<RegisterConsultationResponse>(
        `${registerTransportesPath}`,
        req
      );

    return registerResponse.data;
  } catch {
    return null;
  }
};

export const reactivateRequestAPI = async (id: string) => {
  try {
    let response = await axiosApiInstance.get<Request>(
      `${reactivateRequestPath}/${id}`
    );

    return response.data;
  } catch {
    return null;
  }
};

export const updateRequestStudyAPI = async (
  req: SaveSubjectsRequest
): Promise<boolean> => {
  try {
    let registerResponse = await axiosApiInstance.post<boolean>(
      `${subjectSave}`,
      req
    );

    return registerResponse.data;
  } catch {
    return false;
  }
};

export const saveResultAPI = async (req: SaveRequest) => {
  try {
    let token = await authData();
    await axios.post(
      `${dataUrl}/consult/saveResults/${req.identifier}`,
      req.result,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return true;
  } catch {
    return false;
  }
};

export const getRNDCAPI = async (
  req: RNDCPersonaRequest
): Promise<Array<RNDCItem>> => {
  try {
    let token = await authData();
    let rndcPersonaResponse = await axios.post<Array<RNDCItem>>(
      `${dataUrl}/${rndcPersona}`,
      req,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return rndcPersonaResponse.data;
  } catch {
    return [];
  }
};

export const getRNDCPlacaAPI = async (
  req: RNDCPlacaRequest
): Promise<Array<RNDCItem>> => {
  try {
    let token = await authData();
    let rndcPlacaResponse = await axios.post<Array<RNDCItem>>(
      `${dataUrl}/${rndcPlaca}`,
      req,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return rndcPlacaResponse.data;
  } catch {
    return [];
  }
};

export const getFosygaAPI = async (id: string): Promise<FosygaData | null> => {
  try {
    let token = await authData();

    let fosygaResponse = await axios.get<FosygaData>(
      `${dataUrl}/transportes/getFosyga/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return fosygaResponse.data;
  } catch {
    return null;
  }
};

export const getLicenciasSuspendidasAPI = async (
  id: string
): Promise<LicenciaSupendida | null> => {
  try {
    let token = await authData();

    let response = await axios.get<LicenciaSupendida>(
      `${dataUrl}/transportes/getLicenciasSuspendidas/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch {
    return null;
  }
};

export const getOFACAPI = async (id: string): Promise<Lista | null> => {
  try {
    let token = await authData();

    let response = await axios.get<Lista>(`${dataUrl}/transportes/ofac/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch {
    return null;
  }
};

export const getONUAPI = async (id: string): Promise<Lista | null> => {
  try {
    let token = await authData();

    let response = await axios.get<Lista>(`${dataUrl}/transportes/onu/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch {
    return null;
  }
};

export const getUEAPI = async (id: string): Promise<Lista | null> => {
  try {
    let token = await authData();

    let response = await axios.get<Lista>(`${dataUrl}/transportes/ue/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch {
    return null;
  }
};

export const getPoliciaAPI = async (
  id: string
): Promise<AntecedentesPolicia | null> => {
  try {
    let token = await authData();

    let response = await axios.get<AntecedentesPolicia>(
      `${dataUrl}/transportes/policia/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch {
    return null;
  }
};

export const getRamaAPI = async (id: string): Promise<RamaJudicial | null> => {
  try {
    let token = await authData();

    let response = await axios.get<RamaJudicial>(
      `${dataUrl}/transportes/ramajudicial/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch {
    return null;
  }
};

export const getProcesosAPI = async (
  id: string
): Promise<ProcesosUnificados | null> => {
  try {
    let token = await authData();

    let response = await axios.get<ProcesosUnificados>(
      `${dataUrl}/transportes/procesosunificados/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch {
    return null;
  }
};

export const getMultasAPI = async (
  id: string
): Promise<MultasInfracciones | null> => {
  try {
    let token = await authData();

    let response = await axios.get<MultasInfracciones>(
      `${dataUrl}/transportes/simit/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch {
    return null;
  }
};

export const getProcuraduriaAPI = async (
  id: string
): Promise<Procuraduria | null> => {
  try {
    let token = await authData();
    let response = await axios.get<Procuraduria>(
      `${dataUrl}/transportes/procuraduria/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch {
    return null;
  }
};

export const getRuntPersonaAPI = async (
  id: string
): Promise<RuntPersonaResult | null> => {
  try {
    let token = await authData();

    let response = await axios.get<RuntPersonaResult>(
      `${dataUrl}/transportes/runtPersona/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch {
    return null;
  }
};

export const getRuntPlacaAPI = async (
  id: string
): Promise<RuntPlacaResult | null> => {
  try {
    let token = await authData();

    let response = await axios.get<RuntPlacaResult>(
      `${dataUrl}/transportes/runtPlaca/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch {
    return null;
  }
};

export const getRndcPersonaAPI = async (
  req: RNDCPersonaRequest
): Promise<Array<RNDCItem>> => {
  try {
    let token = await authData();
    let response = await axios.post<Array<RNDCItem>>(
      `${dataUrl}/transportes/getRNDCPersona/`,
      req,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch {
    return [];
  }
};

export const getRndcPlacaAPI = async (
  req: RNDCPlacaRequest
): Promise<Array<RNDCItem>> => {
  try {
    let token = await authData();

    let response = await axios.post<Array<RNDCItem>>(
      `${dataUrl}/transportes/getRNDCVehiculo/`,
      req,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch {
    return [];
  }
};

export const getSofiaPlusAPI = async (
  id: string
): Promise<SofiaPlus | null> => {
  try {
    let token = await authData();

    let response = await axios.get<SofiaPlus>(
      `${dataUrl}/transportes/sofiaplus/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch {
    return null;
  }
};

export const authData = async () => {
  let fosygaResponse = await axios.get<AuthResponse>(
    `${dataUrl}/authenticate`,
    {
      auth: {
        username: process.env.REACT_APP_DATA_USERNAME!,
        password: process.env.REACT_APP_DATA_PASSWORD!,
      },
    }
  );
  return fosygaResponse.data.token;
};

export const downloadFileAPI = async (id: string) => {
  let file = await axiosApiInstance.post<DownloadedFile>(
    `request/downloadFile`,
    { id }
  );
  return file.data;
};

export interface AuthResponse {
  token: string;
  expiresIn: number;
}

export interface Request {
  type: string;
  driverIdType: string;
  driverIdentification: string;
  ownerIdType: string;
  ownerIdentification: string;
  trailerOwnerIdentification: string;
  vehicleType: string;
  placaVehicle: string;
  placaTrailer: string;
  officialIdType: string;
  officialIdentification: string;
  officialPosition: string;
  providerNit: string;
  fileNames: Array<FileRequest>;
  providerIdentification: string;
  observations: string;
  creationDate: string;
  status: string;
  comments: Array<CommentRequest>;
  clientId: string;
  userId: string;
  id: string;
  user: UserInfo;
}

export interface UserInfo {
  username: string;
  name: string;
}

export interface FileRequest {
  fileName: string;
  fileId: string;
}

export interface CommentRequest {
  content: string;
  date: string;
}

export interface FilteredResponse {
  requests: Array<Request>;
  numPages: number;
}

export interface FilteredRequest {
  page: number;
  status: string | null;
  type: "transportes" | "funcionarios" | "clients";
}

export interface RegistrationRequest {
  identificacionConductor: string;
  tipoIdentificacionConductor: string;
  identificacionPropietario: string;
  tipoIdentificacionPropietario: string;
  identificacionOfficial: string;
  tipoIdentificacionOfficial: string;
  identificacionProvider: string;
  tipoIdentificacionProvider: string;
  placaCabezote: string;
  placaTrailer: string;
  trailerOwnerIdentification: string;
  type: string;
}

export interface RegisterConsultationResponse {
  identifierPersona: string;
  identifierPropietario: string;
  identifierVehiculo: string;
  identifierTrailer: string;
  identifierOfficial: string;
  identifierProvider: string;
  datoConsultadoPersona: number;
  datoConsultadoPropietario: number;
  datoConsultadoPlaca: number;
  datoConsultadoTrailer: number;
  datoConsultadoOfficial: number;
  datoConsultadoProvider: number;
  error: string;
}

export interface RNDCPersonaRequest {
  datoConsultadoPersona: string;
  cedula: string;
}

export interface RNDCPlacaRequest {
  datoConsultadoPlaca: string;
  placa: string;
}

export interface ResultData {
  rndcCedula: Array<RNDCItem> | null;
  rndcPlaca: Array<RNDCItem> | null;
  fosyga: FosygaData | null;
  licenciasSuspendidas: LicenciaSupendida | null;
  ofac: Lista | null;
  onu: Lista | null;
  ue: Lista | null;
  policia: AntecedentesPolicia | null;
  ramaJudicial: RamaJudicial | null;
  procesosUnificados: ProcesosUnificados | null;
  multas: MultasInfracciones | null;
  procuraduria: Procuraduria | null;
  runtPersona: RuntPersonaResult | null;
  runtPlaca: RuntPlacaResult | null;
  sofiaPlus: SofiaPlus | null;
}

export interface SaveRequest {
  result: ResultData;
  identifier: string;
}

export interface SaveSubjectsRequest {
  registration: RegisterConsultationResponse;
  request: Request;
  idRequest: string;
}

export interface SaveResultInfo {
  observations: string;
  status: string;
  comments: Array<CommentRequest>;
}

export interface Subject {
  idType: string;
  identification: string;
  currentDataIdentifier: string;
  currentDataDate: string;
}

export interface ResultConsultation {
  request: Request;
  subjects: Array<Subject>;
  user: UserInfo;
}

export interface ResultData {
  rndcCedula: Array<RNDCItem> | null;
  rndcPlaca: Array<RNDCItem> | null;
  fosyga: FosygaData | null;
  licenciasSuspendidas: LicenciaSupendida | null;
  ofac: Lista | null;
  onu: Lista | null;
  ue: Lista | null;
  policia: AntecedentesPolicia | null;
  ramaJudicial: RamaJudicial | null;
  procesosUnificados: ProcesosUnificados | null;
  multas: MultasInfracciones | null;
  procuraduria: Procuraduria | null;
  runtPersona: RuntPersonaResult | null;
  runtPlaca: RuntPlacaResult | null;
  sofiaPlus: SofiaPlus | null;
}

export interface DownloadedFile {
  fileName: string;
  dataBase64: string;
}

export { getFilteredRequestsAPI, downloadReportAPI };
