import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import ClipLoader from "react-spinners/ClipLoader";
import LoadingOverlay from "react-loading-overlay-ts";
import { ReactComponent as LeftArrow } from "../../../../../Assets/images/leftArrow.svg";
import {
  getResultData,
  resetDetailRequest,
  selectConsultedRequest,
  selectCurrentResultData,
  selectLoadingResultData,
  selectSelectedElement,
} from "../../securityEvaluationSlice";
import { useEffect } from "react";
import { FosygaResult } from "../../EvalComponents/FosygaResult";
import { LicenciaSuspendida } from "../../EvalComponents/LicenciaSuspendida";
import { ListaRestrictiva } from "../../EvalComponents/ListaRestrictiva";
import { PoliciaResult } from "../../EvalComponents/PoliciaResult";
import { ProcuraduriaResult } from "../../EvalComponents/Procuraduria";
import { ProcesosUnificadosResult } from "../../EvalComponents/ProcesoUnificado";
import { RuntPersona } from "../../EvalComponents/RuntPersona";
import { MultaResult } from "../../EvalComponents/MultaResult";
import { RNDCResult } from "../../EvalComponents/RNDCResult";
import { SofiaPlusComponent } from "../../EvalComponents/SofiaPlus";
import { RamaJudicialResult } from "../../EvalComponents/RamaJudicial";
import { RuntPlaca } from "../../EvalComponents/RuntPlaca";

export const ConsultResult = () => {
  let params = useParams<{ id: string }>();

  let resultData = useAppSelector(selectCurrentResultData);
  let resultLoading = useAppSelector(selectLoadingResultData);
  let elementSelected = useAppSelector(selectSelectedElement);
  let currentConsultedRequest = useAppSelector(selectConsultedRequest);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    if (!resultData) {
      dispatch(getResultData(params.id!));
    }
    return () => {};
  }, [dispatch, navigate, params.id, resultData]);

  return (
    <LoadingOverlay
      styles={{
        content: {},
        overlay: {},
        spinner: {},
        wrapper: { height: "90%" },
      }}
      active={resultLoading === "pending"}
      className="overflow-auto"
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {currentConsultedRequest && (
        <div className="flex flex-col w-full h-6/6 items-center justify-evenly">
          <div className="flex flex-row h-1/5 w-full items-center justify-evenly">
            <div
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="bg-primary-5 flex flex-row items-center justify-start h-11 w-64 rounded-md box-border px-5 cursor-pointer"
            >
              <LeftArrow className="mr-16" />
              <div className="text-primary text-lg font-bold">Regresar</div>
            </div>
            <div className="text-5xl text-primary font-extrabold text-center">
              Estudio de Seguridad
            </div>
          </div>
          <div className="flex flex-col items-center justify-center h-1/6 w-full">
            <div className="text-4xl text-primary text-center font-extrabold mb-4">
              Resultado del estudio de
            </div>
          </div>
          <div className="flex flex-col h-4/6 w-full items-center">
            {elementSelected === "Conductor" ? (
              <div className="h-full flex flex-col w-full items-center">
                <div className="text-3xl text-primary text-center font-extrabold my-4">
                  Conductor
                </div>
                <div className="flex flex-row items-center justify-center w-full">
                  <div className="flex flex-row justify-evenly w-full">
                    <div className="flex flex-row justify-center w-full mx-5">
                      <div className="text-lg text-primary-35 font-bold mr-10">
                        No. de documento
                      </div>
                      <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 text-center text-primary text-sm font-semibold">
                        {currentConsultedRequest.request?.driverIdentification}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-full justify-evenly my-5">
                    <div className="flex flex-row justify-center w-full mx-5">
                      <div className="text-lg text-primary-35 font-bold mr-10">
                        Tipo de documento
                      </div>
                      <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-4 py-2 text-center text-primary text-sm font-semibold">
                        {currentConsultedRequest.request?.driverIdType === "cc"
                          ? "Cedula de ciudadanía"
                          : "Cedula de extranjería"}
                      </div>
                    </div>
                  </div>
                </div>
                {currentConsultedRequest.request?.driverIdentification ? (
                  <div className="overflow-auto w-full">
                    <FosygaResult
                      item={resultData?.fosyga!}
                      loading={"resolved"}
                    />
                    <LicenciaSuspendida
                      item={resultData?.licenciasSuspendidas!}
                      loading={"resolved"}
                    />
                    <PoliciaResult
                      item={resultData?.policia!}
                      loading={"resolved"}
                    />
                    <ProcuraduriaResult
                      item={resultData?.procuraduria!}
                      loading={"resolved"}
                    />
                    <RamaJudicialResult
                      item={resultData?.ramaJudicial!}
                      loading={"resolved"}
                    />
                    <ProcesosUnificadosResult
                      item={resultData?.procesosUnificados!}
                      loading={"resolved"}
                    />
                    <RuntPersona
                      item={resultData?.runtPersona!}
                      loading={"resolved"}
                    />
                    <MultaResult
                      item={resultData?.multas!}
                      loading={"resolved"}
                    />
                    <RNDCResult
                      items={resultData?.rndcCedula!}
                      loading={"resolved"}
                    />

                    <ListaRestrictiva
                      name="OFAC"
                      item={resultData?.ofac!}
                      loading={"resolved"}
                    />
                    <ListaRestrictiva
                      name="ONU"
                      item={resultData?.onu!}
                      loading={"resolved"!}
                    />
                    <ListaRestrictiva
                      name="UE"
                      item={resultData?.ue!}
                      loading={"resolved"}
                    />
                    <SofiaPlusComponent
                      item={resultData?.sofiaPlus!}
                      loading={"resolved"}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {elementSelected === "Propietario" ? (
              <div className="h-full flex flex-col w-full items-center">
                <div className="text-3xl text-primary text-center font-extrabold my-4">
                  Propietario
                </div>
                <div className="flex flex-row items-center justify-center w-full">
                  <div className="flex flex-row justify-evenly w-full">
                    <div className="flex flex-row justify-center w-full mx-5">
                      <div className="text-lg text-primary-35 font-bold mr-10">
                        No. de documento
                      </div>
                      <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 text-center text-primary text-sm font-semibold">
                        {currentConsultedRequest.request?.ownerIdentification}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-full justify-evenly my-5">
                    <div className="flex flex-row justify-center w-full mx-5">
                      <div className="text-lg text-primary-35 font-bold mr-10">
                        Tipo de documento
                      </div>
                      <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-4 py-2 text-center text-primary text-sm font-semibold">
                        {currentConsultedRequest.request?.ownerIdType === "cc"
                          ? "Cedula de ciudadanía"
                          : "Nit"}
                      </div>
                    </div>
                  </div>
                </div>
                {currentConsultedRequest.request?.ownerIdentification ? (
                  <div className="overflow-auto w-full">
                    <PoliciaResult
                      item={resultData?.policia!}
                      loading={"resolved"}
                    />
                    <ProcuraduriaResult
                      item={resultData?.procuraduria!}
                      loading={"resolved"}
                    />
                    <RamaJudicialResult
                      item={resultData?.ramaJudicial!}
                      loading={"resolved"}
                    />
                    <ProcesosUnificadosResult
                      item={resultData?.procesosUnificados!}
                      loading={"resolved"}
                    />
                    <ListaRestrictiva
                      name="OFAC"
                      item={resultData?.ofac!}
                      loading={"resolved"}
                    />
                    <ListaRestrictiva
                      name="ONU"
                      item={resultData?.onu!}
                      loading={"resolved"!}
                    />
                    <ListaRestrictiva
                      name="UE"
                      item={resultData?.ue!}
                      loading={"resolved"}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {elementSelected === "Proveedor" ? (
              <div className="h-full flex flex-col w-full items-center">
                <div className="text-3xl text-primary text-center font-extrabold my-4">
                  Cliente o proveedor
                </div>
                <div className="flex flex-row items-center justify-center w-full">
                  <div className="flex flex-row justify-evenly w-full">
                    <div className="flex flex-row justify-center w-full mx-5">
                      <div className="text-lg text-primary-35 font-bold mr-10">
                        No. de documento
                      </div>
                      <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 text-center text-primary text-sm font-semibold">
                        {currentConsultedRequest.request?.providerNit}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-full justify-evenly my-5">
                    <div className="flex flex-row justify-center w-full mx-5">
                      <div className="text-lg text-primary-35 font-bold mr-10">
                        Tipo de documento
                      </div>
                      <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-4 py-2 text-center text-primary text-sm font-semibold">
                        NIT
                      </div>
                    </div>
                  </div>
                </div>
                {currentConsultedRequest.request?.ownerIdentification ? (
                  <div className="overflow-auto w-full">
                    <ProcuraduriaResult
                      item={resultData?.procuraduria!}
                      loading={"resolved"}
                    />
                    <RamaJudicialResult
                      item={resultData?.ramaJudicial!}
                      loading={"resolved"}
                    />
                    <ProcesosUnificadosResult
                      item={resultData?.procesosUnificados!}
                      loading={"resolved"}
                    />

                    <ListaRestrictiva
                      name="OFAC"
                      item={resultData?.ofac!}
                      loading={"resolved"}
                    />
                    <ListaRestrictiva
                      name="ONU"
                      item={resultData?.onu!}
                      loading={"resolved"!}
                    />
                    <ListaRestrictiva
                      name="UE"
                      item={resultData?.ue!}
                      loading={"resolved"}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {elementSelected === "Funcionario" ? (
              <div className="h-full flex flex-col w-full items-center">
                <div className="text-3xl text-primary text-center font-extrabold my-4">
                  Funcionario
                </div>
                <div className="flex flex-row items-center justify-center w-full">
                  <div className="flex flex-row justify-evenly w-full">
                    <div className="flex flex-row justify-center w-full mx-5">
                      <div className="text-lg text-primary-35 font-bold mr-10">
                        No. de documento
                      </div>
                      <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 text-center text-primary text-sm font-semibold">
                        {
                          currentConsultedRequest.request
                            ?.officialIdentification
                        }
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-full justify-evenly my-5">
                    <div className="flex flex-row justify-center w-full mx-5">
                      <div className="text-lg text-primary-35 font-bold mr-10">
                        Tipo de documento
                      </div>
                      <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-4 py-2 text-center text-primary text-sm font-semibold">
                        {currentConsultedRequest.request?.officialIdType ===
                        "cc"
                          ? "Cedula de ciudadanía"
                          : "Nit"}
                      </div>
                    </div>
                  </div>
                </div>
                {currentConsultedRequest.request?.officialIdentification ? (
                  <div className="overflow-auto w-full">
                    <PoliciaResult
                      item={resultData?.policia!}
                      loading={"resolved"}
                    />
                    <ProcuraduriaResult
                      item={resultData?.procuraduria!}
                      loading={"resolved"}
                    />
                    <RamaJudicialResult
                      item={resultData?.ramaJudicial!}
                      loading={"resolved"}
                    />
                    <ProcesosUnificadosResult
                      item={resultData?.procesosUnificados!}
                      loading={"resolved"}
                    />

                    <ListaRestrictiva
                      name="OFAC"
                      item={resultData?.ofac!}
                      loading={"resolved"}
                    />
                    <ListaRestrictiva
                      name="ONU"
                      item={resultData?.onu!}
                      loading={"resolved"}
                    />
                    <ListaRestrictiva
                      name="UE"
                      item={resultData?.ue!}
                      loading={"resolved"}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {elementSelected === "Vehiculo" ? (
              <div className="h-full flex flex-col w-full items-center">
                <div className="text-3xl text-primary text-center font-extrabold my-4">
                  Vehículo
                </div>
                <div className="flex flex-row items-center justify-center w-full">
                  <div className="flex flex-row justify-evenly w-full">
                    <div className="flex flex-row justify-center w-full mx-5">
                      <div className="text-lg text-primary-35 font-bold mr-10">
                        Placa
                      </div>
                      <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 text-center text-primary text-sm font-semibold">
                        {currentConsultedRequest.request?.placaVehicle}
                      </div>
                    </div>
                  </div>
                </div>
                {currentConsultedRequest.request?.placaVehicle ? (
                  <div className="overflow-auto w-full">
                    <RNDCResult
                      items={resultData?.rndcPlaca!}
                      loading={"resolved"}
                    />
                    <RuntPlaca
                      item={resultData?.runtPlaca!}
                      loading={"resolved"}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {elementSelected === "Remolque" ? (
              <div className="h-full flex flex-col w-full items-center">
                <div className="text-3xl text-primary text-center font-extrabold my-4">
                  Remolque
                </div>
                <div className="flex flex-row items-center justify-center w-full">
                  <div className="flex flex-row justify-evenly w-full">
                    <div className="flex flex-row justify-center w-full mx-5">
                      <div className="text-lg text-primary-35 font-bold mr-10">
                        Placa
                      </div>
                      <div className="bg-primary-10 border-0.5 border-primary-20 box-border rounded-md px-3 py-2 text-center text-primary text-sm font-semibold">
                        {currentConsultedRequest.request?.placaTrailer}
                      </div>
                    </div>
                  </div>
                </div>
                {currentConsultedRequest.request?.placaTrailer ? (
                  <div className="overflow-auto w-full">
                    <RuntPlaca
                      item={resultData?.runtPlaca!}
                      loading={"resolved"}
                    />
                    <RNDCResult
                      items={resultData?.rndcPlaca!}
                      loading={"resolved"}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </LoadingOverlay>
  );
};
