import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../login/loginSlice";

export interface PoliciaProps {
  loading: LoadingStatus;
  item: AntecedentesPolicia | null;
}

export interface AntecedentesPolicia {
  lista: string;
  presentaRiesgo: string;
  mensajeRespuesta: string;
  tipoDocumentoNoValido?: string;
}

export const PoliciaResult = (props: PoliciaProps) => {
  const getColor = (text: string) => {
    if (!text) return "text-black-400";
    if (
      text.includes(
        "NO TIENE ASUNTOS PENDIENTES CON LAS AUTORIDADES JUDICIALES"
      )
    ) {
      return "text-green-400";
    } else if (
      text.includes("ACTUALMENTE NO ES REQUERIDO POR AUTORIDAD JUDICIAL ALGUNA")
    ) {
      return "text-yellow-400";
    } else if (text.includes("Por favor acérquese a las instalaciones")) {
      return "text-red-400";
    } else {
      return "text-red-400";
    }
  };
  return (
    <LoadingOverlay
      styles={{
        content: {},
        overlay: {},
        spinner: {},
        wrapper: { height: "fit-content" },
      }}
      active={props.loading === "pending"}
      className="overflow-auto mb-10"
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {props.item && props.item.tipoDocumentoNoValido ? (
        <>
          <div className="flex flex-col justify-evenly w-full px-2">
            <div className={`text-white text-3xl font-semibold text-center`}>
              Antecedente Policia
            </div>

            <div className="text-primary text-sm font-semibold text-center mt-2">
              {props.item.tipoDocumentoNoValido}
            </div>
          </div>
        </>
      ) : (
        <>
          {props.item ? (
            <div className="flex flex-col justify-evenly w-full px-2">
              <div
                className={`${getColor(
                  props.item.mensajeRespuesta
                )} text-3xl font-semibold text-center`}
              >
                Antecedente Policia
              </div>

              <div className="text-primary text-sm font-semibold text-center mt-2">
                Mensaje del resultado: {props.item.mensajeRespuesta}
              </div>
            </div>
          ) : (
            <div>
              {props.loading === "rejected" || props.loading === "resolved" ? (
                <div className="text-primary text-xl font-semibold text-center">
                  No se encontraron registros, intente de nuevo más tarde
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </>
      )}
    </LoadingOverlay>
  );
};
