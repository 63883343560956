import axiosApiInstance from "../../../helpers/interceptor";

let createRequestMethod = "request";
let createTransportesRequest = "request/createTransportesRequest";
let createProveedoresRequest = "request/createProviderRequest";
let createFuncionarioRequest = "request/createOfficialRequest";

export const createRequestAPI = async (solicitud: Request) => {
  let solicitudResponse = await axiosApiInstance.post<Request>(
    `${createRequestMethod}`,
    solicitud
  );
  if (solicitudResponse.status === 200) {
    return { error: "", success: true };
  } else {
    return { error: "", success: false };
  }
};

export const createTransportesRequestAPI = async (
  req: CreateTransportesRequest
) => {
  try {
    let requestResponse = await axiosApiInstance.post<CreateRequestReponse>(
      `${createTransportesRequest}`,
      req
    );
    return { id: requestResponse.data.id, error: "" };
  } catch (e: any) {
    console.log(e.response);
    let error = "";
    if (e.response) {
      if (e.response.status === 401) {
        error = "401";
      } else {
        error = e.response.data.error;
      }
    }
    return { id: "", error: error };
  }
};

export const createProveedoresRequestAPI = async (
  req: CreateProveedoresRequest
) => {
  try {
    let requestResponse = await axiosApiInstance.post<CreateRequestReponse>(
      `${createProveedoresRequest}`,
      req
    );
    return { id: requestResponse.data.id, error: "" };
  } catch (e: any) {
    let error = "";
    if (e.response) {
      if (e.response.status === 401) {
        error = "401";
      } else {
        error = e.response.data.error;
      }
    }
    return { id: "", error: error };
  }
};

export const createFuncionarioRequestAPI = async (
  req: CreateOfficialRequest
) => {
  try {
    let requestResponse = await axiosApiInstance.post<CreateRequestReponse>(
      `${createFuncionarioRequest}`,
      req
    );
    return { id: requestResponse.data.id, error: "" };
  } catch (e: any) {
    let error = "";
    if (e.response) {
      if (e.response.status === 401) {
        error = "401";
      } else {
        error = e.response.data.error;
      }
    }
    return { id: "", error: error };
  }
};

export interface CreateTransportesRequest {
  driverIdType: string | null;
  driverIdentification: string | null;
  ownerIdType: string | null;
  ownerIdentification: string | null;
  vehicleType: string | null;
  placaVehicle: string | null;
  placaTrailer: string | null;
  observations: string | null;
  trailerOwnerIdentification: string | null;
}

export interface CreateProveedoresRequest {
  providerNit: string;
  observations: string;
  files: Array<FileRequestRequest>;
}

export interface FileRequestRequest {
  fileName: string;
  fileBase64: string;
  fileType: string;
}

export interface CreateOfficialRequest {
  officialIdType: string;
  officialIdentification: string;
  oficialPosition: string;
  observations: string;
}

export interface CreateRequestReponse {
  id: string;
}
