import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../login/loginSlice";

export interface RuntPlacaProps {
  loading: LoadingStatus;
  item: RuntPlacaResult | null;
}

export interface RuntPlacaResult {
  cap_carga: number;
  motor: string;
  estado: string;
  color: string;
  pbv: number;
  fechaRegistro: string;
  placaRna: string;
  modelo: string;
  linea: string;
  noLicenciaTransito: string;
  organismoTransito: string;
  clase: string;
  marca: string;
  cilindraje: string;
  regrabacionSerie: string;
  chasis: string;
  serie: string;
  regrabacionMotor: string;
  vin: string;
  id: number;
  clasificacion: string;
  prenda: string;
  limitaciones: string;
  placa: string;
  data: string;
}

export const RuntPlaca = (props: RuntPlacaProps) => {
  return (
    <LoadingOverlay
      styles={{
        content: {},
        overlay: {},
        spinner: {},
        wrapper: { height: "fit-content" },
      }}
      active={props.loading === "pending"}
      className="overflow-auto mb-10"
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {props.item && !props.item.data ? (
        <div className="flex flex-col justify-evenly w-full px-2">
          <div className="text-primary text-3xl font-semibold text-center">
            Runt
          </div>
          <div className="text-primary text-lg font-semibold text-center">
            Datos
          </div>
          <table className="relative w-full">
            <thead>
              <tr>
                <th></th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Placa
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Placa RNA
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Modelo
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Linea
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Marca
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Clase
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Serie
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Chasis
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Prenda
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Cilindraje
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md">
                <td></td>
                <td>{props.item.placa}</td>
                <td>{props.item.placaRna}</td>
                <td>{props.item.modelo}</td>
                <td>{props.item.linea}</td>
                <td>{props.item.marca}</td>
                <td>{props.item.clase}</td>
                <td>{props.item.serie}</td>
                <td>{props.item.chasis}</td>
                <td>{props.item.prenda}</td>
                <td>{props.item.cilindraje}</td>
                <td></td>
              </tr>

              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <table className="relative w-full">
            <thead>
              <tr>
                <th></th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Color
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Capacida de carga
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Estado
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  PBV
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Fecha de registro
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Regrabación Serie
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Regrabación Motor
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  limitaciones
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Motor
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Organismo de transito
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md">
                <td></td>
                <td>{props.item.color}</td>
                <td>{props.item.cap_carga}</td>
                <td>{props.item.estado}</td>
                <td>{props.item.pbv}</td>
                <td>{props.item.fechaRegistro}</td>
                <td>{props.item.regrabacionSerie}</td>
                <td>{props.item.regrabacionMotor}</td>
                <td>{props.item.limitaciones}</td>
                <td>{props.item.motor}</td>
                <td>{props.item.organismoTransito}</td>
                <td></td>
              </tr>

              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <>
              {" "}
              <div className="text-primary text-3xl font-semibold text-center">
                Runt
              </div>
              <div className="text-primary text-xl font-semibold text-center">
                {props.item?.data
                  ? props.item?.data
                  : "No se encontraron registros"}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </LoadingOverlay>
  );
};
