import axios from "axios";
import axiosApiInstance from "../../helpers/interceptor";

let baseUrl = process.env.REACT_APP_BASE_URL || "";
let loginMethod = "auth/login";

let tryLoginAPI = async (
  username: string,
  password: string
): Promise<{
  error: string;
  response: LoginResponse | null;
}> => {
  let request: LoginRequest = { username: username, password: password };
  try {
    let response = await axios.post<LoginResponse>(
      `${baseUrl}/${loginMethod}`,
      request
    );
    return {
      error: "",
      response: response.data,
    };
  } catch (e: any) {
    return {
      error: e.message,
      response: null,
    };
  }
};

let getClientAPI = async () => {
  let response = await axiosApiInstance.get<Client>("client/byId");
  if (response.status === 200) {
    return { error: "", client: response.data };
  } else {
    return { error: `${response.status}`, client: null };
  }
};

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  userName: string;
  roles: Array<string>;
  clientName: string;
  name: string;
}

export interface UserInfo {
  id: string;
  contrasena: string;
  name: string;
  roles: Array<string>;
  usuario: string;
}

export interface Client {
  id: string;
  nombre: string;
  identificacion: string;
  tipoIdentificacion: string;
  configurationIdNeutral: string;
  configurationIdNegative: string;
  configurationIdPositive: string;
}

export { tryLoginAPI, getClientAPI };
