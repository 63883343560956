import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../app/hooks";
import { DropdownSecondary } from "../../../common/DropdownSecondary";
import { ReactComponent as LeftArrow } from "../../../../Assets/images/leftArrow.svg";
import { setCurrentStudy } from "../../../common/commonSlice";

export const SelectType = () => {
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  let tipoSolicitud = [
    { label: "Transportes", value: "transportes" },
    { label: "Funcionarios ", value: "funcionarios" },
    { label: "Clientes y proveedores ", value: "clients" },
  ];

  const [type, setType] = useState("");
  useEffect(() => {
    return () => {};
  }, [dispatch]);

  let getLabel = (value: string) => {
    for (var i = 0; i < tipoSolicitud.length; i++) {
      if (tipoSolicitud[i].value === value) {
        return tipoSolicitud[i].label;
      }
    }
    return "";
  };
  return (
    <div className="flex flex-col h-5/6 w-full items-center justify-start">
      <div className="flex flex-row sm:flex-row h-1/5 w-full items-center justify-evenly">
        <div
          onClick={(e) => {
            e.preventDefault();
            navigate("/services/intro");
          }}
          className="bg-primary-5 flex flex-row items-center justify-start h-11 w-64 rounded-md box-border px-5 cursor-pointer"
        >
          <LeftArrow className="mr-8" />
          <div className="text-primary text-lg font-bold">Regresar al MENU</div>
        </div>
        <div className="text-white text-5xl font-extrabold ">
          Nueva consulta
        </div>
      </div>
      <div className="flex flex-col h-3/5 items-center justify-center w-1/2">
        <div className="text-lg font-bold text-primary mb-14">
          Tipo de consulta
        </div>
        <div className="lg:w-3/6 sm:w-3/5 xl:w-3/5 mb-24 ml-9">
          <DropdownSecondary
            defaultValue={
              type === ""
                ? { label: "SELECCIONE UNA OPCIÓN", value: "" }
                : { label: getLabel(type), value: type }
            }
            title="TipoSolicitud"
            options={tipoSolicitud}
            onChange={(e) => {
              setType(e?.value!);
            }}
          />
        </div>
        {type !== "" ? (
          <div
            onClick={(e) => {
              e.preventDefault();
              switch (type) {
                case "transportes":
                  dispatch(setCurrentStudy("transportes"));
                  navigate("/services/requestProVe");
                  break;
                case "funcionarios":
                  dispatch(setCurrentStudy("funcionarios"));
                  navigate("/services/requestFuncionario");
                  break;
                case "clients":
                  dispatch(setCurrentStudy("clients"));
                  navigate("/services/requestProveedor");
                  break;
                default:
                  break;
              }
            }}
            className="flex flex-col items-center justify-center h-10 w-60 text-primary bg-primary-5 rounded-md text-center font-bold cursor-pointer"
          >
            Continuar
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
