export interface FilterButtonProps {
  selected?: boolean;
  text: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const FilterButton = (props: FilterButtonProps) => {
  return (
    <div
      onClick={props.onClick}
      className={`border-0.5 border-primary-10 bg-primary-5 rounded-md box-border text-center px-6 text-lg ${
        props.selected ? "text-primary" : "text-primary-35"
      } font-bold hover:text-primary cursor-pointer`}
    >
      {props.text}
    </div>
  );
};
