import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import localforage from "localforage";
import { RootState } from "../../app/store";
import { tryLoginAPI, UserInfo } from "./loginAPI";

export type LoadingStatus = "idle" | "resolved" | "rejected" | "pending";

export interface LoginState {
  username: string;
  password: string;
  loading: boolean;
  errorMessage: string;
  tokenString: string;
  requestToken: string;
  tokenExpiryTimestamp: number;
  userInfo: UserInfo | null;
}

// Estado inicial del login
const initialState: LoginState = {
  username: "",
  password: "",
  loading: false,
  errorMessage: "",
  tokenString: "",
  requestToken: "",
  userInfo: null,
  tokenExpiryTimestamp: -1,
};

export const tryLogin = createAsyncThunk(
  "login/tryLogin",
  async (_, { getState, rejectWithValue }) => {
    const currentState = getState() as RootState;
    const username = currentState.login.username;
    const password = currentState.login.password;
    const responseLogin = await tryLoginAPI(username, password);
    if (responseLogin.error === "") {
      await localforage.setItem("token", responseLogin.response?.token);
      return responseLogin.response;
    } else {
      return rejectWithValue(responseLogin.error);
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    changeUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    changePassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    emptyErrorMessage: (state) => {
      state.errorMessage = "";
    },
    setTokenInfo: (
      state,
      action: PayloadAction<{ token: string; expiry: number }>
    ) => {
      state.requestToken = action.payload.token;
      state.tokenExpiryTimestamp = action.payload.expiry;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(tryLogin.fulfilled, (state, action) => {
        let response = action.payload;

        if (response) {
          state.tokenString = response.token;
          state.userInfo = {
            name: response.name,
            roles: response.roles,
            usuario: response.userName,
            contrasena: "",
            id: "",
          };
          state.username = "";
          state.password = "";
        }
        state.loading = false;
      })
      .addCase(tryLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(tryLogin.rejected, (state, action) => {
        state.errorMessage = action.payload as string;
        state.password = "";
        state.loading = false;
      });
  },
});

export const {
  changeUsername,
  changePassword,
  setTokenInfo,
  emptyErrorMessage,
} = loginSlice.actions;

export const selectUsername = (state: RootState) => state.login.username;
export const selectPassword = (state: RootState) => state.login.password;
export const selectLoading = (state: RootState) => state.login.loading;
export const selectTokenString = (state: RootState) => state.login.tokenString;
export const selectRequestToken = (state: RootState) =>
  state.login.requestToken;
export const selectErrorMessage = (state: RootState) =>
  state.login.errorMessage;
export const selectUserInfoLogin = (state: RootState) => state.login.userInfo;

export default loginSlice.reducer;
