export interface LoginButtonProps {
  className?: string;
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
}

export const LoginButton = (props: LoginButtonProps) => {
  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className={`mt-28 w-64 h-11 text-center rounded-md border-0 bg-primary-10 text-primary font-bold uppercase ${
        props.className ? props.className : ""
      }`}
    >
      {props.text}
    </button>
  );
};
