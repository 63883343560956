import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  CombinedState,
  AnyAction,
} from "@reduxjs/toolkit";

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { persistStore } from "redux-persist";
import loginReducer, { LoginState } from "../features/login/loginSlice";
import securityEvaluationReducer, {
  SecurityEvaluationState,
} from "../features/services/securityEvaluation/securityEvaluationSlice";
import requestCreationReducer, {
  RequestCreationState,
} from "../features/services/createRequest/requestCreationSlice";
import updateDataSlice, {
  UpdateDataState,
} from "../features/services/updateData/updateDataSlice";
import commonReducer, { CommonState } from "../features/common/commonSlice";

const reducers = combineReducers({
  login: loginReducer,
  securityEvaluation: securityEvaluationReducer,
  requestCreation: requestCreationReducer,
  common: commonReducer,
  updateData: updateDataSlice,
});

const rootReducer = (
  state:
    | CombinedState<{
        login: LoginState;
        securityEvaluation: SecurityEvaluationState;
        requestCreation: RequestCreationState;
        common: CommonState;
        updateData: UpdateDataState;
      }>
    | undefined,
  action: AnyAction
) => {
  if (action.type === "common/resetState") {
    state = undefined;
  }
  return reducers(state, action);
};

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["common"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
