import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../login/loginSlice";

export interface FosygaProps {
  loading: LoadingStatus;
  item: FosygaData | null;
}

export interface FosygaData {
  afiliaciones: Array<AfiliacionFosyga>;
  presentaRiesgo: boolean;
  datosBasicos: {
    apellidos: string;
    tipoIdentificacion: string;
    numIdentificacion: string;
    fechaNacimiento: string;
    municipio: string;
    departamento: string;
    nombres: string;
  };
}

export interface AfiliacionFosyga {
  estado: string;
  fechaAfiliacion: string;
  entidad: string;
  regimen: string;
  tipoAfiliado: string;
}

export const FosygaResult = (props: FosygaProps) => {
  return (
    <LoadingOverlay
      styles={{
        content: {},
        overlay: {},
        spinner: {},
        wrapper: { height: "fit-content" },
      }}
      active={props.loading === "pending"}
      className="overflow-auto mb-10"
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {props.item ? (
        <div className="flex flex-col justify-evenly w-full px-2">
          <div className="text-primary text-3xl font-semibold text-center">
            Fosyga
          </div>
          {props.item && props.item.datosBasicos && (
            <div
              className={` ${
                props.item.presentaRiesgo ? "text-red-400" : "text-primary"
              } text-xl font-semibold text-center`}
            >
              Datos Básicos
            </div>
          )}
          {props.item && props.item.datosBasicos ? (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Nombre
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Apellidos
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Tipo Identificación
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Identificación
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha de nacimiento
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Municipio
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Departamento
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                <tr className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md">
                  <td></td>
                  <td>{props.item.datosBasicos.nombres}</td>
                  <td>{props.item.datosBasicos.apellidos}</td>
                  <td>{props.item.datosBasicos.tipoIdentificacion}</td>
                  <td>{props.item.datosBasicos.numIdentificacion}</td>
                  <td>{props.item.datosBasicos.fechaNacimiento}</td>
                  <td>{props.item.datosBasicos.municipio}</td>
                  <td>{props.item.datosBasicos.departamento}</td>
                  <td></td>
                </tr>

                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
          <div className="text-primary text-xl font-semibold text-center">
            Afiliaciones
          </div>
          {props.item && props.item.afiliaciones ? (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Estado
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha de afiliación
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Entidad
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Regimen
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Tipo de Afiliado
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item &&
                  props.item.afiliaciones &&
                  props.item.afiliaciones.map((afiliacion, i) => {
                    return (
                      <tr
                        key={i}
                        className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                      >
                        <td></td>
                        <td>{afiliacion.estado}</td>
                        <td>{afiliacion.fechaAfiliacion}</td>
                        <td>{afiliacion.entidad}</td>
                        <td>{afiliacion.regimen}</td>
                        <td>{afiliacion.tipoAfiliado}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <h5 className="text-red-300 text-2xl font-semibold">
                La persona no está registrado en BDUA
              </h5>
            </div>
          )}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <div className="text-primary text-xl font-semibold text-center">
              No se encontraron registros, intente de nuevo más tarde
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </LoadingOverlay>
  );
};
