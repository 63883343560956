import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "react-spinners/ClipLoader";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { ReactComponent as LeftArrow } from "../../../../../Assets/images/leftArrow.svg";
import {
  registerTransportes,
  resetDetailData,
  selectCurrentRegistration,
  selectCurrentRequest,
} from "../../securityEvaluationSlice";

export const GettingAutomatic = () => {
  let registration = useAppSelector(selectCurrentRegistration);
  let request = useAppSelector(selectCurrentRequest);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let params = useParams<{ id: string }>();

  useEffect(() => {
    if (request && !registration) {
      dispatch(registerTransportes());
    }

    if (!registration?.error && registration) {
      navigate(`/services/securityEvaluation/automatic/alerts/${params.id}`);
    }
  }, [request, registration, dispatch, navigate, params.id]);

  return (
    <div className="flex flex-col h-5/6 w-full items-center justify-evenly">
      {registration?.error ? (
        <>
          <div className="text-2xl text-primary font-extrabold text-center">
            Error Consultando listas
          </div>
          {registration?.error && (
            <p className="text-lg text-center font-semibold text-primary">
              {registration.error}
            </p>
          )}
          <div
            onClick={(e) => {
              e.preventDefault();
              dispatch(resetDetailData());
              navigate("/services/securityEvaluation");
            }}
            className="bg-primary-5 flex flex-row items-center justify-start h-11 w-64 rounded-md box-border px-5 cursor-pointer"
          >
            <div className="text-primary text-lg font-bold">
              Intentar de nuevo
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            onClick={(e) => {
              e.preventDefault();
              dispatch(resetDetailData());
              navigate(`/services/securityEvaluation/choose/${params.id}`);
            }}
            className="bg-primary-5 flex flex-row items-center justify-start h-11 w-64 rounded-md box-border px-5 cursor-pointer"
          >
            <LeftArrow className="mr-16" />
            <div className="text-primary text-lg font-bold">Regresar</div>
          </div>
          <div className="text-2xl text-primary font-extrabold text-center w-2/5">
            En este momento estamos realizando el estudio de seguridad
            automático
          </div>
          <div className="flex flex-col items-center h-28">
            <Loader size={90} color="#FFFFFF" />
          </div>
          <div className="text-2xl text-primary font-extrabold text-center">
            Consultaremos las siguientes listas
          </div>
          <div className="flex flex-col justify-start items-center">
            <>
              <div className="text-lg font-semibold text-primary text-center">
                Procuraduría
              </div>
              <div className="text-lg font-semibold text-primary text-center">
                Rama Judicial
              </div>
              <div className="text-lg font-semibold text-primary text-center">
                Procesos unificados
              </div>
              <div className="text-lg font-semibold text-primary text-center">
                Comparendos e infracciones
              </div>
              <div className="text-lg font-semibold text-primary text-center">
                Listas restrictivas
              </div>
              <div className="text-lg font-semibold text-primary text-center">
                RUNT
              </div>
              <div className="text-lg font-semibold text-primary text-center">
                Policia
              </div>
              <div className="text-lg font-semibold text-primary text-center">
                Fosyga
              </div>
              <div className="text-lg font-semibold text-primary text-center">
                RNDC
              </div>
              <div className="text-lg font-semibold text-primary text-center">
                Sofia Plus
              </div>
            </>
          </div>
        </>
      )}
    </div>
  );
};
