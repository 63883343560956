import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

import {
  changeCurrentFilteredRequest,
  changePage,
  getFilteredRequest,
  selectLoadingStatus,
  selectFilteredConsultAlertResponse,
  selectCurrentFilteredConsultAlert,
  setCurrentSolicitud,
} from "./updateDataSlice";

import { ReactComponent as LeftArrow } from "../../../Assets/images/leftArrow.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { FilterButton } from "./components/FilterButton";

const UpdateData = () => {
  let loadingStatus = useAppSelector(selectLoadingStatus);
  let solicitudes = useAppSelector(selectFilteredConsultAlertResponse);
  let filteredRequest = useAppSelector(selectCurrentFilteredConsultAlert);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(getFilteredRequest({ ...filteredRequest }));
    //  dispatch(deleteRequest());
  }, [dispatch, filteredRequest]);

  return (
    <>
      {loadingStatus === "pending" ? (
        <LoadingOverlay
          active={loadingStatus === "pending"}
          spinner={<ClipLoader color="white" size="150px" />}
        ></LoadingOverlay>
      ) : (
        <div className="flex flex-col items-center  w-full h-5/6">
          {/* Header */}
          <div className="flex flex-row h-1/5 w-full items-center justify-evenly">
            <div
              onClick={(e) => {
                e.preventDefault();
                navigate("/services/intro");
              }}
              className="bg-primary-5 flex flex-row items-center justify-start h-11 w-64 rounded-md box-border px-5 cursor-pointer"
            >
              <LeftArrow className="mr-16" />
              <div className="text-primary text-lg font-bold">Regresar</div>
            </div>
            <div className="text-5xl text-primary font-extrabold text-center">
              Actualización de datos
            </div>
            <div></div>
          </div>

          <div className="flex flex-col h-3/5 sm:w-4/5 xl:w-3/5">
          <div className="grid grid-cols-2"> 
          <div className="mb-10 my-5">
              
              <h1 className="text-2xl text-primary font-extrabold text-center">
                {solicitudes &&
                  solicitudes.solicitudes &&
                  solicitudes.solicitudes.length}
              </h1>
              <h1 className="text-2xl text-primary-35 font-extrabold text-center">
                No. de Alertas
              </h1>
            </div>
            <div className="mb-10 my-5">
              
              <h1 className="text-2xl text-primary font-extrabold text-center">
                {solicitudes &&
                  solicitudes.solicitudes &&
                  solicitudes.solicitudes.length}
              </h1>
              <h1 className="text-2xl text-primary-35 font-extrabold text-center">
                Alertas esta semana
              </h1>
            </div> 
                </div>
           

            {/* Tabla con datos de alertas */}
            <div className="flex-grow overflow-auto h-4/5">
              {solicitudes &&
              solicitudes.solicitudes &&
              solicitudes.solicitudes.length > 0 ? (
                <table className="relative w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                        Tipo
                      </th>
                      <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                        Usuario / Vehículo
                      </th>
                      <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                        Lugar
                      </th>
                      <th className="sticky top-0 text-center py-2 text-sm text-primary-35 font-semibold">
                        Estado
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {solicitudes.solicitudes.map((solicitud, i) => {
                      return (
                        <tr
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(setCurrentSolicitud(solicitud));
                            navigate("/services/updateData/detail");
                          }}
                          key={i}
                          className="text-center text-primary text-sm font-semibold cursor-pointer h-7 hover:bg-primary-35"
                        >
                          <td></td>
                          <td>{solicitud.dataEvaluado.tipoEvaluado}</td>
                          <td>
                            {solicitud.dataEvaluado.tipoIdentificacion === "CC"
                              ? solicitud.dataEvaluado.nombres
                              : solicitud.dataEvaluado.tipoIdentificacion ===
                                "NIT"
                              ? solicitud.dataEvaluado.razonSocial
                              : solicitud.dataEvaluado.tipoIdentificacion ===
                                "Placa"
                              ? solicitud.dataEvaluado.identificacion
                              : ""}
                          </td>
                          <td>Bogotá D.C.</td>
                          <td>{solicitud.estado}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <div className="flex flex-col h-3/5 w-full items-center justify-center">
                  <div className="text-3xl text-primary font-semibold text-center">
                    {loadingStatus === "idle"
                      ? "Lista de alertas"
                      : "No se encontraron alertas"}
                  </div>
                </div>
              )}
            </div>

            {/* Paginación */}
            <div className="flex flex-col h-1/5 items-center justify-between">
              <div className="flex flex-row items-center justify-center">
                <div
                  className="flex flex-row font-bold text-primary"
                  style={{ fontSize: "13px" }}
                >
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      if (filteredRequest.page > 0) {
                        dispatch(changePage(filteredRequest.page - 1));
                      }
                    }}
                    className="w-8 px-4 h-8 text-center cursor-pointer text-base"
                  >
                    {"<"}
                  </div>
                  Página {filteredRequest.page + 1} de{" "}
                  {solicitudes?.numPages || 0}
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      if (filteredRequest.page < solicitudes?.numPages! - 1) {
                        dispatch(changePage(filteredRequest.page + 1));
                      }
                    }}
                    className="w-8 px-4 h-8 text-center cursor-pointer text-base"
                  >
                    {">"}
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full items-center justify-evenly">
                <FilterButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      changeCurrentFilteredRequest({
                        ...filteredRequest,
                        tipoEvaluado: "Conductor",
                      })
                    );
                  }}
                  text="Conductor"
                  selected={filteredRequest.tipoEvaluado === "Conductor"}
                />
                <FilterButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      changeCurrentFilteredRequest({
                        ...filteredRequest,
                        tipoEvaluado: "Vehiculo",
                      })
                    );
                  }}
                  text="Vehiculo"
                  selected={filteredRequest.tipoEvaluado === "Vehiculo"}
                />
                <FilterButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      changeCurrentFilteredRequest({
                        ...filteredRequest,
                        tipoEvaluado: "Propietario",
                      })
                    );
                  }}
                  text="Propietario"
                  selected={filteredRequest.tipoEvaluado === "Propietario"}
                />
                <FilterButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      changeCurrentFilteredRequest({
                        ...filteredRequest,
                        tipoEvaluado: null,
                      })
                    );
                  }}
                  text="Todas"
                  selected={
                    !filteredRequest.tipoEvaluado ||
                    filteredRequest.tipoEvaluado === ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="h-1/5 sm:w-4/5 xl:w-3/5"></div>
        </div>
      )}
    </>
  );
};

export default UpdateData;
