import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../login/loginSlice";
import { FosygaData } from "./EvalComponents/FosygaResult";
import { LicenciaSupendida } from "./EvalComponents/LicenciaSuspendida";
import { Lista } from "./EvalComponents/ListaRestrictiva";
import { MultasInfracciones } from "./EvalComponents/MultaResult";
import { AntecedentesPolicia } from "./EvalComponents/PoliciaResult";
import { ProcesosUnificados } from "./EvalComponents/ProcesoUnificado";
import { Procuraduria } from "./EvalComponents/Procuraduria";
import { RamaJudicial } from "./EvalComponents/RamaJudicial";
import { RNDCItem } from "./EvalComponents/RNDCResult";
import { RuntPersonaResult } from "./EvalComponents/RuntPersona";
import { RuntPlacaResult } from "./EvalComponents/RuntPlaca";
import { SofiaPlus } from "./EvalComponents/SofiaPlus";
import {
  DownloadedFile,
  downloadFileAPI,
  FilteredRequest,
  FilteredResponse,
  getConsultationByRequestId,
  getFilteredRequestsAPI,
  getFosygaAPI,
  getLicenciasSuspendidasAPI,
  getMultasAPI,
  getOFACAPI,
  getONUAPI,
  getPDFData,
  getPoliciaAPI,
  getProcesosAPI,
  getProcuraduriaAPI,
  getRamaAPI,
  getRequestByIdAPI,
  getResultDataAPI,
  getRndcPersonaAPI,
  getRndcPlacaAPI,
  getRuntPersonaAPI,
  getRuntPlacaAPI,
  getSofiaPlusAPI,
  getUEAPI,
  getUserByIdAPI,
  reactivateRequestAPI,
  registerConsultationAPI,
  RegisterConsultationResponse,
  RegistrationRequest,
  Request,
  ResultConsultation,
  ResultData,
  RNDCPersonaRequest,
  RNDCPlacaRequest,
  saveResultAPI,
  SaveResultInfo,
  updateRequestStudyAPI,
} from "./securityEvaluationAPI";

export interface SendAutomaticData {
  idEvaluado: string;
  tipoEvaluado: string;
}

export interface SecurityEvaluationState {
  loadingRequestStatus: LoadingStatus;
  loadingOneRequestStatus: LoadingStatus;
  loadingSave: LoadingStatus;
  loadingRequestConsultation: LoadingStatus;
  loadingResultData: LoadingStatus;
  loadingDownloadFile: LoadingStatus;
  selectedElement:
    | "Conductor"
    | "Propietario"
    | "Proveedor"
    | "Funcionario"
    | "Vehiculo"
    | "Remolque";
  errorMessage: string;
  currentFilteredRequest: FilteredRequest;
  currentFilteredResponse: FilteredResponse;
  currentRequest: Request | null;
  currentRegistration: RegisterConsultationResponse | null;
  currentConsultationRequest: ResultConsultation | null;
  currentResultData: ResultData | null;
  driverResult: {
    procuraduria: Procuraduria | null;
    procuraduriaLoading: LoadingStatus;
    fosyga: FosygaData | null;
    fosygaLoading: LoadingStatus;
    licenciaSuspendida: LicenciaSupendida | null;
    licenciaLoading: LoadingStatus;
    ofac: Lista | null;
    loadingOfac: LoadingStatus;
    onu: Lista | null;
    loadingOnu: LoadingStatus;
    ue: Lista | null;
    loadingUE: LoadingStatus;
    policia: AntecedentesPolicia | null;
    loadingPolicia: LoadingStatus;
    ramaJudicial: RamaJudicial | null;
    loadingRama: LoadingStatus;
    procesos: ProcesosUnificados | null;
    loadingProcesos: LoadingStatus;
    multas: MultasInfracciones | null;
    multasLoading: LoadingStatus;
    runtPersona: RuntPersonaResult | null;
    loadingRuntPersona: LoadingStatus;
    sofiaPlus: SofiaPlus | null;
    loadingSofiaPlus: LoadingStatus;
    rndc: Array<RNDCItem>;
    loadingRndc: LoadingStatus;
  };
  ownerResult: {
    procuraduria: Procuraduria | null;
    procuraduriaLoading: LoadingStatus;
    ramaJudicial: RamaJudicial | null;
    loadingRama: LoadingStatus;
    procesos: ProcesosUnificados | null;
    loadingProcesos: LoadingStatus;
    ofac: Lista | null;
    loadingOfac: LoadingStatus;
    onu: Lista | null;
    loadingOnu: LoadingStatus;
    ue: Lista | null;
    loadingUE: LoadingStatus;
    policia: AntecedentesPolicia | null;
    loadingPolicia: LoadingStatus;
  };
  providerResult: {
    procuraduria: Procuraduria | null;
    procuraduriaLoading: LoadingStatus;
    ramaJudicial: RamaJudicial | null;
    loadingRama: LoadingStatus;
    procesos: ProcesosUnificados | null;
    loadingProcesos: LoadingStatus;
    ofac: Lista | null;
    loadingOfac: LoadingStatus;
    onu: Lista | null;
    loadingOnu: LoadingStatus;
    ue: Lista | null;
    loadingUE: LoadingStatus;
  };
  officialResult: {
    procuraduria: Procuraduria | null;
    procuraduriaLoading: LoadingStatus;
    ramaJudicial: RamaJudicial | null;
    loadingRama: LoadingStatus;
    procesos: ProcesosUnificados | null;
    loadingProcesos: LoadingStatus;
    ofac: Lista | null;
    loadingOfac: LoadingStatus;
    onu: Lista | null;
    loadingOnu: LoadingStatus;
    ue: Lista | null;
    loadingUE: LoadingStatus;
    policia: AntecedentesPolicia | null;
    loadingPolicia: LoadingStatus;
  };
  vehicleResult: {
    runt: RuntPlacaResult | null;
    runtLoading: LoadingStatus;
    rndc: Array<RNDCItem>;
    rndcLoading: LoadingStatus;
  };
  trailerResult: {
    runt: RuntPlacaResult | null;
    runtLoading: LoadingStatus;
    rndc: Array<RNDCItem>;
    rndcLoading: LoadingStatus;
  };
  currentDownloadedFile: DownloadedFile | null;
  loadingreActivate: LoadingStatus;
}

const initialState: SecurityEvaluationState = {
  loadingreActivate: "idle",
  loadingRequestStatus: "idle",
  loadingOneRequestStatus: "idle",
  loadingSave: "idle",
  loadingRequestConsultation: "idle",
  loadingResultData: "idle",
  loadingDownloadFile: "idle",
  errorMessage: "",
  selectedElement: "Conductor",
  currentFilteredRequest: { page: 0, status: null, type: "transportes" },
  currentFilteredResponse: { numPages: 0, requests: [] },
  currentRequest: null,
  currentRegistration: null,
  currentResultData: null,
  currentConsultationRequest: null,
  driverResult: {
    fosyga: null,
    fosygaLoading: "idle",
    procuraduria: null,
    procuraduriaLoading: "idle",
    licenciaLoading: "idle",
    licenciaSuspendida: null,
    loadingOfac: "idle",
    loadingOnu: "idle",
    loadingPolicia: "idle",
    loadingProcesos: "idle",
    loadingRama: "idle",
    loadingRuntPersona: "idle",
    loadingUE: "idle",
    multas: null,
    multasLoading: "idle",
    ofac: null,
    onu: null,
    policia: null,
    procesos: null,
    ramaJudicial: null,
    runtPersona: null,
    ue: null,
    loadingRndc: "idle",
    loadingSofiaPlus: "idle",
    rndc: [],
    sofiaPlus: null,
  },
  officialResult: {
    loadingOfac: "idle",
    loadingOnu: "idle",
    loadingPolicia: "idle",
    loadingProcesos: "idle",
    loadingRama: "idle",
    loadingUE: "idle",
    ofac: null,
    onu: null,
    policia: null,
    procesos: null,
    procuraduria: null,
    procuraduriaLoading: "idle",
    ramaJudicial: null,
    ue: null,
  },
  ownerResult: {
    loadingOfac: "idle",
    loadingOnu: "idle",
    loadingPolicia: "idle",
    loadingProcesos: "idle",
    loadingRama: "idle",
    loadingUE: "idle",
    ofac: null,
    onu: null,
    policia: null,
    procesos: null,
    procuraduria: null,
    procuraduriaLoading: "idle",
    ramaJudicial: null,
    ue: null,
  },
  providerResult: {
    loadingOfac: "idle",
    loadingOnu: "idle",
    loadingProcesos: "idle",
    loadingRama: "idle",
    loadingUE: "idle",
    ofac: null,
    onu: null,
    procesos: null,
    procuraduria: null,
    procuraduriaLoading: "idle",
    ramaJudicial: null,
    ue: null,
  },
  trailerResult: {
    runt: null,
    runtLoading: "idle",
    rndc: [],
    rndcLoading: "idle",
  },
  vehicleResult: {
    rndc: [],
    rndcLoading: "idle",
    runt: null,
    runtLoading: "idle",
  },
  currentDownloadedFile: null,
};

export const getFilteredRequest = createAsyncThunk(
  "securityEvaluation/getFilteredRequest",
  async (filteredRequest: FilteredRequest, { rejectWithValue }) => {
    try {
      const responseRequest = await getFilteredRequestsAPI(filteredRequest);
      if (responseRequest.error === "") {
        return responseRequest.requests;
      } else {
        return rejectWithValue(responseRequest.error);
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getRequestById = createAsyncThunk(
  "securityEvaluation/getRequestById",
  async (id: string, { rejectWithValue }) => {
    let responseRequest = await getRequestByIdAPI(id);
    if (responseRequest) {
      try {
        const responseUser = await getUserByIdAPI(responseRequest.userId);
        responseRequest.user = responseUser!;
      } catch {}
      return responseRequest;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const getRequestConsultation = createAsyncThunk(
  "securityEvaluation/getRequestConsultation",
  async (id: string) => {
    let requestConsultation = await getConsultationByRequestId(id);

    return requestConsultation;
  }
);

export const generatePDFFromId = createAsyncThunk(
  "securityEvaluation/generatePDF",
  async (id: string) => {
    let downloaded = await getPDFData(id);
    return downloaded;
  }
);

export const getResultData = createAsyncThunk(
  "securityEvaluation/getResultData",
  async (id: string) => {
    let requestConsultation = await getResultDataAPI(id);

    return requestConsultation;
  }
);

export const registerTransportes = createAsyncThunk(
  "securityEvaluation/registerTransportes",
  async (_, { getState, rejectWithValue }) => {
    let state = getState() as RootState;
    let req: RegistrationRequest = {
      identificacionConductor:
        state.securityEvaluation.currentRequest?.driverIdentification!,
      identificacionPropietario:
        state.securityEvaluation.currentRequest?.ownerIdentification!,
      placaCabezote: state.securityEvaluation.currentRequest?.placaVehicle!,
      placaTrailer: state.securityEvaluation.currentRequest?.placaTrailer!,
      tipoIdentificacionConductor:
        state.securityEvaluation.currentRequest?.driverIdType!,
      tipoIdentificacionPropietario:
        state.securityEvaluation.currentRequest?.ownerIdType!,
      identificacionOfficial:
        state.securityEvaluation.currentRequest?.officialIdentification!,
      tipoIdentificacionOfficial:
        state.securityEvaluation.currentRequest?.officialIdType!,
      identificacionProvider:
        state.securityEvaluation.currentRequest?.providerNit!,
      tipoIdentificacionProvider: "nit",
      type: state.securityEvaluation.currentRequest?.type!,
      trailerOwnerIdentification:
        state.securityEvaluation.currentRequest?.trailerOwnerIdentification!,
    };
    let responseRequest = await registerConsultationAPI(req);
    if (responseRequest) {
      return responseRequest;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const getFosyga = createAsyncThunk(
  "securityEvaluation/getFosyga",
  async (req: SourceRequest) => {
    let response = await getFosygaAPI(req.id!);

    return { response, type: req.type };
  }
);

export const getLicenciasSuspendidas = createAsyncThunk(
  "securityEvaluation/getLicenciasSuspendidas",
  async (req: SourceRequest) => {
    let response = await getLicenciasSuspendidasAPI(req.id!);

    return { response, type: req.type };
  }
);

export const getOFAC = createAsyncThunk(
  "securityEvaluation/getOFAC",
  async (req: SourceRequest) => {
    let response = await getOFACAPI(req.id!);

    return { response, type: req.type };
  }
);

export const getONU = createAsyncThunk(
  "securityEvaluation/getONU",
  async (req: SourceRequest) => {
    let response = await getONUAPI(req.id!);

    return { response, type: req.type };
  }
);

export const getUE = createAsyncThunk(
  "securityEvaluation/getUE",
  async (req: SourceRequest) => {
    let response = await getUEAPI(req.id!);

    return { response, type: req.type };
  }
);

export const getPolicia = createAsyncThunk(
  "securityEvaluation/getPolicia",
  async (req: SourceRequest) => {
    let response = await getPoliciaAPI(req.id!);

    return { response, type: req.type };
  }
);

export const getRama = createAsyncThunk(
  "securityEvaluation/getRama",
  async (req: SourceRequest) => {
    let response = await getRamaAPI(req.id!);

    return { response, type: req.type };
  }
);

export const getProcesos = createAsyncThunk(
  "securityEvaluation/getProcesos",
  async (req: SourceRequest) => {
    let response = await getProcesosAPI(req.id!);

    return { response, type: req.type };
  }
);

export const getMultas = createAsyncThunk(
  "securityEvaluation/getMultas",
  async (req: SourceRequest) => {
    let response = await getMultasAPI(req.id!);

    return { response, type: req.type };
  }
);

export const getProcuraduria = createAsyncThunk(
  "securityEvaluation/getProcuraduria",
  async (req: SourceRequest) => {
    let response = await getProcuraduriaAPI(req.id!);

    return { response, type: req.type };
  }
);

export const getRuntPersona = createAsyncThunk(
  "securityEvaluation/getRuntPersona",
  async (req: SourceRequest) => {
    let response = await getRuntPersonaAPI(req.id!);

    return { response, type: req.type };
  }
);

export const getRuntPlaca = createAsyncThunk(
  "securityEvaluation/getRuntPlaca",
  async (req: SourceRequest) => {
    let response = await getRuntPlacaAPI(req.id!);

    return { response, type: req.type };
  }
);

export const getRuntPlacaTrailer = createAsyncThunk(
  "securityEvaluation/getRuntPlacaTrailer",
  async (req: SourceRequest) => {
    let response = await getRuntPlacaAPI(req.id!);

    return { response, type: req.type };
  }
);

export const getSofiaPlus = createAsyncThunk(
  "securityEvaluation/getSofiaPlus",
  async (req: SourceRequest) => {
    let response = await getSofiaPlusAPI(req.id!);

    return { response, type: req.type };
  }
);

export const getRNDCPersona = createAsyncThunk(
  "securityEvaluation/getRndcPersona",
  async (req: SourceRequest) => {
    let response = await getRndcPersonaAPI(req.reqRndcPersona!);

    return { response, type: req.type };
  }
);

export const getRndcPlaca = createAsyncThunk(
  "securityEvaluation/getRndcPlaca",
  async (req: SourceRequest) => {
    let response = await getRndcPlacaAPI(req.reqRndcPlaca!);

    return { response, type: req.type };
  }
);

export const getRndcPlacaTrailer = createAsyncThunk(
  "securityEvaluation/getRndcPlacaTrailer",
  async (req: SourceRequest) => {
    let response = await getRndcPlacaAPI(req.reqRndcPlaca!);

    return { response, type: req.type };
  }
);

export const reactivateRequest = createAsyncThunk(
  "securityEvaluation/reactivate",
  async (id: string, { rejectWithValue }) => {
    let request = await reactivateRequestAPI(id);
    if (request) {
      return true;
    } else {
      rejectWithValue("Error activating element");
    }
  }
);

export const saveResult = createAsyncThunk(
  "securityEvaluation/saveResult",
  async (req: SaveResultInfo, { getState }) => {
    let currentState = getState() as RootState;
    let registration = currentState.securityEvaluation.currentRegistration;
    let requestOld = currentState.securityEvaluation.currentRequest!;
    let request: Request = {
      clientId: requestOld.clientId,
      comments: req.comments,
      creationDate: requestOld.creationDate,
      driverIdentification: requestOld.driverIdentification,
      driverIdType: requestOld.driverIdType,
      fileNames: requestOld.fileNames,
      id: requestOld.id,
      observations: req.observations,
      officialIdentification: requestOld.officialIdentification,
      officialIdType: requestOld.officialIdType,
      officialPosition: requestOld.officialPosition,
      ownerIdentification: requestOld.ownerIdentification,
      ownerIdType: requestOld.ownerIdType,
      trailerOwnerIdentification: requestOld.trailerOwnerIdentification,
      placaTrailer: requestOld.placaTrailer,
      placaVehicle: requestOld.placaVehicle,
      providerIdentification: requestOld.providerIdentification,
      providerNit: requestOld.providerNit,
      status: req.status,
      type: requestOld.type,
      user: requestOld.user,
      userId: requestOld.userId,
      vehicleType: requestOld.vehicleType,
    };
    try {
      if (request?.type === "transportes") {
        if (request.driverIdentification) {
          let resultOld = currentState.securityEvaluation.driverResult;
          let resultNew: ResultData = {
            fosyga: resultOld.fosyga,
            licenciasSuspendidas: resultOld.licenciaSuspendida,
            multas: resultOld.multas,
            ofac: resultOld.ofac,
            onu: resultOld.onu,
            policia: resultOld.policia,
            procesosUnificados: resultOld.procesos,
            procuraduria: resultOld.procuraduria,
            ramaJudicial: resultOld.ramaJudicial,
            rndcCedula: resultOld.rndc,
            rndcPlaca: null,
            runtPersona: resultOld.runtPersona,
            runtPlaca: null,
            sofiaPlus: resultOld.sofiaPlus,
            ue: resultOld.ue,
          };

          await saveResultAPI({
            identifier: registration?.identifierPersona.toString()!,
            result: resultNew,
          });
        }
        if (request.ownerIdentification) {
          let resultOld = currentState.securityEvaluation.ownerResult;
          let resultNew: ResultData = {
            fosyga: null,
            licenciasSuspendidas: null,
            multas: null,
            ofac: resultOld.ofac,
            onu: resultOld.onu,
            policia: resultOld.policia,
            procesosUnificados: resultOld.procesos,
            procuraduria: resultOld.procuraduria,
            ramaJudicial: resultOld.ramaJudicial,
            rndcCedula: null,
            rndcPlaca: null,
            runtPersona: null,
            runtPlaca: null,
            sofiaPlus: null,
            ue: resultOld.ue,
          };

          await saveResultAPI({
            identifier: registration?.identifierPropietario.toString()!,
            result: resultNew,
          });
        }

        if (request.placaVehicle) {
          let resultOld = currentState.securityEvaluation.vehicleResult;
          let resultNew: ResultData = {
            fosyga: null,
            licenciasSuspendidas: null,
            multas: null,
            ofac: null,
            onu: null,
            policia: null,
            procesosUnificados: null,
            procuraduria: null,
            ramaJudicial: null,
            rndcCedula: null,
            rndcPlaca: resultOld.rndc,
            runtPersona: null,
            runtPlaca: resultOld.runt,
            sofiaPlus: null,
            ue: null,
          };

          await saveResultAPI({
            identifier: registration?.identifierVehiculo.toString()!,
            result: resultNew,
          });
        }
        if (request.placaTrailer && registration?.identifierTrailer) {
          let resultOld = currentState.securityEvaluation.trailerResult;
          let resultNew: ResultData = {
            fosyga: null,
            licenciasSuspendidas: null,
            multas: null,
            ofac: null,
            onu: null,
            policia: null,
            procesosUnificados: null,
            procuraduria: null,
            ramaJudicial: null,
            rndcCedula: null,
            rndcPlaca: resultOld.rndc,
            runtPersona: null,
            runtPlaca: resultOld.runt,
            sofiaPlus: null,
            ue: null,
          };

          await saveResultAPI({
            identifier: registration?.identifierTrailer.toString()!,
            result: resultNew,
          });
        }
      } else if (request?.type === "funcionarios") {
        if (request.officialIdentification) {
          let resultOld = currentState.securityEvaluation.officialResult;
          let resultNew: ResultData = {
            fosyga: null,
            licenciasSuspendidas: null,
            multas: null,
            ofac: resultOld.ofac,
            onu: resultOld.onu,
            policia: resultOld.policia,
            procesosUnificados: resultOld.procesos,
            procuraduria: resultOld.procuraduria,
            ramaJudicial: resultOld.ramaJudicial,
            rndcCedula: null,
            rndcPlaca: null,
            runtPersona: null,
            runtPlaca: null,
            sofiaPlus: null,
            ue: resultOld.ue,
          };

          await saveResultAPI({
            identifier: registration?.identifierOfficial.toString()!,
            result: resultNew,
          });
        }
      } else if (request?.type === "clients") {
        if (request.providerNit) {
          let resultOld = currentState.securityEvaluation.providerResult;
          let resultNew: ResultData = {
            fosyga: null,
            licenciasSuspendidas: null,
            multas: null,
            ofac: resultOld.ofac,
            onu: resultOld.onu,
            policia: null,
            procesosUnificados: resultOld.procesos,
            procuraduria: resultOld.procuraduria,
            ramaJudicial: resultOld.ramaJudicial,
            rndcCedula: null,
            rndcPlaca: null,
            runtPersona: null,
            runtPlaca: null,
            sofiaPlus: null,
            ue: resultOld.ue,
          };

          await saveResultAPI({
            identifier: registration?.identifierProvider.toString()!,
            result: resultNew,
          });
        }
      }

      let response = await updateRequestStudyAPI({
        registration: registration!,
        request: request!,
        idRequest: request.id!,
      });

      return response;
    } catch {
      return false;
    }
  }
);

export const downloadFile = createAsyncThunk(
  "securityEvaluation/downloadFile",
  async (id: string) => {
    let requestConsultation = await downloadFileAPI(id);

    return requestConsultation;
  }
);

export const securityEvaluationSlice = createSlice({
  name: "securityEvaluationSlice",
  initialState,
  reducers: {
    changePage: (state, action: PayloadAction<number>) => {
      state.currentFilteredRequest = {
        ...state.currentFilteredRequest,
        page: action.payload,
      };
    },
    resetReactivate: (state) => {
      state.loadingreActivate = "idle";
      state.currentRequest = null;
    },
    changeCurrentFilteredRequest: (
      state,
      action: PayloadAction<FilteredRequest>
    ) => {
      state.currentFilteredRequest = { ...action.payload, page: 0 };
    },
    resetState: (state) => {
      state.currentFilteredRequest = {
        page: 0,
        status: null,
        type: "transportes",
      };
      state.currentRequest = null;
      state.currentFilteredResponse = { numPages: 0, requests: [] };
    },
    resetDetailRequest: (state) => {
      state.currentRequest = null;
      state.loadingOneRequestStatus = "idle";
    },
    resetDetailData: (state) => {
      state.currentRequest = null;
      state.loadingOneRequestStatus = "idle";
      state.currentConsultationRequest = null;
      state.currentDownloadedFile = null;
      state.currentRegistration = null;
      state.currentRequest = null;
      state.currentResultData = null;
      state.driverResult = {
        fosyga: null,
        fosygaLoading: "idle",
        procuraduria: null,
        procuraduriaLoading: "idle",
        licenciaLoading: "idle",
        licenciaSuspendida: null,
        loadingOfac: "idle",
        loadingOnu: "idle",
        loadingPolicia: "idle",
        loadingProcesos: "idle",
        loadingRama: "idle",
        loadingRuntPersona: "idle",
        loadingUE: "idle",
        multas: null,
        multasLoading: "idle",
        ofac: null,
        onu: null,
        policia: null,
        procesos: null,
        ramaJudicial: null,
        runtPersona: null,
        ue: null,
        loadingRndc: "idle",
        loadingSofiaPlus: "idle",
        rndc: [],
        sofiaPlus: null,
      };
      state.errorMessage = "";
      state.loadingOneRequestStatus = "idle";
      state.loadingRequestConsultation = "idle";
      state.loadingRequestStatus = "idle";
      state.loadingResultData = "idle";
      state.loadingSave = "idle";
      state.officialResult = {
        loadingOfac: "idle",
        loadingOnu: "idle",
        loadingPolicia: "idle",
        loadingProcesos: "idle",
        loadingRama: "idle",
        loadingUE: "idle",
        ofac: null,
        onu: null,
        policia: null,
        procesos: null,
        procuraduria: null,
        procuraduriaLoading: "idle",
        ramaJudicial: null,
        ue: null,
      };
      state.ownerResult = {
        loadingOfac: "idle",
        loadingOnu: "idle",
        loadingPolicia: "idle",
        loadingProcesos: "idle",
        loadingRama: "idle",
        loadingUE: "idle",
        ofac: null,
        onu: null,
        policia: null,
        procesos: null,
        procuraduria: null,
        procuraduriaLoading: "idle",
        ramaJudicial: null,
        ue: null,
      };
      state.providerResult = {
        loadingOfac: "idle",
        loadingOnu: "idle",
        loadingProcesos: "idle",
        loadingRama: "idle",
        loadingUE: "idle",
        ofac: null,
        onu: null,
        procesos: null,
        procuraduria: null,
        procuraduriaLoading: "idle",
        ramaJudicial: null,
        ue: null,
      };
      state.trailerResult = {
        runt: null,
        runtLoading: "idle",
        rndc: [],
        rndcLoading: "idle",
      };
      state.vehicleResult = {
        rndc: [],
        rndcLoading: "idle",
        runt: null,
        runtLoading: "idle",
      };
    },
    setElementSelected: (
      state,
      action: PayloadAction<
        | "Conductor"
        | "Propietario"
        | "Proveedor"
        | "Funcionario"
        | "Vehiculo"
        | "Remolque"
      >
    ) => {
      state.selectedElement = action.payload;
    },
    deleteCurrentDownloadedFile: (state) => {
      state.currentDownloadedFile = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(reactivateRequest.fulfilled, (state) => {
        state.loadingreActivate = "resolved";
      })
      .addCase(reactivateRequest.pending, (state) => {
        state.loadingreActivate = "pending";
      })
      .addCase(reactivateRequest.rejected, (state) => {
        state.loadingreActivate = "rejected";
      })
      .addCase(getFilteredRequest.fulfilled, (state, action) => {
        let request = action.payload;
        if (request) {
          state.currentFilteredResponse = request;
          state.loadingRequestStatus = "resolved";
        } else {
          state.loadingRequestStatus = "rejected";
        }
      })
      .addCase(getFilteredRequest.pending, (state) => {
        state.loadingRequestStatus = "pending";
      })
      .addCase(getFilteredRequest.rejected, (state, action) => {
        state.errorMessage = action.payload as string;
        state.loadingRequestStatus = "rejected";
      })
      .addCase(getRequestById.fulfilled, (state, action) => {
        let request = action.payload;
        if (request) {
          state.currentRequest = request;
          state.loadingOneRequestStatus = "resolved";
        } else {
          state.loadingOneRequestStatus = "rejected";
        }
      })
      .addCase(getRequestById.pending, (state) => {
        state.loadingOneRequestStatus = "pending";
      })
      .addCase(getRequestById.rejected, (state) => {
        state.errorMessage = "No fue encontrada la consulta";
        state.loadingOneRequestStatus = "rejected";
      })
      .addCase(registerTransportes.fulfilled, (state, action) => {
        let registration = action.payload;
        if (registration) {
          state.currentRegistration = registration;
          state.loadingOneRequestStatus = "resolved";
        } else {
          state.loadingOneRequestStatus = "rejected";
        }
      })
      .addCase(registerTransportes.pending, (state) => {
        state.loadingOneRequestStatus = "pending";
      })
      .addCase(registerTransportes.rejected, (state) => {
        state.errorMessage = "No fue encontrada la consulta";
        state.loadingOneRequestStatus = "rejected";
      })
      .addCase(getFosyga.fulfilled, (state, action) => {
        let result = action.payload;
        switch (result.type) {
          case "driver":
            if (result) {
              state.driverResult = {
                ...state.driverResult,
                fosyga: result.response,
                fosygaLoading: "resolved",
              };
            } else {
              state.driverResult = {
                ...state.driverResult,
                fosyga: null,
                fosygaLoading: "rejected",
              };
            }
            break;

          default:
            break;
        }
      })
      .addCase(getFosyga.pending, (state, action) => {
        switch (action.meta.arg.type) {
          case "driver":
            state.driverResult = {
              ...state.driverResult,
              fosygaLoading: "pending",
            };
            break;

          default:
            break;
        }
      })
      .addCase(getLicenciasSuspendidas.fulfilled, (state, action) => {
        let result = action.payload;
        switch (result.type) {
          case "driver":
            if (result) {
              state.driverResult = {
                ...state.driverResult,
                licenciaSuspendida: result.response,
                licenciaLoading: "resolved",
              };
            } else {
              state.driverResult = {
                ...state.driverResult,
                licenciaSuspendida: null,
                licenciaLoading: "rejected",
              };
            }
            break;

          default:
            break;
        }
      })
      .addCase(getLicenciasSuspendidas.pending, (state, action) => {
        switch (action.meta.arg.type) {
          case "driver":
            state.driverResult = {
              ...state.driverResult,
              licenciaLoading: "pending",
            };
            break;

          default:
            break;
        }
      })
      .addCase(getOFAC.fulfilled, (state, action) => {
        let result = action.payload;
        switch (result.type) {
          case "driver":
            if (result) {
              state.driverResult = {
                ...state.driverResult,
                ofac: result.response,
                loadingOfac: "resolved",
              };
            } else {
              state.driverResult = {
                ...state.driverResult,
                ofac: null,
                loadingOfac: "rejected",
              };
            }
            break;
          case "official":
            if (result) {
              state.officialResult = {
                ...state.officialResult,
                ofac: result.response,
                loadingOfac: "resolved",
              };
            } else {
              state.officialResult = {
                ...state.officialResult,
                ofac: null,
                loadingOfac: "rejected",
              };
            }
            break;
          case "owner":
            if (result) {
              state.ownerResult = {
                ...state.ownerResult,
                ofac: result.response,
                loadingOfac: "resolved",
              };
            } else {
              state.ownerResult = {
                ...state.ownerResult,
                ofac: null,
                loadingOfac: "rejected",
              };
            }
            break;
          case "provider":
            if (result) {
              state.providerResult = {
                ...state.providerResult,
                ofac: result.response,
                loadingOfac: "resolved",
              };
            } else {
              state.providerResult = {
                ...state.providerResult,
                ofac: null,
                loadingOfac: "rejected",
              };
            }
            break;
          default:
            break;
        }
      })
      .addCase(getOFAC.pending, (state, action) => {
        switch (action.meta.arg.type) {
          case "driver":
            state.driverResult = {
              ...state.driverResult,
              loadingOfac: "pending",
            };
            break;
          case "official":
            state.officialResult = {
              ...state.officialResult,
              loadingOfac: "pending",
            };
            break;
          case "owner":
            state.ownerResult = {
              ...state.ownerResult,
              loadingOfac: "pending",
            };
            break;
          case "provider":
            state.providerResult = {
              ...state.providerResult,
              loadingOfac: "pending",
            };
            break;
          default:
            break;
        }
      })
      .addCase(getONU.fulfilled, (state, action) => {
        let result = action.payload;
        switch (result.type) {
          case "driver":
            if (result) {
              state.driverResult = {
                ...state.driverResult,
                onu: result.response,
                loadingOnu: "resolved",
              };
            } else {
              state.driverResult = {
                ...state.driverResult,
                onu: null,
                loadingOnu: "rejected",
              };
            }
            break;
          case "official":
            if (result) {
              state.officialResult = {
                ...state.officialResult,
                onu: result.response,
                loadingOnu: "resolved",
              };
            } else {
              state.officialResult = {
                ...state.officialResult,
                onu: null,
                loadingOnu: "rejected",
              };
            }
            break;
          case "owner":
            if (result) {
              state.ownerResult = {
                ...state.ownerResult,
                onu: result.response,
                loadingOnu: "resolved",
              };
            } else {
              state.ownerResult = {
                ...state.ownerResult,
                onu: null,
                loadingOnu: "rejected",
              };
            }
            break;
          case "provider":
            if (result) {
              state.providerResult = {
                ...state.providerResult,
                onu: result.response,
                loadingOnu: "resolved",
              };
            } else {
              state.providerResult = {
                ...state.providerResult,
                onu: null,
                loadingOnu: "rejected",
              };
            }
            break;
          default:
            break;
        }
      })
      .addCase(getONU.pending, (state, action) => {
        switch (action.meta.arg.type) {
          case "driver":
            state.driverResult = {
              ...state.driverResult,
              loadingOnu: "pending",
            };
            break;
          case "official":
            state.officialResult = {
              ...state.officialResult,
              loadingOnu: "pending",
            };
            break;
          case "owner":
            state.ownerResult = {
              ...state.ownerResult,
              loadingOnu: "pending",
            };
            break;
          case "provider":
            state.providerResult = {
              ...state.providerResult,
              loadingOnu: "pending",
            };
            break;
          default:
            break;
        }
      })
      .addCase(getUE.fulfilled, (state, action) => {
        let result = action.payload;
        switch (result.type) {
          case "driver":
            if (result) {
              state.driverResult = {
                ...state.driverResult,
                ue: result.response,
                loadingUE: "resolved",
              };
            } else {
              state.driverResult = {
                ...state.driverResult,
                ue: null,
                loadingUE: "rejected",
              };
            }
            break;
          case "official":
            if (result) {
              state.officialResult = {
                ...state.officialResult,
                ue: result.response,
                loadingUE: "resolved",
              };
            } else {
              state.officialResult = {
                ...state.officialResult,
                ue: null,
                loadingUE: "rejected",
              };
            }
            break;
          case "owner":
            if (result) {
              state.ownerResult = {
                ...state.ownerResult,
                ue: result.response,
                loadingUE: "resolved",
              };
            } else {
              state.ownerResult = {
                ...state.ownerResult,
                ue: null,
                loadingUE: "rejected",
              };
            }
            break;
          case "provider":
            if (result) {
              state.providerResult = {
                ...state.providerResult,
                ue: result.response,
                loadingUE: "resolved",
              };
            } else {
              state.providerResult = {
                ...state.providerResult,
                ue: null,
                loadingUE: "rejected",
              };
            }
            break;
          default:
            break;
        }
      })
      .addCase(getUE.pending, (state, action) => {
        switch (action.meta.arg.type) {
          case "driver":
            state.driverResult = {
              ...state.driverResult,
              loadingUE: "pending",
            };
            break;
          case "official":
            state.officialResult = {
              ...state.officialResult,
              loadingUE: "pending",
            };
            break;
          case "owner":
            state.ownerResult = {
              ...state.ownerResult,
              loadingUE: "pending",
            };
            break;
          case "provider":
            state.providerResult = {
              ...state.providerResult,
              loadingUE: "pending",
            };
            break;
          default:
            break;
        }
      })
      .addCase(getPolicia.fulfilled, (state, action) => {
        let result = action.payload;
        switch (result.type) {
          case "driver":
            if (result) {
              state.driverResult = {
                ...state.driverResult,
                policia: result.response,
                loadingPolicia: "resolved",
              };
            } else {
              state.driverResult = {
                ...state.driverResult,
                policia: null,
                loadingPolicia: "rejected",
              };
            }
            break;
          case "official":
            if (result) {
              state.officialResult = {
                ...state.officialResult,
                policia: result.response,
                loadingPolicia: "resolved",
              };
            } else {
              state.officialResult = {
                ...state.officialResult,
                policia: null,
                loadingPolicia: "rejected",
              };
            }
            break;
          case "owner":
            if (result) {
              state.ownerResult = {
                ...state.ownerResult,
                policia: result.response,
                loadingPolicia: "resolved",
              };
            } else {
              state.ownerResult = {
                ...state.ownerResult,
                policia: null,
                loadingPolicia: "rejected",
              };
            }
            break;
          default:
            break;
        }
      })
      .addCase(getPolicia.pending, (state, action) => {
        switch (action.meta.arg.type) {
          case "driver":
            state.driverResult = {
              ...state.driverResult,
              loadingPolicia: "pending",
            };
            break;
          case "official":
            state.officialResult = {
              ...state.officialResult,
              loadingPolicia: "pending",
            };
            break;
          case "owner":
            state.ownerResult = {
              ...state.ownerResult,
              loadingPolicia: "pending",
            };
            break;
          default:
            break;
        }
      })
      .addCase(getRama.fulfilled, (state, action) => {
        let result = action.payload;
        switch (result.type) {
          case "driver":
            if (result) {
              state.driverResult = {
                ...state.driverResult,
                ramaJudicial: result.response,
                loadingRama: "resolved",
              };
            } else {
              state.driverResult = {
                ...state.driverResult,
                ramaJudicial: null,
                loadingRama: "rejected",
              };
            }
            break;
          case "official":
            if (result) {
              state.officialResult = {
                ...state.officialResult,
                ramaJudicial: result.response,
                loadingRama: "resolved",
              };
            } else {
              state.officialResult = {
                ...state.officialResult,
                ramaJudicial: null,
                loadingRama: "rejected",
              };
            }
            break;
          case "owner":
            if (result) {
              state.ownerResult = {
                ...state.ownerResult,
                ramaJudicial: result.response,
                loadingRama: "resolved",
              };
            } else {
              state.ownerResult = {
                ...state.ownerResult,
                ramaJudicial: null,
                loadingRama: "rejected",
              };
            }
            break;
          case "provider":
            if (result) {
              state.providerResult = {
                ...state.providerResult,
                ramaJudicial: result.response,
                loadingRama: "resolved",
              };
            } else {
              state.providerResult = {
                ...state.providerResult,
                ramaJudicial: null,
                loadingRama: "rejected",
              };
            }
            break;
          default:
            break;
        }
      })
      .addCase(getRama.pending, (state, action) => {
        switch (action.meta.arg.type) {
          case "driver":
            state.driverResult = {
              ...state.driverResult,
              loadingRama: "pending",
            };
            break;
          case "official":
            state.officialResult = {
              ...state.officialResult,
              loadingRama: "pending",
            };
            break;
          case "owner":
            state.ownerResult = {
              ...state.ownerResult,
              loadingRama: "pending",
            };
            break;
          case "provider":
            state.providerResult = {
              ...state.providerResult,
              loadingRama: "pending",
            };
            break;
          default:
            break;
        }
      })
      .addCase(getProcesos.fulfilled, (state, action) => {
        let result = action.payload;
        switch (result.type) {
          case "driver":
            if (result) {
              state.driverResult = {
                ...state.driverResult,
                procesos: result.response,
                loadingProcesos: "resolved",
              };
            } else {
              state.driverResult = {
                ...state.driverResult,
                procesos: null,
                loadingProcesos: "rejected",
              };
            }
            break;
          case "official":
            if (result) {
              state.officialResult = {
                ...state.officialResult,
                procesos: result.response,
                loadingProcesos: "resolved",
              };
            } else {
              state.officialResult = {
                ...state.officialResult,
                procesos: null,
                loadingProcesos: "rejected",
              };
            }
            break;
          case "owner":
            if (result) {
              state.ownerResult = {
                ...state.ownerResult,
                procesos: result.response,
                loadingProcesos: "resolved",
              };
            } else {
              state.ownerResult = {
                ...state.ownerResult,
                procesos: null,
                loadingProcesos: "rejected",
              };
            }
            break;
          case "provider":
            if (result) {
              state.providerResult = {
                ...state.providerResult,
                procesos: result.response,
                loadingProcesos: "resolved",
              };
            } else {
              state.providerResult = {
                ...state.providerResult,
                procesos: null,
                loadingProcesos: "rejected",
              };
            }
            break;
          default:
            break;
        }
      })
      .addCase(getProcesos.pending, (state, action) => {
        switch (action.meta.arg.type) {
          case "driver":
            state.driverResult = {
              ...state.driverResult,
              loadingProcesos: "pending",
            };
            break;
          case "official":
            state.officialResult = {
              ...state.officialResult,
              loadingProcesos: "pending",
            };
            break;
          case "owner":
            state.ownerResult = {
              ...state.ownerResult,
              loadingProcesos: "pending",
            };
            break;
          case "provider":
            state.providerResult = {
              ...state.providerResult,
              loadingProcesos: "pending",
            };
            break;
          default:
            break;
        }
      })
      .addCase(getMultas.fulfilled, (state, action) => {
        let result = action.payload;
        switch (result.type) {
          case "driver":
            if (result) {
              state.driverResult = {
                ...state.driverResult,
                multas: result.response,
                multasLoading: "resolved",
              };
            } else {
              state.driverResult = {
                ...state.driverResult,
                multas: null,
                multasLoading: "rejected",
              };
            }
            break;

          default:
            break;
        }
      })
      .addCase(getMultas.pending, (state, action) => {
        switch (action.meta.arg.type) {
          case "driver":
            state.driverResult = {
              ...state.driverResult,
              multasLoading: "pending",
            };
            break;

          default:
            break;
        }
      })
      .addCase(getProcuraduria.fulfilled, (state, action) => {
        let result = action.payload;
        switch (result.type) {
          case "driver":
            if (result) {
              state.driverResult = {
                ...state.driverResult,
                procuraduria: result.response,
                procuraduriaLoading: "resolved",
              };
            } else {
              state.driverResult = {
                ...state.driverResult,
                procuraduria: null,
                procuraduriaLoading: "rejected",
              };
            }
            break;
          case "official":
            if (result) {
              state.officialResult = {
                ...state.officialResult,
                procuraduria: result.response,
                procuraduriaLoading: "resolved",
              };
            } else {
              state.officialResult = {
                ...state.officialResult,
                procuraduria: null,
                procuraduriaLoading: "rejected",
              };
            }
            break;
          case "owner":
            if (result) {
              state.ownerResult = {
                ...state.ownerResult,
                procuraduria: result.response,
                procuraduriaLoading: "resolved",
              };
            } else {
              state.ownerResult = {
                ...state.ownerResult,
                procuraduria: null,
                procuraduriaLoading: "rejected",
              };
            }
            break;
          case "provider":
            if (result) {
              state.providerResult = {
                ...state.providerResult,
                procuraduria: result.response,
                procuraduriaLoading: "resolved",
              };
            } else {
              state.providerResult = {
                ...state.providerResult,
                procuraduria: null,
                procuraduriaLoading: "rejected",
              };
            }
            break;
          default:
            break;
        }
      })
      .addCase(getProcuraduria.pending, (state, action) => {
        switch (action.meta.arg.type) {
          case "driver":
            state.driverResult = {
              ...state.driverResult,
              procuraduriaLoading: "pending",
            };
            break;
          case "official":
            state.officialResult = {
              ...state.officialResult,
              procuraduriaLoading: "pending",
            };
            break;
          case "owner":
            state.ownerResult = {
              ...state.ownerResult,
              procuraduriaLoading: "pending",
            };
            break;
          case "provider":
            state.providerResult = {
              ...state.providerResult,
              procuraduriaLoading: "pending",
            };
            break;
          default:
            break;
        }
      })
      .addCase(getSofiaPlus.fulfilled, (state, action) => {
        let result = action.payload;
        switch (result.type) {
          case "driver":
            if (result) {
              state.driverResult = {
                ...state.driverResult,
                sofiaPlus: result.response,
                loadingSofiaPlus: "resolved",
              };
            } else {
              state.driverResult = {
                ...state.driverResult,
                sofiaPlus: null,
                loadingSofiaPlus: "rejected",
              };
            }
            break;

          default:
            break;
        }
      })
      .addCase(getSofiaPlus.pending, (state, action) => {
        switch (action.meta.arg.type) {
          case "driver":
            state.driverResult = {
              ...state.driverResult,
              loadingSofiaPlus: "pending",
            };
            break;

          default:
            break;
        }
      })
      .addCase(getRuntPersona.fulfilled, (state, action) => {
        let result = action.payload;
        switch (result.type) {
          case "driver":
            if (result) {
              state.driverResult = {
                ...state.driverResult,
                runtPersona: result.response,
                loadingRuntPersona: "resolved",
              };
            } else {
              state.driverResult = {
                ...state.driverResult,
                runtPersona: null,
                loadingRuntPersona: "rejected",
              };
            }
            break;

          default:
            break;
        }
      })
      .addCase(getRuntPersona.pending, (state, action) => {
        switch (action.meta.arg.type) {
          case "driver":
            state.driverResult = {
              ...state.driverResult,
              loadingRuntPersona: "pending",
            };
            break;

          default:
            break;
        }
      })
      .addCase(getRuntPlaca.fulfilled, (state, action) => {
        let result = action.payload;

        if (result) {
          state.vehicleResult = {
            ...state.vehicleResult,
            runt: result.response,
            runtLoading: "resolved",
          };
        } else {
          state.vehicleResult = {
            ...state.vehicleResult,
            runt: null,
            runtLoading: "rejected",
          };
        }
      })
      .addCase(getRuntPlaca.pending, (state) => {
        state.vehicleResult = {
          ...state.vehicleResult,
          runtLoading: "pending",
        };
      })
      .addCase(getRuntPlacaTrailer.fulfilled, (state, action) => {
        let result = action.payload;

        if (result) {
          state.trailerResult = {
            ...state.trailerResult,
            runt: result.response,
            runtLoading: "resolved",
          };
        } else {
          state.trailerResult = {
            ...state.trailerResult,
            runt: null,
            runtLoading: "rejected",
          };
        }
      })
      .addCase(getRuntPlacaTrailer.pending, (state) => {
        state.trailerResult = {
          ...state.trailerResult,
          runtLoading: "pending",
        };
      })
      .addCase(getRNDCPersona.fulfilled, (state, action) => {
        let result = action.payload;
        switch (result.type) {
          case "driver":
            if (result) {
              state.driverResult = {
                ...state.driverResult,
                rndc: result.response,
                loadingRndc: "resolved",
              };
            } else {
              state.driverResult = {
                ...state.driverResult,
                rndc: [],
                loadingRndc: "rejected",
              };
            }
            break;

          default:
            break;
        }
      })
      .addCase(getRNDCPersona.pending, (state, action) => {
        switch (action.meta.arg.type) {
          case "driver":
            state.driverResult = {
              ...state.driverResult,
              loadingRndc: "pending",
            };
            break;

          default:
            break;
        }
      })
      .addCase(getRndcPlaca.fulfilled, (state, action) => {
        let result = action.payload;

        if (result) {
          state.vehicleResult = {
            ...state.vehicleResult,
            rndc: result.response,
            rndcLoading: "resolved",
          };
        } else {
          state.vehicleResult = {
            ...state.vehicleResult,
            rndc: [],
            rndcLoading: "rejected",
          };
        }
      })
      .addCase(getRndcPlaca.pending, (state) => {
        state.vehicleResult = {
          ...state.vehicleResult,
          rndcLoading: "pending",
        };
      })
      .addCase(getRndcPlacaTrailer.fulfilled, (state, action) => {
        let result = action.payload;

        if (result) {
          state.trailerResult = {
            ...state.trailerResult,
            rndc: result.response,
            rndcLoading: "resolved",
          };
        } else {
          state.trailerResult = {
            ...state.trailerResult,
            rndc: [],
            rndcLoading: "rejected",
          };
        }
      })
      .addCase(getRndcPlacaTrailer.pending, (state) => {
        state.trailerResult = {
          ...state.trailerResult,
          rndcLoading: "pending",
        };
      })
      .addCase(saveResult.fulfilled, (state, action) => {
        if (action.payload) {
          state.loadingSave = "resolved";
          state.currentRegistration = null;
          state.currentRequest = null;
          state.driverResult = {
            fosyga: null,
            fosygaLoading: "idle",
            procuraduria: null,
            procuraduriaLoading: "idle",
            licenciaLoading: "idle",
            licenciaSuspendida: null,
            loadingOfac: "idle",
            loadingOnu: "idle",
            loadingPolicia: "idle",
            loadingProcesos: "idle",
            loadingRama: "idle",
            loadingRuntPersona: "idle",
            loadingUE: "idle",
            multas: null,
            multasLoading: "idle",
            ofac: null,
            onu: null,
            policia: null,
            procesos: null,
            ramaJudicial: null,
            runtPersona: null,
            ue: null,
            loadingRndc: "idle",
            loadingSofiaPlus: "idle",
            rndc: [],
            sofiaPlus: null,
          };
          state.officialResult = {
            loadingOfac: "idle",
            loadingOnu: "idle",
            loadingPolicia: "idle",
            loadingProcesos: "idle",
            loadingRama: "idle",
            loadingUE: "idle",
            ofac: null,
            onu: null,
            policia: null,
            procesos: null,
            procuraduria: null,
            procuraduriaLoading: "idle",
            ramaJudicial: null,
            ue: null,
          };
          state.ownerResult = {
            loadingOfac: "idle",
            loadingOnu: "idle",
            loadingPolicia: "idle",
            loadingProcesos: "idle",
            loadingRama: "idle",
            loadingUE: "idle",
            ofac: null,
            onu: null,
            policia: null,
            procesos: null,
            procuraduria: null,
            procuraduriaLoading: "idle",
            ramaJudicial: null,
            ue: null,
          };
          state.providerResult = {
            loadingOfac: "idle",
            loadingOnu: "idle",
            loadingProcesos: "idle",
            loadingRama: "idle",
            loadingUE: "idle",
            ofac: null,
            onu: null,
            procesos: null,
            procuraduria: null,
            procuraduriaLoading: "idle",
            ramaJudicial: null,
            ue: null,
          };
          state.trailerResult = {
            runt: null,
            runtLoading: "idle",
            rndc: [],
            rndcLoading: "idle",
          };
          state.vehicleResult = {
            rndc: [],
            rndcLoading: "idle",
            runt: null,
            runtLoading: "idle",
          };
        } else {
          state.loadingSave = "rejected";
        }
      })
      .addCase(saveResult.pending, (state) => {
        state.loadingSave = "pending";
      })
      .addCase(getRequestConsultation.fulfilled, (state, action) => {
        let requestConsultation = action.payload;
        if (requestConsultation) {
          state.loadingRequestConsultation = "resolved";
          state.currentConsultationRequest = requestConsultation;
        } else {
          state.loadingRequestConsultation = "rejected";
          state.currentConsultationRequest = null;
        }
      })
      .addCase(getRequestConsultation.pending, (state) => {
        state.loadingRequestConsultation = "pending";
      })
      .addCase(getResultData.fulfilled, (state, action) => {
        let resultData = action.payload;
        if (resultData) {
          state.loadingResultData = "resolved";
          state.currentResultData = resultData;
        } else {
          state.loadingResultData = "rejected";
          state.currentConsultationRequest = null;
        }
      })
      .addCase(getResultData.pending, (state) => {
        state.loadingResultData = "pending";
      })
      .addCase(downloadFile.fulfilled, (state, action) => {
        state.currentDownloadedFile = action.payload;
      })
      .addCase(generatePDFFromId.fulfilled, (state, action) => {
        if (action.payload) {
          state.loadingDownloadFile = "resolved";
        } else {
          state.loadingDownloadFile = "rejected";
        }
      })
      .addCase(generatePDFFromId.pending, (state) => {
        state.loadingDownloadFile = "pending";
      });
  },
});

export const {
  changeCurrentFilteredRequest,
  changePage,
  resetReactivate,
  resetState,
  resetDetailRequest,
  deleteCurrentDownloadedFile,
  resetDetailData,
  setElementSelected,
} = securityEvaluationSlice.actions;

export const selectCurrentFilteredRequest = (state: RootState) =>
  state.securityEvaluation.currentFilteredRequest;
export const selectCurrentFilteredResponse = (state: RootState) =>
  state.securityEvaluation.currentFilteredResponse;
export const selectLoadingRequests = (state: RootState) =>
  state.securityEvaluation.loadingRequestStatus;
export const selectLoadingOneRequest = (state: RootState) =>
  state.securityEvaluation.loadingOneRequestStatus;
export const selectCurrentRequest = (state: RootState) =>
  state.securityEvaluation.currentRequest;
export const selectCurrentRegistration = (state: RootState) =>
  state.securityEvaluation.currentRegistration;
export const selectdriverResult = (state: RootState) =>
  state.securityEvaluation.driverResult;
export const selectOwnerResult = (state: RootState) =>
  state.securityEvaluation.ownerResult;
export const selectVehicleResult = (state: RootState) =>
  state.securityEvaluation.vehicleResult;
export const selectTrailerResult = (state: RootState) =>
  state.securityEvaluation.trailerResult;
export const selectOfficialResult = (state: RootState) =>
  state.securityEvaluation.officialResult;
export const selectProviderResult = (state: RootState) =>
  state.securityEvaluation.providerResult;
export const selectUpdatedLoading = (state: RootState) =>
  state.securityEvaluation.loadingSave;
export const selectConsultRequestLoading = (state: RootState) =>
  state.securityEvaluation.loadingRequestConsultation;
export const selectConsultedRequest = (state: RootState) =>
  state.securityEvaluation.currentConsultationRequest;
export const selectCurrentResultData = (state: RootState) =>
  state.securityEvaluation.currentResultData;
export const selectLoadingResultData = (state: RootState) =>
  state.securityEvaluation.loadingResultData;
export const selectSelectedElement = (state: RootState) =>
  state.securityEvaluation.selectedElement;
export const selectCurrentDownloadedFile = (state: RootState) =>
  state.securityEvaluation.currentDownloadedFile;
export const selectLoadingReactivate = (state: RootState) =>
  state.securityEvaluation.loadingreActivate;
export const selectLoadingDownloadFile = (state: RootState) =>
  state.securityEvaluation.loadingDownloadFile;

export default securityEvaluationSlice.reducer;

export interface SourceRequest {
  type: "driver" | "owner" | "trailer" | "vehicle" | "official" | "provider";
  id?: string;
  reqRndcPersona?: RNDCPersonaRequest;
  reqRndcPlaca?: RNDCPlacaRequest;
}
