import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../login/loginSlice";

export interface ProcesosUnificadosProps {
  loading: LoadingStatus;
  item: ProcesosUnificados | null;
}

export interface ProcesosUnificados {
  tipoDocumentoConsulta: [
    {
      tipoDocumento: string;
      nombreDocumento: string;
    }
  ];
  presentaRiesgo: boolean;
  presentaAdvertencia: boolean;
  procesos: Array<ProcesoJudicial>;
}
export interface ProcesoJudicial {
  contenidoRadicacion: string;
  ubicacion: string;
  fechaProceso: string;
  despacho: string;
  coincidencia: string;
  ponente: string;
  isLaft: boolean;
  tipoProceso: string;
  esPrivado: boolean;
  sujetosProcesales: string;
  fechaUltimaActuacion: string;
  departamento: string;
  idProceso: number;
  claseProceso: string;
  numProceso: string;
}

export const ProcesosUnificadosResult = (props: ProcesosUnificadosProps) => {
  const getColor = (presentaRiesgo: boolean, presentaAdvertencia: boolean) => {
    if (presentaRiesgo) {
      return "text-red-400";
    } else if (presentaAdvertencia) {
      return "text-yellow-400";
    } else {
      return "text-green-400";
    }
  };

  return (
    <LoadingOverlay
      styles={{
        content: {},
        overlay: {},
        spinner: {},
        wrapper: { height: "fit-content" },
      }}
      active={props.loading === "pending"}
      className="overflow-auto mb-10"
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {props.item ? (
        <div className="flex flex-col justify-evenly w-full px-2">
          <div
            className={`${getColor(
              props.item.presentaRiesgo,
              props.item.presentaAdvertencia
            )} text-3xl font-semibold text-center`}
          >
            Procesos unificados
          </div>
          <div className="text-primary text-xl font-semibold text-center">
            {props.item.presentaRiesgo || props.item.presentaAdvertencia
              ? "Presenta riesgo o advertencia"
              : "No presenta riesgo"}
          </div>
          {props.item.procesos.length > 0 ? (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    No. Proceso
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Ponente
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Tipo de Proceso
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Sujetos procesales
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Clase
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Despacho
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Ubicación
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Es Laft
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Contenido Radicación
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha última actualización
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.procesos.map((proceso, i) => {
                  return (
                    <tr
                      key={i}
                      className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                    >
                      <td></td>
                      <td>{proceso.numProceso}</td>
                      <td>{proceso.ponente}</td>
                      <td>{proceso.tipoProceso}</td>
                      <td>{proceso.sujetosProcesales}</td>
                      <td>{proceso.claseProceso}</td>
                      <td>{proceso.despacho}</td>
                      <td>{proceso.fechaProceso}</td>
                      <td>{proceso.ubicacion}</td>
                      <td>{proceso.isLaft ? "Sí" : "No"}</td>
                      <td>{proceso.contenidoRadicacion}</td>
                      <td>{proceso.fechaUltimaActuacion}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            <div className="text-primary text-xl font-semibold text-center">
              No tiene procesos
            </div>
          )}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <div className="text-primary text-xl font-semibold text-center">
              No se encontraron registros, intente de nuevo más tarde
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </LoadingOverlay>
  );
};
