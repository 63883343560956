import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../login/loginSlice";

export interface MultaProps {
  loading: LoadingStatus;
  item: MultasInfracciones | null;
}

export interface MultasInfracciones {
  encabezado: string;
  resoluciones: Array<ResolucionMulta>;
  comparendos: Array<Comparendo>;
  acuerdos: Array<Acuerdo>;
}

export interface ResolucionMulta {
  estado: string;
  resolucion: string;
  infraccion: string;
  interesMora: string;
  nombreInfractor: string;
  fechaComparendo: string;
  valorMulta: string;
  secretaria: string;
  fechaResolucion: string;
  comparendo: string;
  valorAdicional: string;
  valorAPagar: string;
}

export interface Comparendo {
  fecha: string;
  estado: string;
  total: string;
  infraccion: string;
  nombreInfractor: string;
  valorMulta: string;
  secretaria: string;
  fechaNotificacion: string;
  comparendo: string;
  valorAdicional: string;
  valorAPagar: string;
}

export interface Acuerdo {
  estado: string;
  resolucion: string;
  nombreInfractor: string;
  saldo: string;
  fechaResolucion: string;
  valorAdicional: string;
  valorAPagar: string;
  fechaComparendo: string;
  total: string;
  infraccion: string;
  valorMulta: string;
  secretaria: string;
  comparendo: string;
}

export const MultaResult = (props: MultaProps) => {
  return (
    <LoadingOverlay
      styles={{
        content: {},
        overlay: {},
        spinner: {},
        wrapper: { height: "fit-content" },
      }}
      active={props.loading === "pending"}
      className="overflow-auto mb-10"
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {props.item ? (
        <div className="flex flex-col justify-evenly w-full px-2">
          <div className="text-primary text-3xl font-semibold text-center">
            Comparendos e infracciones
          </div>
          {props.item.encabezado ? (
            <div className="text-primary text-xl font-semibold text-center">
              Encabezado: {props.item.encabezado}
            </div>
          ) : (
            ""
          )}

          {props.item.resoluciones ? (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Nombre
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Infraccion
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Interes mora
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Estado
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Secretaria
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Comparendo
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Valor multa
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Valor Adicional
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Valor a Pagar
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.resoluciones.map((resolucion, i) => {
                  return (
                    <tr
                      key={i}
                      className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                    >
                      <td></td>
                      <td>{resolucion.nombreInfractor}</td>
                      <td>{resolucion.infraccion}</td>
                      <td>{resolucion.interesMora}</td>
                      <td>{resolucion.fechaComparendo}</td>
                      <td>{resolucion.estado}</td>
                      <td>{resolucion.secretaria}</td>
                      <td>{resolucion.comparendo}</td>
                      <td>{resolucion.valorMulta}</td>
                      <td>{resolucion.valorAdicional}</td>
                      <td>{resolucion.valorAPagar}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
          {props.item.comparendos ? (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Nombre
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Infraccion
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Fecha de notificación
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Estado
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Secretaria
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Comparendo
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Valor multa
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Valor Adicional
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Valor a Pagar
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.comparendos.map((comparendo, i) => {
                  return (
                    <tr
                      key={i}
                      className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                    >
                      <td></td>
                      <td>{comparendo.nombreInfractor}</td>
                      <td>{comparendo.infraccion}</td>
                      <td>{comparendo.fecha}</td>
                      <td>{comparendo.fechaNotificacion}</td>
                      <td>{comparendo.estado}</td>
                      <td>{comparendo.secretaria}</td>
                      <td>{comparendo.comparendo}</td>
                      <td>{comparendo.valorMulta}</td>
                      <td>{comparendo.valorAdicional}</td>
                      <td>{comparendo.valorAPagar}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
          {props.item.acuerdos ? (
            <>
              <div className="text-yellow-400 text-2xl font-semibold text-center">
                Acuerdos
              </div>
              <table className="relative w-full">
                <thead>
                  <tr>
                    <th></th>
                    <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                      Resolución
                    </th>
                    <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                      Fecha Resolución
                    </th>
                    <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                      Comparendo
                    </th>
                    <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                      Fecha Comparendo
                    </th>
                    <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                      Secretaría
                    </th>
                    <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                      Nombre infractor
                    </th>
                    <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                      Estado
                    </th>
                    <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                      Valor multa
                    </th>
                    <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                      Valor Adicional
                    </th>
                    <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                      Total
                    </th>
                    <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                      Saldo
                    </th>
                    <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                      Valor a Pagar
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {props.item.acuerdos.map((acuerdo, i) => {
                    return (
                      <tr
                        key={i}
                        className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                      >
                        <td></td>
                        <td>{acuerdo.resolucion}</td>
                        <td>{acuerdo.fechaResolucion}</td>
                        <td>{acuerdo.comparendo}</td>
                        <td>{acuerdo.fechaComparendo}</td>
                        <td>{acuerdo.secretaria}</td>
                        <td>{acuerdo.nombreInfractor}</td>
                        <td>{acuerdo.estado}</td>
                        <td className="text-yellow-400">
                          {acuerdo.valorMulta}
                        </td>
                        <td>{acuerdo.valorAdicional}</td>
                        <td className="text-yellow-400">{acuerdo.total}</td>
                        <td className="text-yellow-400">{acuerdo.saldo}</td>
                        <td className="text-yellow-400">
                          {acuerdo.valorAPagar}
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <div className="text-primary text-xl font-semibold text-center">
              No se encontraron registros, intente de nuevo más tarde
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </LoadingOverlay>
  );
};
