import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { UserInfo } from "../login/loginAPI";
import { LoadingStatus } from "../login/loginSlice";

export interface CommonState {
  loadingStatus: LoadingStatus;
  userInfo: UserInfo | null;
  expiryTimestamp: number;
  token: string;
  currentStudy: "funcionarios" | "transportes" | "clients";
}

const initialState: CommonState = {
  loadingStatus: "idle",
  userInfo: null,
  expiryTimestamp: -1,
  token: "",
  currentStudy: "transportes",
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<UserInfo>) => {
      state.userInfo = action.payload;
    },
    setCurrentStudy: (
      state,
      action: PayloadAction<"funcionarios" | "transportes" | "clients">
    ) => {
      state.currentStudy = action.payload;
    },
    setTokenInfo: (
      state,
      action: PayloadAction<{ token: string; expiry: number }>
    ) => {
      state.expiryTimestamp = action.payload.expiry;
      state.token = action.payload.token;
    },
    resetState: () => {},
  },
  extraReducers: (builder) => {},
});

export const { setUserInfo, resetState, setTokenInfo, setCurrentStudy } =
  commonSlice.actions;

export const selectTokenExpiryTimestamp = (state: RootState) =>
  state.common.expiryTimestamp;
export const selectUserInfo = (state: RootState) => state.common.userInfo;
export const selectCurrentStudy = (state: RootState) =>
  state.common.currentStudy;

export default commonSlice.reducer;
