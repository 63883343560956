import { useEffect, useState } from "react";
import Modal, { Styles } from "react-modal";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ReactComponent as LeftArrow } from "../../../../Assets/images/leftArrow.svg";
import { DropdownSecondary } from "../../../common/DropdownSecondary";
import {
  createFuncionarioRequest,
  resetErrorMessage,
  resetLoadingStatus,
  selectRequestCreationErrorMessage,
  selectRequestCreationLoading,
  selectRequestCreationRedirectionId,
} from "../requestCreationSlice";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { CreateOfficialRequest } from "../requestCreationAPI";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "20%",
    width: "20%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "2%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};

Modal.setAppElement("#root");

export const RequestCreationFuncionario = () => {
  let loadingStatus = useAppSelector(selectRequestCreationLoading);
  let redirectionId = useAppSelector(selectRequestCreationRedirectionId);
  let errorMessage = useAppSelector(selectRequestCreationErrorMessage);

  const [funcionario, setFuncionario] = useState<CreateOfficialRequest>({
    observations: "",
    officialIdentification: "",
    officialIdType: "cc",
    oficialPosition: "",
  });

  let tipoDocumentoOptionsFuncionario = [
    { label: "Cedula de Ciudadanía", value: "cc" },
    { label: "NIT ", value: "nit" },
  ];

  let getTipoDocumentoLabel = (value: string) => {
    switch (value) {
      case "CC":
        return "Cedula de Ciudadanía";
      case "CE":
        return "Cedula de extranjería";
      case "NIT":
        return "Nit";
      case "Pasaporte":
        return "Pasaporte";
      default:
        return "SELECCIONE UNA OPCIÓN";
    }
  };

  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  const closeModal = () => {
    dispatch(resetLoadingStatus());
  };
  useEffect(() => {
    window.addEventListener("popstate", () => {});
    if (loadingStatus === "resolved" && redirectionId !== "") {
      navigate(`/services/securityEvaluation/choose/${redirectionId}`, {
        replace: true,
      });
    }
    return () => {};
  }, [dispatch, loadingStatus, navigate, redirectionId]);
  return (
    <>
      {loadingStatus === "pending" && (
        <LoadingOverlay
          active={loadingStatus === "pending"}
          spinner={<ClipLoader color="white" size="150px" />}
        ></LoadingOverlay>
      )}

      <Modal
        isOpen={loadingStatus === "rejected"}
        onRequestClose={(e) => {
          e.preventDefault();
          dispatch(resetLoadingStatus());
          dispatch(resetErrorMessage());
          if (errorMessage === "401") {
            navigate(0);
          }
        }}
        style={customStylesModal}
        contentLabel=""
      >
        <div className="flex flex-col items-center justify-center">
          <div className="text-center text-sm text-black mb-3">
            Error creando la consulta
          </div>
          {errorMessage === "401" ? <p>La sesión se ha vencido</p> : ""}
          <button
            onClick={(e) => {
              e.preventDefault();
              closeModal();
              dispatch(resetErrorMessage());
              if (errorMessage === "401") {
                navigate(0);
              }
            }}
            className="bg-backgroundButtonModal rounded-lg text-center w-44 h-8 text-sm text-white font-bold"
          >
            {errorMessage ? "Iniciar sesión" : "Intentar de nuevo"}
          </button>
        </div>
      </Modal>
      <div className="flex flex-col items-center w-full h-5/6 ">
        <div className="flex flex-col sm:flex-row h-1/5 w-full items-center justify-evenly">
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/services/requestCreation/selectTypes");
            }}
            className="bg-primary-5 flex flex-row items-center justify-start h-11 w-64 rounded-md box-border px-5 cursor-pointer"
          >
            <LeftArrow className="mr-8" />
            <div className="text-primary text-lg font-bold">
              Regresar al MENU
            </div>
          </div>
          <div className="text-5xl text-primary font-extrabold text-center">
            Consulta de Funcionario
          </div>
        </div>
        <div className="flex flex-col h-3/5 w-3/4 justify-center items-start space-y-6">
          <div className="text-3xl text-primary font-extrabold m-2">
            Funcionario
          </div>
          <div className="flex flex-col sm:flex-row w-full items-center justify-between">
            <div className="text-lg font-bold text-primary-35 m-2">
              Tipo de Documento
            </div>
            <div className="lg:w-3/6 sm:w-3/5 xl:w-3/5 m-2">
              <DropdownSecondary
                defaultValue={
                  funcionario.officialIdType === ""
                    ? { label: "SELECCIONE UNA OPCIÓN", value: "" }
                    : {
                        label: getTipoDocumentoLabel(
                          funcionario.officialIdType
                        ),
                        value: funcionario.officialIdType,
                      }
                }
                title="TipoDocumento"
                options={tipoDocumentoOptionsFuncionario}
                onChange={(e, m) => {
                  if (m.action === "clear") {
                    setFuncionario({
                      ...funcionario,
                      officialIdType: "",
                      officialIdentification: "",
                    });
                  } else {
                    if (e?.value === "") {
                      setFuncionario({
                        ...funcionario,
                        officialIdType: e?.value!,
                        officialIdentification: "",
                      });
                    } else {
                      setFuncionario({
                        ...funcionario,
                        officialIdType: e?.value!,
                      });
                    }
                  }
                }}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row w-full items-center justify-between">
            <div className="text-lg font-bold text-primary-35 m-2">
              Número de documento
            </div>
            <div className="flex flex-col w-full, h-full">
              <input
                value={funcionario.officialIdentification!}
                onChange={(e) => {
                  setFuncionario({
                    ...funcionario,
                    officialIdentification: e.target.value,
                  });
                }}
                className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold h-10 px-6 m-2"
              />
              {funcionario.officialIdType &&
                funcionario.officialIdType !== "" &&
                funcionario.officialIdentification?.length! <= 3 && (
                  <p className="text-xs text-red-400 mx-2">
                    La identificación debe ser mayor a 3 dígitos
                  </p>
                )}
            </div>
          </div>

          <div className="flex flex-col sm:flex-row w-full items-center justify-between">
            <div className="text-lg font-bold text-primary-35 m-2">Cargo</div>
            <div className="flex flex-col h-full">
              <input
                value={funcionario.oficialPosition}
                onChange={(e) => {
                  setFuncionario({
                    ...funcionario,
                    oficialPosition: e.target.value,
                  });
                }}
                className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold h-10 px-6 m-2"
              />
              {funcionario.oficialPosition?.length! < 1 && (
                <p className="text-xs text-red-400 mx-2">Debe tener un cargo</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-24 w-full">
            <div className="text-lg font-bold text-primary-35 m-2">
              Observaciones
            </div>
            <input
              value={funcionario.observations}
              onChange={(e) => {
                setFuncionario({
                  ...funcionario,
                  observations: e.target.value,
                });
              }}
              className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold min-h-full px-6 w-11/12 m-2"
            />

            <div className="flex flex-col items-center justify-center m-2">
              {requestFuncionarioCreated(funcionario) && (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(createFuncionarioRequest(funcionario));
                  }}
                  className="items-center justify-center pt-2 h-10 w-60 text-primary bg-primary-5 rounded-md text-center font-bold cursor-pointer"
                >
                  Crear consulta
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const requestFuncionarioCreated = (
  requestParam: CreateOfficialRequest
): boolean => {
  let correct = false;
  if (requestParam.officialIdType && requestParam.officialIdType !== "") {
    if (requestParam.officialIdentification?.length! > 3) {
      if (requestParam.oficialPosition.length > 0) {
        correct = true;
      }
    }
  }

  return correct;
};
