import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectUserInfo, setCurrentStudy } from "../common/commonSlice";
import { ChooseServiceButton } from "./components/ChooseServiceButton";

export const Intro = () => {
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  let userInfo = useAppSelector(selectUserInfo);
  useEffect(() => {
    return () => {};
  }, [dispatch]);

  return (
    <div className="flex flex-col h-5/6 w-full items-center justify-center">
      <div className="text-white text-5xl font-extrabold mb-28">
        ¿Qué vamos a realizar hoy?
      </div>
      {userInfo?.roles.includes("agent") && (
        <ChooseServiceButton
          text="Crear consulta"
          className="mb-10"
          onClick={(e) => {
            e.preventDefault();
            navigate("/services/requestCreation/selectTypes");
          }}
        />
      )}
      {(userInfo?.roles.includes("security") ||
        userInfo?.roles.includes("agent")) && (
        <ChooseServiceButton
          text="Estudio Transportes"
          className="mb-10"
          onClick={(e) => {
            e.preventDefault();
            dispatch(setCurrentStudy("transportes"));
            navigate("/services/securityEvaluation");
          }}
        />
      )}
      {(userInfo?.roles.includes("security") ||
        userInfo?.roles.includes("agent")) && (
        <ChooseServiceButton
          text="Estudio Funcionarios"
          className="mb-10"
          onClick={(e) => {
            e.preventDefault();
            dispatch(setCurrentStudy("funcionarios"));
            navigate("/services/securityEvaluation");
          }}
        />
      )}
      {(userInfo?.roles.includes("security") ||
        userInfo?.roles.includes("agent")) && (
        <ChooseServiceButton
          text="Estudio de clientes y proveedores"
          className="mb-10 h-16"
          onClick={(e) => {
            e.preventDefault();
            dispatch(setCurrentStudy("clients"));
            navigate("/services/securityEvaluation");
          }}
        />
      )}
      {/* {userInfo?.roles.includes("agent") && (
        <ChooseServiceButton
          text="Solicitudes Transportes"
          className="mb-10"
          onClick={(e) => {
            e.preventDefault();
            dispatch(setCurrentStudy("transportes"));
            navigate("/services/securityEvaluation");
          }}
        />
      )}
      {userInfo?.roles.includes("agent") && (
        <ChooseServiceButton
          text="Solicitudes Funcionarios"
          className="mb-10"
          onClick={(e) => {
            e.preventDefault();
            dispatch(setCurrentStudy("funcionarios"));
            navigate("/services/securityEvaluation");
          }}
        />
      )}

      {userInfo?.roles.includes("agent") && (
        <ChooseServiceButton
          text="Solicitudes Clientes y Proveedores"
          className="mb-10 h-16"
          onClick={(e) => {
            e.preventDefault();
            dispatch(setCurrentStudy("clients"));
            navigate("/services/securityEvaluation");
          }}
        />
      )} */}
    </div>
  );
};
