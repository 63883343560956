import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../login/loginSlice";

export interface ProcuraduriaProps {
  loading: LoadingStatus;
  item: Procuraduria | null;
}

export interface Procuraduria {
  lista: string;
  antecedentesPenales: AntecedentesPenale[];
  tipo: string;
  coincidencia: string;
  isFallecido: boolean;
  presentaRiesgo: boolean;
  nombre: string;
  presentaAdvertencia: boolean;
  textoIdentificado: string;
  tieneAntecedentes: boolean;
  textoSaludo: string;
  tipoDocumentoConsulta: TipoDocumentoConsulta[];
  duracion: number;
  vinoTextoTradicional: boolean;
}

export interface AntecedentesPenale {
  titulo: string;
  antecedentes: Antecedente[];
  tituloSiri: string;
}

export interface TipoDocumentoConsulta {
  tipoDocumento: string;
  nombreDocumento: string;
}

export interface Antecedente {
  subTitulo: string;
  tituloAntecedente: string[];
  textoAntecedente: string[][];
}

export const ProcuraduriaResult = (props: ProcuraduriaProps) => {
  const getColor = (presentaRiesgo: boolean, presentaAdvertencia: boolean) => {
    if (presentaRiesgo) {
      return "text-red-400";
    } else if (presentaAdvertencia) {
      return "text-yellow-400";
    } else {
      return "text-green-400";
    }
  };
  return (
    <LoadingOverlay
      styles={{
        content: {},
        overlay: {},
        spinner: {},
        wrapper: { height: "fit-content" },
      }}
      active={props.loading === "pending"}
      className="overflow-auto mb-10"
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {props.item ? (
        <div className="flex flex-col justify-evenly w-full px-2">
          <div
            className={`${getColor(
              props.item.presentaRiesgo,
              props.item.presentaAdvertencia
            )} text-3xl font-semibold text-center`}
          >
            Procuraduría
          </div>
          <div className="text-primary text-lg font-semibold text-center">
            Datos Básicos
          </div>
          <table className="relative w-full">
            <thead>
              <tr>
                <th></th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Nombre
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Tiene antecedentes
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Es Fallecido
                </th>

                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Texto identificado
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Coincidencia
                </th>
                <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                  Tipo
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md">
                <td></td>
                <td>{props.item.nombre}</td>
                <td>{props.item.tieneAntecedentes ? "Sí" : "No"}</td>
                <td>{props.item.isFallecido ? "Sí" : "No"}</td>
                <td>{props.item.textoIdentificado}</td>
                <td>{props.item.coincidencia}</td>
                <td>{props.item.tipo}</td>
                <td></td>
              </tr>

              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          {props.item.textoSaludo && (
            <>
              <h4 className="text-primary text-lg font-semibold text-center">
                Mensaje de respuesta
              </h4>
              <p className="text-center text-primary text-xs font-normal">
                {props.item.textoSaludo}
              </p>
            </>
          )}
          {props.item.antecedentesPenales &&
            props.item.antecedentesPenales.length > 0 && (
              <div className="text-primary text-lg font-semibold text-center">
                Antecedentes Penales
              </div>
            )}
          {props.item.antecedentesPenales.map((antPenal, i) => {
            return (
              <div className="flex flex-col w-full" key={i}>
                <div className="text-primary text-lg font-semibold text-center">
                  {antPenal.titulo}
                </div>
                {antPenal.antecedentes.map((antecedente, j) => {
                  return (
                    <div className="flex flex-col w-full" key={j}>
                      <div className="text-primary text-lg font-semibold text-center">
                        {antecedente.subTitulo}
                      </div>
                      <table className="relative w-full">
                        <thead>
                          <tr>
                            <th></th>
                            {antecedente.tituloAntecedente.map((titulo, k) => {
                              return (
                                <>
                                  <th
                                    className="text-primary font-semibold text-center"
                                    key={k}
                                  >
                                    {titulo}
                                  </th>
                                </>
                              );
                            })}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          {antecedente.textoAntecedente.map((texto, l) => {
                            return (
                              <tr
                                key={l}
                                className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                              >
                                <td></td>
                                {texto.map((data, m) => {
                                  return (
                                    <>
                                      <th
                                        className="text-primary font-semibold text-center"
                                        key={m}
                                      >
                                        {data}
                                      </th>
                                    </>
                                  );
                                })}
                                <td></td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>&nbsp;</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <div className="text-primary text-xl font-semibold text-center">
              No se encontraron registros, intente de nuevo más tarde
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </LoadingOverlay>
  );
};
