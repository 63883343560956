import { useEffect, useState } from "react";
import Modal, { Styles } from "react-modal";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ReactComponent as LeftArrow } from "../../../../Assets/images/leftArrow.svg";
import { ReactComponent as Carga } from "../../../../Assets/images/carga.svg";
import {
  createProveedoresRequest,
  resetErrorMessage,
  resetLoadingStatus,
  selectRequestCreationErrorMessage,
  selectRequestCreationLoading,
  selectRequestCreationRedirectionId,
} from "../requestCreationSlice";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { CreateProveedoresRequest } from "../requestCreationAPI";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "20%",
    width: "20%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "2%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};

Modal.setAppElement("#root");

export const RequestCreationProveedor = () => {
  let loadingStatus = useAppSelector(selectRequestCreationLoading);
  let redirectionId = useAppSelector(selectRequestCreationRedirectionId);
  let errorMessage = useAppSelector(selectRequestCreationErrorMessage);

  const [proveedor, setProveedor] = useState<CreateProveedoresRequest>({
    files: [],
    observations: "",
    providerNit: "",
  });

  function arrayBufferToBase64(buffer: ArrayBuffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  const closeModal = () => {
    dispatch(resetLoadingStatus());
  };
  useEffect(() => {
    window.addEventListener("popstate", () => {});
    if (loadingStatus === "resolved" && redirectionId !== "") {
      navigate(`/services/securityEvaluation/choose/${redirectionId}`, {
        replace: true,
      });
    }
    return () => {};
  }, [dispatch, loadingStatus, navigate, redirectionId]);
  return (
    <>
      {loadingStatus === "pending" && (
        <LoadingOverlay
          active={loadingStatus === "pending"}
          spinner={<ClipLoader color="white" size="150px" />}
        ></LoadingOverlay>
      )}

      <Modal
        isOpen={loadingStatus === "rejected"}
        onRequestClose={(e) => {
          e.preventDefault();
          dispatch(resetLoadingStatus());
          dispatch(resetErrorMessage());
          if (errorMessage === "401") {
            navigate(0);
          }
        }}
        style={customStylesModal}
        contentLabel=""
      >
        <div className="flex flex-col items-center justify-center">
          <div className="text-center text-sm text-black mb-3">
            Error creando la consulta
          </div>
          {errorMessage === "401" ? <p>La sesión se ha vencido</p> : ""}
          <button
            onClick={(e) => {
              e.preventDefault();
              closeModal();
              dispatch(resetErrorMessage());
              if (errorMessage === "401") {
                navigate(0);
              }
            }}
            className="bg-backgroundButtonModal rounded-lg text-center w-44 h-8 text-sm text-white font-bold"
          >
            {errorMessage ? "Iniciar sesión" : "Intentar de nuevo"}
          </button>
        </div>
      </Modal>
      <div className="flex flex-col items-center w-full h-5/6 ">
        <div className="flex flex-col sm:flex-row h-1/5 w-full items-center justify-evenly">
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/services/requestCreation/selectTypes");
            }}
            className="bg-primary-5 flex flex-row items-center justify-start h-11 w-64 rounded-md box-border px-5 cursor-pointer"
          >
            <LeftArrow className="mr-8" />
            <div className="text-primary text-lg font-bold">
              Regresar al MENU
            </div>
          </div>
          <div className="text-5xl text-primary font-extrabold text-center">
            Consulta de Clientes o Proveedores
          </div>
        </div>
        <div />
        <div className="flex flex-col h-3/5 w-3/4 justify-center items-start space-y-6">
          <div className="text-3xl text-primary font-extrabold m-2">
            Cliente o Proveedor
          </div>
          <div className="items-center justify-between">
            <div className="grid grid-cols-4 text-center">
              <div className="items-center justify-between">
                <div className="text-lg font-bold text-primary-35 m-2">
                  NIT (sin dígito de verificación)
                </div>
                <input
                  value={proveedor.providerNit}
                  onChange={(e) => {
                    setProveedor({
                      ...proveedor,
                      providerNit: e.target.value,
                    });
                  }}
                  className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold h-10 px-6 m-2"
                />
                {proveedor.providerNit?.length! <= 3 && (
                  <p className="text-xs text-red-400 mx-2">
                    La identificación debe ser mayor a 3 dígitos
                  </p>
                )}
              </div>

              {proveedor.files.map((e, i) => {
                return (
                  <div className="items-center justify-between" key={i}>
                    <div className="text-sm font-normal text-primary m-2">
                      {e.fileName}
                    </div>
                    <div className="text-sm font-normal text-primary m-2">
                      Cargado
                    </div>
                  </div>
                );
              })}

              <label
                className="flex flex-col items-center self-center justify-center content-center cursor-pointer"
                htmlFor="fileInput"
              >
                <div className="text-lg font-normal text-primary m-2">
                  Cargar archivo
                </div>
                <input
                  onChange={async (e) => {
                    let currentFiles = proveedor.files;
                    let file = e.target.files ? e.target.files[0] : null;
                    let name = "";
                    let type = "";
                    let fileBase64 = "";
                    if (file) {
                      name = file.name;
                      name = name.replaceAll(/\s/g, "_");
                      type = file.type;
                      let temp = await file.arrayBuffer();
                      fileBase64 = arrayBufferToBase64(temp);
                    }
                    currentFiles.push({
                      fileBase64,
                      fileName: name,
                      fileType: type,
                    });
                    setProveedor({ ...proveedor, files: currentFiles });
                  }}
                  type="file"
                  id="fileInput"
                  className="flex flex-row items-center justify-center"
                />
                <Carga />
              </label>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row w-full items-center justify-between">
            <div className="flex flex-col h-24 w-full">
              <div className="text-lg font-bold text-primary-35 m-2">
                Observaciones
              </div>
              <input
                value={proveedor.observations}
                onChange={(e) => {
                  setProveedor({
                    ...proveedor,
                    observations: e.target.value,
                  });
                }}
                className="bg-primary-10 border-0.5 border-solid border-primary-20 box-border rounded-md text-primary text-center text-sm font-semibold min-h-full px-6 w-11/12 m-2"
              />
              {
                <div className="flex flex-col items-center justify-center m-2">
                  {proveedor.providerNit.length > 3 && (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(createProveedoresRequest(proveedor));
                      }}
                      className="items-center justify-center pt-2 h-10 w-60 text-primary bg-primary-5 rounded-md text-center font-bold cursor-pointer"
                    >
                      Crear consulta
                    </div>
                  )}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
