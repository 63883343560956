import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../login/loginSlice";
import {
  createFuncionarioRequestAPI,
  CreateOfficialRequest,
  CreateProveedoresRequest,
  createProveedoresRequestAPI,
  CreateTransportesRequest,
  createTransportesRequestAPI,
} from "./requestCreationAPI";

export interface RequestCreationState {
  loadingStatus: LoadingStatus;
  errorMessage: string;
  redirectionId: string;
}

const initialState: RequestCreationState = {
  errorMessage: "",
  loadingStatus: "idle",
  redirectionId: "",
};

export const createTransportesRequest = createAsyncThunk(
  "requestCreation/createTransportes",
  async (req: CreateTransportesRequest, { rejectWithValue }) => {
    if (!req.driverIdentification || req.driverIdentification.length! <= 3) {
      req.driverIdType = "";
    }
    let responseSolicitud = await createTransportesRequestAPI(req);
    if (responseSolicitud.id !== "") {
      return responseSolicitud;
    } else {
      return rejectWithValue(responseSolicitud.error);
    }
  }
);

export const createProveedoresRequest = createAsyncThunk(
  "requestCreation/createProveedores",
  async (req: CreateProveedoresRequest, { rejectWithValue }) => {
    let responseSolicitud = await createProveedoresRequestAPI(req);
    if (responseSolicitud.id !== "") {
      return responseSolicitud;
    } else {
      return rejectWithValue(responseSolicitud.error);
    }
  }
);

export const createFuncionarioRequest = createAsyncThunk(
  "requestCreation/createFuncionario",
  async (req: CreateOfficialRequest, { rejectWithValue }) => {
    let responseSolicitud = await createFuncionarioRequestAPI(req);
    if (responseSolicitud.id !== "") {
      return responseSolicitud;
    } else {
      return rejectWithValue(responseSolicitud.error);
    }
  }
);

export const requestCreationSlice = createSlice({
  name: "requestCreationSlice",
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loadingStatus = "idle";
    },
    resetRedirectionId: (state) => {
      state.redirectionId = "";
      state.loadingStatus = "idle";
    },
    resetErrorMessage: (state) => {
      state.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTransportesRequest.fulfilled, (state, action) => {
        state.loadingStatus = "resolved";
        state.redirectionId = action.payload.id;
      })
      .addCase(createTransportesRequest.rejected, (state, action) => {
        let error = action.payload as string;
        state.loadingStatus = "rejected";
        state.errorMessage = error;
      })
      .addCase(createTransportesRequest.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(createProveedoresRequest.fulfilled, (state, action) => {
        state.loadingStatus = "resolved";
        state.redirectionId = action.payload.id;
      })
      .addCase(createProveedoresRequest.rejected, (state, action) => {
        let error = action.payload as string;
        state.loadingStatus = "rejected";
        state.errorMessage = error;
      })
      .addCase(createProveedoresRequest.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(createFuncionarioRequest.fulfilled, (state, action) => {
        state.loadingStatus = "resolved";
        state.redirectionId = action.payload.id;
      })
      .addCase(createFuncionarioRequest.rejected, (state, action) => {
        let error = action.payload as string;
        state.loadingStatus = "rejected";
        state.errorMessage = error;
      })
      .addCase(createFuncionarioRequest.pending, (state) => {
        state.loadingStatus = "pending";
      });
  },
});

export const { resetLoadingStatus, resetRedirectionId, resetErrorMessage } =
  requestCreationSlice.actions;

export const selectRequestCreationLoading = (state: RootState) =>
  state.requestCreation.loadingStatus;
export const selectRequestCreationErrorMessage = (state: RootState) =>
  state.requestCreation.errorMessage;
export const selectRequestCreationRedirectionId = (state: RootState) =>
  state.requestCreation.redirectionId;
export default requestCreationSlice.reducer;
