import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../login/loginSlice";

export interface ListaProps {
  loading: LoadingStatus;
  item: Lista | null;
  name: string;
}

export interface Lista {
  lista: string;
  tipo: string;
  respuestaSin: string;
  tipoDocumentoConsulta: string;
  antecedentes: Array<Antecedente | any>;
  duracion: number;
  presentaRiesgo: boolean;
}

export interface Antecedente {
  country: string;
  placeOfBirth: string;
  idType: string;
  address: string;
  city: string;
  dateOfBirth: string;
  program: string;
  type: string;
  idNumber: string;
  idCountry: string;
  stateOrProvince: string;
  nationalityCountry: string;
}

export const ListaRestrictiva = (props: ListaProps) => {
  const getColor = (presentaRiesgo: boolean) => {
    if (presentaRiesgo) {
      return "text-red-400";
    } else {
      return "text-green-400";
    }
  };
  return (
    <LoadingOverlay
      styles={{
        content: {},
        overlay: {},
        spinner: {},
        wrapper: { height: "fit-content" },
      }}
      active={props.loading === "pending"}
      className="overflow-auto mb-10"
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {props.item ? (
        <div className="flex flex-col justify-evenly w-full px-2">
          <div
            className={`${getColor(
              props.item.presentaRiesgo
            )} text-3xl font-semibold text-center uppercase`}
          >
            {props.name}
          </div>
          <div className="text-primary text-lg font-semibold text-center">
            Datos de la lista:
          </div>
          <div className="text-primary text-lg font-semibold text-center">
            Tiene antecedentes: {props.item.presentaRiesgo ? "Sí" : "No"}
          </div>
          {props.item.presentaRiesgo ? (
            ""
          ) : (
            <div className="text-primary text-lg font-semibold text-center">
              No tiene antecedentes
            </div>
          )}

          {props.item.antecedentes ? (
            <div className="text-primary text-lg font-semibold text-center">
              Antecedentes
            </div>
          ) : (
            ""
          )}
          {props.item.antecedentes && props.item.antecedentes.length > 0 ? (
            <table className="relative w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    País
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Lugar de nacimiento
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Tipo de identificacion
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Nombre
                  </th>
                  <th className="text-center py-2 text-sm text-primary-35 font-semibold">
                    Nacionalidad
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.antecedentes.map((antecedente, i) => {
                  return (
                    <tr
                      key={i}
                      className="text-center text-primary text-xs font-normal h-10 bg-primary-5 rounded-md"
                    >
                      <td></td>
                      <td>{antecedente.country}</td>
                      <td>{antecedente.placeOfBirth}</td>
                      <td>{antecedente.idType}</td>
                      <td>{antecedente.name}</td>
                      <td>{antecedente.nationalityCountry}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <div className="text-primary text-xl font-semibold text-center">
              No se encontraron registros, intente de nuevo más tarde
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </LoadingOverlay>
  );
};
